import React from 'react';
import YouTube from 'react-youtube';
 
class Example extends React.Component {
  render() {
    const opts = {
      height: '240',
      width: '100%',
      borderRadius:10,
      playerVars: { // https://developers.google.com/youtube/player_parameters
             }
    };
 
    return (
      <YouTube
        videoId="MfmT1rx41PA"
        opts={opts}
        onReady={this._onReady}
      />
      
    );
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}
 export default Example;