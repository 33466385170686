import React, { Component } from "react";
import Picker from "react-mobile-picker";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Language } from '../_common/data';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        language: Language.filter(lang => lang.isDefault).map(lang => lang.label)[0]
      },
      optionGroups: {
        language: Language.filter(lang => lang.value !== "D").map(lang => lang.label)
      },
      selected: Language.filter(lang => lang.isDefault).map(lang => lang.value)[0],
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    let selected_language = Language.filter(lang => lang.label === value)[0];
    this.setState({selected: selected_language.value});

    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
  };

  onClickNext = () =>{
    const page = 5;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

    const native_language = this.state.selected;
    localStorage.setItem("registerQuestion", JSON.stringify({
      state: {
        ...sharedData.state,
        native_language: native_language,
        page
      }  
    })) 

    this.props.history.push("register-question5")
  }
  
  render() {
    const { optionGroups, valueGroups } = this.state;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
    if(localStorage.getItem('registerQuestion') !== null) {
      if(sharedData.state.page < 4) {
        this.props.history.push("/")
      }
    } else{
      this.props.history.push("/")
    }
    return (
      <div>
        <LinearProgress style={progress} variant="determinate" value={32} />
        <Container fixed>
          <Box
            fontSize={10}
            style={letterSpacing}
            className="fontSailecRegular"
            textAlign="center"
            mt={2}
            color="#8D92A3"
          >
            QUESTION 4 OF 12
          </Box>
          <Box
            fontSize={24}
            px={3}
            mt={3}
            className="fontSailecBold"
            color="#000"
            textAlign="center"
          >
            What language do you speak at home?
          </Box>
          <Box
            textAlign="center"
            className="fontSailecRegular"
            mt={1}
            color="#707070"
            fontSize={14}
          >
            घरमा कुन भाषा बोल्नुहुन्छ?
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={7} />
              <Picker
                optionGroups={optionGroups}
                valueGroups={valueGroups}
                onChange={this.handleChange}
              />
              <Box />
             
                <Button
                onClick={this.onClickNext}
                  variant="contained"
                  className="fontSailecRegular"
                  color="secondary"
                  style={buttonWidth}
                >
                  Next
                </Button>
        
              <Box mb={3}></Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
const buttonWidth = {
  marginTop: 20,
  fontSize: 16,
  padding: "15px 0px",
  display: "block!important",
  bottom: 33,
  position: "fixed",
  width: "91%",
  textTransform: "none"
};
const progress = {
  position: "fixed",
  width: "100%",
  top: 0
};
const letterSpacing = {
  letterSpacing: 1
};

export default App;
