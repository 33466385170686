import React from "react";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import { userService } from "../_services/user.service";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#969696",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const CheckoutForm = (props) => {
  const [value, setValue] = React.useState({
    loading: false,
  });

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    const sharedData = JSON.parse(localStorage.getItem("login"));
    setValue({...value, loading:true})
    // show loader
    props.action(true);

    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    const response = await userService.getPaymentIntent(sharedData.details.token)
    if(response.status == "error") {
      props.action(false);
      setValue({...value, loading:false})

      console.log(response.message);
    } else {
      let client_secret = response.output.client_secret;

      // Use your card Element with other Stripe.js APIs
      const {error, paymentIntent} = await stripe.confirmCardPayment(
        client_secret,
        {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Test',
          },
        }
      });

      if (error) {
        console.log('[error]', error);
      } else {
        console.log('[paymentIntent]', paymentIntent);

        const {status, message} = await userService.paid(sharedData.details.token)
        if(status == "error") {
          console.log("Something went wrong, please contact customer care");  
        } else {
          localStorage.setItem("login", JSON.stringify({
            ...sharedData,
            subscription: "Done"
          }))
          props.props.history.push("/success")
        }

      }
    }
    
  };

  return (
    <form onSubmit={handleSubmit}>
        <Box fontSize={14} my={1} color="#8D92A3" className="fontSailecRegular">Card Number</Box>
        
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />

        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Box fontSize={14} my={1} color="#8D92A3" className="fontSailecRegular">Expire Date</Box>
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS}/>
            </Grid>

            <Grid item xs={6}>
                <Box fontSize={14} my={1} color="#8D92A3" className="fontSailecRegular">CVS</Box>
                <CardCvcElement options={CARD_ELEMENT_OPTIONS}/>
            </Grid>
        </Grid>

        <Button 
          type="submit" 
          className="btn-full" 
          variant="contained" 
          fullWidth 
          color="secondary" 
          disabled={!stripe || value.loading}>
          Pay
        </Button>
    </form>
    
  );
};