import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import SelectStatus from "../_common/SelectStatus";
import SelectMonths from "../_common/SelectMonths";
import SelectYears from "../_common/SelectYears";
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles(theme => ({
//   root: {
//     padding: '2px 4px',
//     display: 'flex',
//     alignItems: 'center',
   
//   },

  colorWhite: {
    color: "#ffffff"
  },
  inputLableI: {
    color: "#8D92A3"
  },
  formHome: {
    borderRadius: 4,
    background: "#ffffff",
    marginTop:"60%",
  },
  buttonWidth: {
    marginTop: 20,
    padding: "15px 0px",
    fontSize:14,
    width: "100%",
    fontFamily: "SailecBold!important",
    letterSpacing:2,
  },

 
  fontShareenath:{
    fontFamily:'ShreenathBoldRegular',
  },
  fontSailecMedium:{
    fontFamily:'SailecMedium!important',
  },
  fontSailecRegular:{
    fontFamily:'SailecRegular!important',
  },
  fontSailecBold:{
    fontFamily:'SailecBold!important',
  },
  marginIAm: {
    marginTop: 9
  },
  mtIAm: {
    marginTop: 30
  },
  textcapitalize: {
    textTransform: "capitalize",
    marginTop: 20,
    fontSize: 16,
    padding: "15px 0px",
  },
  textField:{
    backgroundColor:'#faf9f7',
    
    '& .MuiInput-underline:after': {
      border:"1px solid #f0f0f0",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border:"1px solid #f0f0f0",
      },
      '&:hover fieldset': {
        border:"1px solid #f0f0f0 ",
      },
      '&.Mui-focused fieldset': {
        border:"1px solid #f0f0f0 ",
      },
    },
  },
  radio: {
    color: "#5BC0DE!important",
  },
  radioFemale: {
    color: "#f50057"
  },
 
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [value, setValue] = React.useState({
    gender:"female",
    status:"",
    month:"",
    year:"",
    place:"",
  });
  const handleChange = event => {
    setValue({...value, gender:event.target.value});
  };
  const yearChangeHandle=(yearValue)=>{
    setValue({...value,year:yearValue})
  }
  const monthChangeHandle=(monthValue)=>{
    setValue({...value,month:monthValue})
  }
  const handleStatus=(props)=>{
    
    setValue({...value,status:props})
  }
  const searchChangeHandler=(e)=>{
    setValue({...value,place:e.target.value})
  }
  const {status,year,month,place}=value;
  return (
    <div>
 
      

        <Box px={2} pb={5} className={classes.formHome} >
        <Box fontSize={14} mb={1} className={classes.fontSailecRegular} color="#8D92A3" textAlign="left">
        First Name
        </Box>
        <TextField
          required
          id="standard-required"
          fullWidth
          placeholder="Enter First Name"
          className={clsx(classes.textField,"inputProfile")}
          variant="outlined"
        />
        <Box fontSize={14} mt={2} mb={1} className={classes.fontSailecRegular} color="#8D92A3" textAlign="left">
        Last Name
        </Box>
        <TextField
          required
          id="standard-required"
          fullWidth
          placeholder="Enter Last Name"
          className={clsx(classes.textField,"inputProfile")}
          variant="outlined"
        />
        <Box mt={2} />
          <Grid container>
            <Grid item sm={2} xs={2}>
              <Box
                fontSize={14}
                fontWeight={500}
                color="#8D92A3"
                className={clsx(classes.marginIAm,classes.fontSailecRegular)}
              >
                I am
              </Box>
            </Grid>
            <Grid item sm={10} xs={10}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={value.gender}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="male"
                    className={classes.radioFont}
                    control={<Radio className={clsx(classes.radio)} />}
                    label="Male"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="female"
                    className={classes.radioFont}
                    control={
                      <Radio
                        color="secondary"
                        className={classes.radioFemale}
                      />
                    }
                    label="Female"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={2} xs={2}>
              <Box
                fontSize={14}
                className={clsx(classes.inputLableI, classes.mtIAm,classes.fontSailecRegular)}
              >
                I am
              </Box>
            </Grid>
            <Grid item sm={10} xs={10}>
              <SelectStatus status = {handleStatus} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box mt={4} fontSize={14} className={clsx(classes.inputLableI,classes.fontSailecRegular)}>
              Targeted wedding date
              </Box>
            </Grid>
            <Grid item sm={6} xs={6}>
              <SelectMonths month={monthChangeHandle}/>
            </Grid>
            <Grid item sm={6} xs={6}>
              <SelectYears year={yearChangeHandle}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
            <Box mt={4} mb={2} fontSize={14} className={clsx(classes.inputLableI,classes.fontSailecRegular)}>
                I live in
              </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
            <Paper className={classes.root}>
  <IconButton className={classes.iconButton} aria-label="menu">
    <SearchIcon className={classes.searchIcon} />
  </IconButton>
  <InputBase
    className={classes.input}
    onChange={searchChangeHandler}
    placeholder="Type and choose your town"
    inputProps={{ 'aria-label': 'search google maps' }}
  />
</Paper>

              <Link  to='./my-profile'>
                <Button
               
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className={clsx(classes.textcapitalize,classes.fontSailecRegular)}
                >
                  Done
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
     

      
  
      
    
    
    </div>
  );
}
