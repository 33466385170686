
import Stripe from "stripe";
const stripe = new Stripe("sk_test_DpR0bgK3iI1oYgW6hMz13z0500FBVsgQFA")
// stripe server for integration payment method
export default async (req, res) => {
    const {id, amount} = req.body;
    console.log(req.body)
    try{
      const payment = await stripe.paymentIntents.create({
        amount,
        currency:"USD",
        description:"testing payment",
        payment_method:id,
        confirm:true
    
      });
      console.log(payment);
      return res.status(200).json({
        confirm:"success"
      })
    }
    catch (error) {}
    
    
    };