import React, { Component } from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
// import SearchInput from './searchinput2';
// import DiscoverNavbottom from './discoverNav';
// import DiscoverTabs from './discoverTabs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TuneIcon from '@material-ui/icons/Tune';
// import Card1 from './assets/images/62469792_444065879705050_9118178783013109760_n.png';
// import Card2 from './assets/images/69921162_380283162905345_4634133652115030016_o.png';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FaceIcon from '@material-ui/icons/Face';
import Grow from '@material-ui/core/Grow';
// import FemaleIcon from './assets/images/female@2x.png';
import { userService } from '../_services/user.service';
import { withRouter } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
class RegisterUserName extends Component {
    constructor(props) {
        super(props);
        this.state = {
          anchorEl:null,
          followers:"",
          profileData:"",  
        }
      };
 
      
      handleClick = event => {
        this.setState({anchorEl:event.currentTarget});
      };
    
       handleClose = () => {
        this.setState({anchorEl:null});
      };
      onClickProfile=(images,e)=>{
        this.props.history.push({pathname:"/profile", 
          
            
            search: images.username
          
          
          });
      }
      componentDidMount=()=>{
        const username = this.props.location.search.slice(1);
  // console.log(username)
  const userData =JSON.parse(localStorage.getItem('login'));
  const {token} = userData.details;
  if(localStorage.getItem('login') === null){
    this.props.history.push("/login")
  }
  else{
  
  userService
      .interestedInList(username,token)
      .then(res=> {
        console.log(res)
        this.setState({followers:res})
      });
      userService
      .profileData(username,token)
      .then(res=> {
        
        this.setState({profileData:res.user.first_name})
      });
    //  userService
    //  .user(token)
    //  .then(res=> {
    //    if(res.status === 'success'){
    //      this.setState({profiles:res.users})
    //    }
    //  })
    }
      }
    render() {

     const {anchorEl,allItems,followers,profileData} =this.state;
         
     console.log(this.props.location)
        return (
        
            <div>
               <Grid container style={style.profileTop} className="profilenav">
                        
                        <Link to="/discover"><Grid item style={style.backButton} xs={2}>
                           <IconButton aria-label="delete">
                            <ArrowBackIcon /> 
    </IconButton>
     </Grid></Link>
                            <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading}>{profileData} Interested in</Box>  
                            </Grid>
                      
                        </Grid>
             <Box mt={1} />
           
             <Container fixed>
<Box mt={2}></Box>


{/* style={profileShow} */}
<Grid  container>
    {
    followers === "" ? "Loading" :
    followers.users.length === 0 ? <Box textAlign="center">No Interests Yet</Box>:
    followers.users.map((images)=>(
<React.Fragment>
<Grid onClick={this.onClickProfile.bind(this,images)} style={style.profileImg} key={images.id} item xs={3}>
    {images.profile_pic === null ? <Avatar style={style.Avatar}>{images.no_profile_pic}</Avatar> :   <img src={images.length === 0 ? "" : images.profile_pic} width="100%" style={style.filterImage} alt="profile picture"/>}
{/* <img src={images.img} style={style.filterImage}/> */}
</Grid>
<Grid style={style.profileDetails} item xs={9}>
  <Box onClick={this.onClickProfile.bind(this,images)}>
<Box className="fontSailecMedium" fontSize={14}>{images.name}</Box>
<Box color="#8D92A3" fontSize={12} className="fontSailecRegular"><LocationOnOutlinedIcon style={style.locationIcon}/> {images.location}</Box>
{/* <Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}><img src={FemaleIcon} style={style.imgIcon}/>{`${images.age} Yrs`}</Box> */}
            <Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}>{images.sex}</Box>
            {/* <Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}>{images.location}</Box> */}
            </Box>
<Button variant="contained" color="secondary" style={style.buttonSecondary}>
    <FavoriteIcon style={style.heartIcon}/>Show Interest
  </Button>
  
</Grid>
<Grid item xs={12}>
<Box mt={3} />
 <Divider />
 <Box mt={2} />
</Grid>
</React.Fragment>


    ))}
     </Grid>


            </Container>
            
  
</div>
        
           
        )
}
    }
    export default withRouter(RegisterUserName)
const style={
    root: {
      borderRadius:39,
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
      },
      profileTop:{
        borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
      },
      image:{
        maxWidth:"100%",
        borderRadius:5,
      },
      button:{
        padding:4,
      },
      backArrow:{
        marginTop:10,
      },
      cancelButton:{
        textTransform: "capitalize",
        marginTop:4,
        fontSize:14,
        fontFamily:"SailecRegular!important",
      } ,
      filterImage:{
        objectFit:"cover",
        borderRadius:"50%",
        marginTop:10,
        width: 65,
      height: 65,
      },
      boxDisplay:{
        display:"inline-block",
        marginRight:4,
        padding:"2px 4px",
        borderRadius:2,
        fontFamily:"SailecRegular!important",
        fontSize:12,
        textTransform:'capitalize',
      },
      locationIcon:{
        marginLeft:-5,
        fontSize:17,
      },
      imgIcon:{
        width: 8,
        marginRight:3,
        marginTop: -3,
      },
      buttonSecondary:{
        fontSize:12,
        textTransform:'capitalize',
        marginTop:7,
      },
      heartIcon:{
        fontSize:17,
        marginRight:3,
      },
      profileDetails:{
        paddingLeft:10,
      },
      profileImg:{
        textAlign:'center',
        
      },
      profileHeading:{
        paddingTop:10,
        textTransform:'capitalize',
        
    },
    Avatar:{
      marginLeft:"auto",
      marginRight:"auto",
      background:"#5CB85C",
      fontSize:20,
      fontFamily: 'SailecBold',
      width: 65,
      height: 65,
    }
}


