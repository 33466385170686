import Joi from "joi";

// function for validating form
export const validate = (values, schema) => {
    const options = { abortEarly: false };
    const errors = {};
    const { error } = Joi.validate(values.form, schema, options);
    if (!error) return errors;
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
};

// function for validating single property of a form
export const validateProperty = (name, value, schema) => {
    if (!(name in schema)) {
        return "";
    }
    const field = { [name]: value };
    const subSchema = { [name]: schema[name] };

    const { error } = Joi.validate(field, subSchema);
    return error ? error.details[0].message : "";
};