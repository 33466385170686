import React from "react";
import clsx from "clsx";
import { Link,withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Typography,
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  InputBase,
  IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "./assets/images/Logo.png";
import homeBG from "./assets/images/Home.png";
import PlayStore from "./assets/images/playstore.png";
import Register from "./assets/images/Register.png";
import Zoom from "./assets/images/zoom.png";
import Meeting from "./assets/images/meeting.png";
import Marriage from "./assets/images/Marriage.png";
import BlogBg from "./assets/images/background.png";
import Why from "./assets/images/why.png";
import Why2 from "./assets/images/Why-2.png";
import Why3 from "./assets/images/Why-3.png";
import Why4 from "./assets/images/Why-4.png";
import Why5 from "./assets/images/Why-5.png";
import Video from "./_common/video";
import Slider from "./_common/slider";
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./Home.css";
import AutocompletePlaces from "./_common/AutocompletePlaces";
import Growry from './assets/images/dowry.png';
import {MaritalStatus} from './_common/data';

const useStyles = makeStyles(theme => ({
  homeBG: {
    backgroundImage: `url(${homeBG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  blogBg: {
    backgroundImage: `url(${BlogBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 48
  }
}));

const HomeNav = () => {
  return (
    <AppBar position="static" className="navBar">
      <div id="top">
        <Container fixed>
          <Toolbar>
            <Button>
              <img src={Logo} width="80px" alt="Logo" />
            </Button>
            <Typography variant="h6" className="title"></Typography>
            <Link to="/login">
              <Button
                variant="contained"
                color="secondary"
                className={clsx("buttonLogin", "fontSailecMedium")}
              >
                Login
              </Button>
            </Link>
          </Toolbar>
        </Container>
      </div>
    </AppBar>
  );
};

const Heading = () => {
  return (
    <Container fixed>
      <Box
        textAlign="center"
        fontSize={34}
        mt={6}
        pt={7}
        lineHeight={2}
        className={clsx("text-white", "fontShareenath")}
      >
        vf]Hg'xf]; hLjg ;fyL
      </Box>
      <Box
        textAlign="center"
        fontSize={34}
        lineHeight={0}
        className={clsx("text-white", "fontShareenath")}
      >
        {`tkfO{+ glhs}`}
      </Box>
    </Container>
  );
};

const AlreadyRegister = () => {
  return (
    <Grid container className="alreadyRegister">
      <Grid item xs={12} sm={12} className="memberTransform">
        <Box textAlign="center" fontSize={14} className="fontSailecMedium">
          Already a NepaliVivah member?
        </Box>
        <Box
          textAlign="center"
          fontSize={14}
          color="#8D92A3"
          className="fontSailecRegular"
          mx={4}
          mt={2}
        >
          नेपालिविवाहमा पहिले रजिस्टर गर्नु भएको छ भने यहाँ लोगिन गर्नुहोस
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Link to="/login">
          <Button className={clsx("loginbutton", "fontSailecBold")}>
            login
          </Button>
        </Link>
        <img src={Growry} width="100%"/>
        <Box fontSize={24} textAlign="center" pt={2} className="fontShareenath">दाइजो प्रथा विरुद्ध हाम्रो आवाज</Box>
        <Box fontSize={14} textAlign="center" pb={2} className="fontSailecRegular" px={2}>
          NepaliVivah stands against the ridiculous dowry system.
        </Box>
      </Grid>
    </Grid>
  );
};

const DownloadApp = () => {
  return (
    <Box bgcolor="#F1F3F8">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box
            textAlign="center"
            className="fontSailecRegular"
            pt={4}
            pb={1}
            fontSize={14}
            color="#8D92A3"
          >
            Download the app
          </Box>
          <Box textAlign="center" mb={2}>
            <a
              href="https://play.google.com/store/apps/details?id=com.main.nepalivivah"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img src={PlayStore} width="180px" alt="Play Store Logo" />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const EasyToUseCard = props => {
  return (
    <Grid item xs={6} sm={6}>
      <Box pr={1} my={2}>
        <Box
          textAlign="center"
          pb={3}
          pt={2}
          boxShadow={2}
          className="customCard"
        >
          <img src={props.src} width="100px" alt="Card Icon" />
          <Box
            textAlign="center"
            fontSize={14}
            className="fontSailecRegular"
            color="#8D92A3"
          >
            {props.text}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const EasyToUseSection = () => {
  return (
    <Container fixed>
      <Box
        textAlign="center"
        pt={4}
        className="fontSailecRegular"
        pb={2}
        fontSize={24}
        color="#000000"
      >
        नेपालीविवाह चलाउन सजीलो छ
      </Box>
      <Grid container>
        <EasyToUseCard src={Register} text="रजिस्टर गर्नुहोस" />
        <EasyToUseCard src={Zoom} text="जीवनसाथी खोजनुहोस" />
        <EasyToUseCard src={Meeting} text="एकअर्कालाइ जान्नुहोस" />
        <EasyToUseCard src={Marriage} text="विवाह बन्धनमा बांधिनुहोस" />
      </Grid>
    </Container>
  );
};

const BlogSection = props => {
  return (
    <Box className={props.classes.blogBg} py={5}>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              textAlign="center"
              fontSize={14}
              className="fontSailecRegular"
              color="#ffffff"
            >
              नेपालीविवाहले धेरै नेपालीहरुलाई जीवनसाथी खोज्न मदद गरेको छ।
              तपाईंको जीवनसाथीको खोजमा हामी तपाईंसंग छौं
            </Box>
            <Button
              variant="contained"
              color="secondary"
              className={clsx("buttonFull", "fontSailecBold")}
            >
              read blog
            </Button>
            <Box py={2} />
            <Button className="buttonTransparent">REPORT YOUR MARRIAGE</Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const AddProfileCards = props => {
  return (
    <Box
      textAlign="center"
      my={2}
      pb={3}
      pt={2}
      bgcolor={props.bg}
      className={props.compClass}
    >
      <img src={props.src} width="100px" alt="Profile Card Icon" />
      <Box pt={2} fontSize={14} className="fontSailecRegular" color="#8D92A3">
        {props.textLine1}
      </Box>
      <Box fontSize={14} className="fontSailecRegular" color="#8D92A3">
        {props.textLine2}
      </Box>
    </Box>
  );
};

const AddProfileSection = () => {
  return (
    <Container fixed>
      <Box
        textAlign="center"
        pt={8}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        नेपालीविवाहमा हुनु पर्ने
      </Box>
      <Box
        textAlign="center"
        pb={2}
        className="fontSailecRegular"
        fontSize={24}
        color="#22242A"
      >
        मुख्य कारणहरु
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <AddProfileCards
            bg="#F1F3F8"
            compClass="customCard"
            src={Why}
            textLine1="See who expresses interest in you"
            textLine2="and cancels interest in you in real time"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Why2}
            textLine1="See who a member is interested in "
            textLine2="and when cancels interest"
          />
          <AddProfileCards
            bg="#F1F3F8"
            compClass={clsx("customCard", "customCardHeight")}
            src={Why3}
            textLine1="Our commitment against dowry and "
            textLine2="violence against women"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Why4}
            textLine1="Follow the member you are "
            textLine2="interested in"
          />
          <AddProfileCards
            bg="#F1F3F8"
            compClass={clsx("customCard", "customCardHeight")}
            src={Why5}
            textLine1="Cheapest price in the industry"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Register}
            textLine1="Only serious members"
          />
        </Grid>
      </Grid>
      <Box
        textAlign="center"
        pt={5}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        आफ्नो प्रोफाइल यो पेज मा
      </Box>
      <Box
        textAlign="center"
        pb={2}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        हेर्न चाहनु हुन्छ ?
      </Box>
      <Button
        color="secondary"
        className={clsx("buttonFull", "fontSailecMedium")}
        variant="contained"
        href="#top"
      >
        Add your profile
      </Button>
    </Container>
  );
};

const SafeHands = () => {
  return (
    <Box bgcolor="#F1F3F8" mt={6} pb={20}>
      <Container fixed>
        <Box
          textAlign="center"
          pt={5}
          fontSize={24}
          color="#000000"
          className="fontSailecRegular"
        >
          तपाई नेपालीविवाहको सुरक्षित
        </Box>
        <Box
          textAlign="center"
          pb={4}
          fontSize={24}
          color="#000000"
          className="fontSailecRegular"
        >
          हात मा हुनुहुन्छ
        </Box>
        <Box bgcolor="#fff" py={5} px={2} className="borRadius5">
          <Slider />
        </Box>
      </Container>
    </Box>
  );
};

const StartToday = () => {
  return (
    <Container fixed>
      <Box className="transVideo">
        <Video />
      </Box>
      <Box className="transVideoSec">
        <Box
          textAlign="center"
          fontSize={14}
          color="#8D92A3"
          className="fontSailecRegular"
          px={2}
          mt={3}
        >
          Join the exclusive family of NepaliVivah today and enjoy the ride of
          finding someone who truly understands you. Finding a great partner on
          NepaliVivah is a beautiful experience and you will enjoy it.
        </Box>
        <Button
          href="#top"
          variant="contained"
          color="secondary"
          className={clsx("buttonFull", "fontSailecMedium")}
        >
          start today
        </Button>
      </Box>
    </Container>
  );
};

const Form = props => {
  
  const [state, setState] = React.useState({
    sex: 'female',
    marital_status: 1,
    marital_status_label: 'Single',
    month: 'January',
    year: '2020',
    location: null,
  });
  const months =[
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const yearsTotal =[
   "2020",
   "2021",
   "2022",
   "2023",
   "2024",
   "2025",
   "2026",
   "2027",
   "2028",
   "2029",
   "2030",
  ];
  const currentDate= new Date();
  var futureMonth = months.filter((value)=>{
    return months.indexOf(value) > currentDate.getMonth() + 3
})
var years = yearsTotal.filter((value)=>{
  if(currentDate.getMonth() + 3 > 12){
  return yearsTotal >= `${currentDate.getFullYear() + 1}  `
  }
  else {
    return yearsTotal >= `${currentDate.getFullYear()} `
  }
})
  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    
    if(name == 'marital_status') {
      let label = MaritalStatus.filter(ms => ms.value == event.target.value).map(lang => lang.label)[0]

      setState({
        ...state,
        marital_status_label: label,
      });
    }
  };
 
  const changeLocation = (value) => {
    setState({...state, location: value})
  }
  
  return (
    <Box px={4} pb={5} className="homeForm" boxShadow={2} pt={3}>
      <Grid container>
        <Grid item sm={2} xs={2}>
          <Box
            mt="9px"
            fontSize={14}
            fontWeight={500}
            color="#8D92A3"
            className="fontSailecRegular"
          >
            I am
          </Box>
        </Grid>
        <Grid item sm={10} xs={10}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="position"
              name="position"
              value={state.sex}
              onChange={handleChange('sex')}
              row
            >
              <FormControlLabel
                value="male"
                fontWeight={500}
                control={<Radio className="radio" />}
                label="Male"
                labelPlacement="end"
              />
              <FormControlLabel
                value="female"
                fontWeight={500}
                control={<Radio color="secondary" className="radioFemale" />}
                label="Female"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={2} xs={2}>
          <Box
            fontSize={14}
            mt="12px"
            color="#8D92A3"
            className="fontSailecRegular"
          >
            I am
          </Box>
        </Grid>
        <Grid item sm={10} xs={10}>
        <FormControl className="formControlHome">
        <Select
          native
          value={state.age}
          IconComponent={ExpandMoreIcon}
          className="SelectHome"
          placeholder="Month"
          onChange={handleChange('marital_status')}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          {
            MaritalStatus.filter(ms => ms.value !== "D").map((item, key) => {
              return <option key={key} value={item.value}>{item.label}</option>
            })
          }
        </Select>
      </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            mt={4}
            fontSize={14}
            color="#8D92A3"
            className="fontSailecRegular"
          >
            Looking to get married by
          </Box>
        </Grid>
        <Grid item sm={6} xs={6}>
        <FormControl className="formControlHome">
        <Select
          native
          value={state.age}
          IconComponent={ExpandMoreIcon}
          className="SelectHome"
          placeholder="Month"
          onChange={handleChange('month')}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          {state.year === `${currentDate.getFullYear()}` ? futureMonth.map((value)=>(
          <option value={value}>{value}</option>
          ))
        :
        months.map((value)=>(
          <option value={value}>{value}</option>
          ))
        }
    
        </Select>
      </FormControl>
        </Grid>
        <Grid item sm={6} xs={6}>
        <FormControl className="formControlHome">
        <Select
          native
          value={state.age}
          IconComponent={ExpandMoreIcon}
          className="SelectHome"
          placeholder="Month"
          onChange={handleChange('year')}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          {years.map((value)=>(
          <option value={value}>{value}</option>
          ))}
        </Select>
      </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box
            mt={4}
            mb={2}
            fontSize={14}
            color="#8D92A3"
            className="fontSailecRegular"
          >
            I live in
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <AutocompletePlaces location={changeLocation}/>
            <Button
              disabled={state.location === null ? true : false}
              variant="contained"
              color="secondary"
              size="medium"
              onClick={props.onSubmit.bind(this,state)}
              className={clsx("registerButton", "fontSailecRegular")}
            >
              Continue Search
            </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

function Home(props) {
  
  const classes = useStyles();
  if(localStorage.getItem('login') !== null){
    props.history.push("/discover")
  }

  const onSubmit = (value) =>{
    var targetwedding=`${value.month} ${value.year}`;
    localStorage.setItem("register", JSON.stringify({
      state: {
        targetwedding: targetwedding,
        sex: value.sex,
        marital_status: value.marital_status,
        marital_status_label: value.marital_status_label,
        location: value.location,
        page: 1,
      }
    }))

    props.history.push("/register-step1")
  }
  return (
    <div>
      <Box className={classes.homeBG} pb={10}>
        <HomeNav />
        <Heading />
      </Box>
      <Container fixed>
        <Form classes={classes} onSubmit={onSubmit}/>
        <AlreadyRegister />
      </Container>
      <DownloadApp />
      <EasyToUseSection />
      <BlogSection classes={classes} />
      <AddProfileSection />
      <SafeHands />
      <StartToday />
    </div>
  );
}
export default withRouter(Home);
