import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  buttonWidth2: {
    marginTop: 100,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  buttonWidth3: {
    marginTop: 0,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none",
    color: "#8D92A3"
  },
  textarea: {
    backgroundColor: "#faf9f7",
    "& .MuiInput-underline:after": {
      border: "1px solid #ebedeb!important"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #ebedeb!important"
      },
      "&:hover fieldset": {
        border: "1px solid #ebedeb !important"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #ebedeb !important"
      }
    }
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
}));

export default function LinearDeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Box
          fontSize={24}
          mt={3}
          className={classes.fontSailecBold}
          color="#000"
          textAlign="center"
        >
          Describe a little bit about you and your family
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          mt={1}
          color="#707070"
          fontSize={14}
        >
          तपाई र तपाइको परिवार बारेमा वर्णन गर्नुहोस्
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={5} />
            <TextField
              className={classes.textarea}
              id="outlined-multiline-static"
              multiline
              rows="10"
              placeholder="Write about you and your family"
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Link to="/profile">
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonWidth2}
              >
                Update
              </Button>
            </Link>
            {/* <Link to="/"> */}
              <Button className={classes.buttonWidth3}>Go Back</Button>
            {/* </Link> */}
            <Box mb={3}></Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
