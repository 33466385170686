import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import DiscoverActivate from '../assets/images/Discover-Active@3x.png';
import DiscoverDisable from '../assets/images/Discover-Disable@3x.png';
import ChatActivate from '../assets/images/Chat-Active@3x.png';
import ChatDisable from '../assets/images/Chat-Disable@3x.png';
import FeedActivate from '../assets/images/Feed-Active@3x.png';
import FeedDisable from '../assets/images/Feed-Disable@3x.png';
import NotificationActivate from '../assets/images/Notification-Active@3x.png';
import NotificationDisable from '../assets/images/Notification-Disable@3x.png';
import ProfileActivate from '../assets/images/Profile-Active@3x.png';
import ProfileDisable from '../assets/images/Profile-Disable@3x.png';
const styles = (theme) => ({
    root2: {
        flexGrow: 1,
        maxWidth: 500,
      },
      tabs:{
        position:"fixed",
        bottom:"-8px",
        display:"block",
        width:"100%",
        backgroundColor:'#fff',
        borderTop:"1px solid #E3E3E3",
      },
      tab:{
        fontSize:"10px!important",
        textTransform:'capitalize',
        fontFamily:"SailecRegular!important",
        '&:focus , &$focusVisible':{
            outlineColors:'transparent',
          }
      },
      appbar: {
        backgroundColor: "#fff",
        marginTop: 1,
        boxShadow: "none",
        borderBottom: "1px solid #cccdcf"
      },
    
})
class NavMenu extends Component {
    constructor(props){
        super(props);
        this.state={
            tabsValue:"",
        }
    }
    // Funtion to change to component
     handleChange = (event, newValue) => {
        this.setState({tabsValue:newValue});
        switch (newValue) {
          case 0:
      
      this.props.history.push("/discover")
        break;
      // case 1:
      //   this.props.history.push('/feed')
      //   break;
      // case 2:
      //   this.props.history.push('/feed')
      //   break;
      // case 3:
      //   this.props.history.push('/feed')
      //   break;
      case 3:
        this.props.history.push("/my-account")
        break;
            
        }
      };
    render() {
        const { classes } = this.props;
        
        return (
            <div>
                   <Paper square className={classes.root2}>
      <Tabs
      className={classes.tabs}
        value={this.props.tabValue}
        onChange={this.handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab className={classes.tab} className="discoverNav" icon={ this.props.tabValue === 0 ? <img src={DiscoverActivate} width="24" height="24"/>:
        <img src={DiscoverDisable} width="24" height="24"/>
} label="Discover" />
        {/* <Tab className={classes.tab} className="discoverNav" icon={<svg id="Group_670" data-name="Group 670" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Notification" transform="translate(0 0)">
    <rect id="Box" width="24" height="24" fill="none"/>
    <g id="Group_1050" data-name="Group 1050" transform="translate(8.592 -2.5)">
      <circle id="Ellipse_66" data-name="Ellipse 66" cx="1.5" cy="1.5" r="1.5" transform="translate(-4.5 6.5)" fill="#8d92a3"/>
      <path id="Path_1479" data-name="Path 1479" d="M8,8.5H0a.5.5,0,0,0,0,1H8a.5.5,0,0,0,0-1Z" fill="#8d92a3"/>
      <path id="Path_1480" data-name="Path 1480" d="M8,6.5H0a.5.5,0,0,0,0,1H8a.5.5,0,0,0,0-1Z" fill="#8d92a3"/>
      <path id="Path_1481" data-name="Path 1481" d="M11.908,3.5h-17A2.5,2.5,0,0,0-7.592,6V23a2.5,2.5,0,0,0,2.5,2.5h17a2.5,2.5,0,0,0,2.5-2.5V6A2.5,2.5,0,0,0,11.908,3.5Zm1.5,19.5a1.5,1.5,0,0,1-1.5,1.5h-17a1.5,1.5,0,0,1-1.5-1.5V12.5h20Zm0-11.5h-20V6a1.5,1.5,0,0,1,1.5-1.5h17a1.5,1.5,0,0,1,1.5,1.5Z" fill="#8d92a3"/>
    </g>
  </g>
</svg>
} label="Feed" /> */}
        <Tab className={classes.tab} className="discoverNav" icon={this.props.tabValue === 1 ? <img src={ChatActivate} width="24" height="24"/>:
        <img src={ChatDisable} width="24" height="24"/>} label="Chat" />
        <Tab className={classes.tab} className="discoverNav" icon={this.props.tabValue === 2 ? <img src={NotificationActivate} width="24" height="24"/>:
        <img src={NotificationDisable} width="24" height="24"/>} label="Notification" />
        <Tab className={classes.tab} className="discoverNav" icon={this.props.tabValue === 3 ? <img src={ProfileActivate} width="24" height="24"/>:
        <img src={ProfileDisable} width="24" height="24"/>} label="profile" />
      </Tabs>
    </Paper>
            </div>
        )
    }
}
export default withRouter(withStyles(styles, { withTheme: true })(NavMenu));