import React, { Component } from 'react';
import clsx from "clsx";
import { withStyles,useTheme } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Avatar from '@material-ui/core/Avatar';
import { userService } from "../_services/user.service";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ItemsCarousel from "react-items-carousel";
import DpImage from '../assets/images/Rectangle -8.png';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import NavMenu from './NavMenu';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  tabpanel: {
    paddingTop: 0
  },
  textRight: {
    textAlign: "right"
  },
  buttonIcon: {
    padding: "0px",
    minWidth: 20
  },
  appbar: {
    backgroundColor: "#fff",
    marginTop: 1,
    boxShadow: "none",
    borderBottom: "1px solid #cccdcf"
  },
  divider: {
    height: "3px",
    width: "100%",
    backgroundColor: "#F1F3F8"
  },
  gridpassing: {
    padding: "0px 3px"
  },
  title: {
    fontSize: 14
  },
  buttonSecondry: {
    fontSize: 12,
    textTransform: "capitalize",
    borderColor: "#f2f2f2"
  },
  icon: {
    fontSize: '14px!important',
    marginRight: '5px!important'
  },
  locationIcon: {
    fontSize: '14px!important',
    marginRight: '5px!important'
  },
  womenIcon: {
    fontSize: '13px!important',
    marginLeft: '3px!important',
    marginRight: '5px!important'
  },
  content: {
    padding:'9px!important'
  },
  cancelButton: {
    position: "absolute",
    top: 6,
    right: 6,
    color: "#fff",
    background: "rgba(34,34,34,0.5)",
    borderRadius: "50%",
    fontSize: 20,
    padding: 3
  },
  card: {
    maxWidth: "100%",
    marginBottom:20,
  },
  boxDisply:{
      display:"inline-block",
      marginRight:4,
      borderRadius:2,
      fontFamily:"SailecRegular!important",
      fontSize:12,
  },
  locationIcon:{
      fontSize:18,
      marginTop:-3,
      paddingRight:3,
      color:'#8D92A3',
  },
  boxMT:{
      marginTop:3,
  },
  textAlign:{
      textAlign:'center',
  },
  fab:{
      boxShadow:"none!important",
  },
  pt0:{
      paddingTop:0,
  },
  skeletonCenter:{
    marginTop:10,
    marginLeft:'auto',
    marginRight:'auto',
  },
  avatarGreen:{
    height: 140,
    width: "100%",
    borderRadius: 0,
    fontSize: 42,
    background:"#5CB85C"
  },
})

const DiscoverNavs = (props) =>{
  const theme = useTheme();
  const [Values,setValues] = React.useState(0)
  const [value, setValue] = React.useState(0);
  const [activeItemIndex, activeIndexChange] = React.useState(0);
  const [activeItemIndex2, activeIndexChange2] = React.useState(0);
const [selectValue,setSelectValue]=React.useState(0)
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
// const  handleChange = (event, newValue) => {
//   setValues({Values:newValue});
//   switch (newValue) {
//     case 0:
// props.history.push('/discover')
//   break;
// // case 1:
// //   props.history.push('/feed')
// //   break;
// // case 2:
// //   props.history.push('/feed')
// //   break;
// // case 3:
// //   props.history.push('/feed')
// //   break;
// case 3:
//   props.props.history.push('/my-account')
//   break;
      
//   }
// };

const onClickAction = (value) => {
  props.props.history.push({
    pathname:"/profile",
    search: value.username
  })
}
const onClickSelect=(username)=>{
  const userData =JSON.parse(localStorage.getItem('login'));
  const {token} = userData.details;
  userService
  .interest(username,token)
  .then((res)=>{
console.log(res)
  })
}
  
  

const {navTab} = Values;
const chevronWidth = 0;
  return(
    <>
      <Container fixed>
                    <Box mt={1} />
                <Paper component="form" className={props.classes.root}>
      <IconButton className={props.classes.iconButton} aria-label="menu">
      <SearchIcon />
      </IconButton>
      <InputBase
        className={props.classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
        onFocus={()=> props.props.history.push('/search-a')}
        readOnly
      />
      <Link to="/discover-settings"><IconButton className={props.classes.iconButton} aria-label="directions">
        <TuneIcon />
      </IconButton></Link>
    </Paper>
                </Container>
                {/* discover tabs start */}
                <AppBar position="static" className={props.classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab className={props.classes.tab} label="Discover" {...a11yProps(0)} />
          <Tab className={props.classes.tab} label="Interests" {...a11yProps(1)} />
          <Tab className={props.classes.tab} label="Suggestions" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          className={props.classes.tabpanel}
          value={value}
          index={0}
          dir={theme.direction}
        >
          
<div>
          <div style={{ padding: `0 ${chevronWidth}px` }}>
              {props.DataSuggestions.length === 0 ? (
               <div>
                 <Skeleton variant="text" height={50}/>
                 <Box py={1}/>
                 <Grid container spacing={3}>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   <Box mt={2}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   
                   <Box mt={1}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                 </Grid>
                 <Box my={3} />
                 
                 </div>
              ) : (
                <Box>
                  <Grid container>
                    <Grid item xs={8} sm={8}>
                      <Box className="fontSailecMedium" fontSize={16}>
                      Suggestions
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} className={props.classes.textRight}>
                    <Link to="my-interest">
                      <Button
                        className={props.classes.buttonIcon}
                      >
                        <ArrowForwardIcon />
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={2} />
                  <div>
                    <ItemsCarousel
                      requestToChangeActive={activeIndexChange2}
                      activeItemIndex={activeItemIndex2}
                      numberOfCards={2}
                      gutter={20}
                      leftChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowBackIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      rightChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      outsideChevron
                      chevronWidth={chevronWidth}
                    >
                      {props.DataSuggestions.map(tile => (
                      
                      <Card>
                      <CardActionArea>
                        {/* <CloseIcon
                          onClick={props.onCancelClick.bind(this, tile)}
                          className={props.classes.cancelButton}
                        /> */}
                        <Box onClick={onClickAction.bind(this, tile)}>
                        {
                                tile.profile_pic === null ? <Box className="discoverAvatar"><Avatar className={props.classes.avatarGreen}>{tile.no_profile_pic}</Avatar></Box>
                              :
                              <CardMedia
                                 className="discoverImg"
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={tile.profile_pic}
                                title={tile.name.slice(0,14)}
                              />
}
                          <CardContent className={props.classes.content}>
                            <Typography
                              className="fontSailecMedium"
                              className={props.classes.title}
                            >
                              {tile.name.slice(0,14)}
                              
                            </Typography>
                            <Box
                              fontSize={12}
                              className="fontSailecRegular"
                              color="#8D92A3"
                            >
                              <i
                                className={clsx(props.classes.womenIcon,'fas fa-venus')}></i>{" "}
                              {`${tile.age} Yrs, ${tile.sex}`}
                            </Box>
                            <Box
                              fontSize={12}
                              className="fontSailecRegular"
                              color="#8D92A3"
                            >
                              <LocationOnOutlinedIcon
                                className={props.classes.locationIcon}
                              />
                              {`${tile.location.slice(0,11)}...`}
                            </Box>
                          </CardContent>
                        </Box>
                      </CardActionArea>

                      <CardActions>
                      {props.state[tile.username] === true ?
   <Button
   fullWidth
   className={props.classes.buttonSecondry}
   size="small"
   onClick={props.onClickCancelInterest.bind(this,tile.username)}
   variant="outlined"
   color="default"
   // onClick={onClickSelect.bind(this, tile)}
 >
   <CancelIcon className={props.classes.icon} /> cancel
 </Button>
 :
 <Button
                              fullWidth
                              className={props.classes.buttonSecondry}
                              size="small"
                              onClick={props.onClickInterest.bind(this,tile.username)}
                              variant="outlined"
                              color="secondary"
                              // onClick={onClickSelect.bind(this, tile)}
                            >
                              <FavoriteIcon className={props.classes.icon} /> Show Interest
                            </Button>
}
                      </CardActions>
                    </Card>
                        ))}
                    </ItemsCarousel>
                  </div>
                  </Box>
                  )}
                  
                  <Box mt={3} mb={2} className={props.classes.divider}></Box>
                  {props.DataRecentJoined.length === 0 ? (
               <div>
                 <Skeleton variant="text" height={50}/>
                 <Box py={1}/>
                 <Grid container spacing={3}>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   <Box mt={2}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   
                   <Box mt={1}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                 </Grid>
                 <Box my={3} />
                 </div>
              ) : (
                <Box>
                  <Grid container>
                    
                    <Grid item xs={6} sm={6}>
                      <Box className="fontSailecMedium" fontSize={16}>
                        Recently Joined
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} className={props.classes.textRight}>
                      <Link to="recently-joined">
                      <Button
                        className={props.classes.buttonIcon}
                      >
                        <ArrowForwardIcon />
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={2} />
                  <div>
                    <ItemsCarousel
                      requestToChangeActive={activeIndexChange}
                      activeItemIndex={activeItemIndex}
                      numberOfCards={2}
                      gutter={20}
                      leftChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowBackIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      rightChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      outsideChevron
                      chevronWidth={chevronWidth}
                    >
                      {props.DataRecentJoined.map(tile => (
                        
                        <Card>
                          <CardActionArea>
                            {/* <CloseIcon
                              onClick={props.onCancelClick.bind(this, tile)}
                              className={props.classes.cancelButton}
                            /> */}
                            <Box onClick={onClickAction.bind(this, tile)}>
                            {
                                tile.profile_pic === null ? <Box className="discoverAvatar"><Avatar className={props.classes.avatarGreen}>{tile.no_profile_pic}</Avatar></Box>
                              :
                              <CardMedia
                                 className="discoverImg"
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={tile.profile_pic}
                                title={tile.name.slice(0,14)}
                              />
}
                              <CardContent className={props.classes.content}>
                                <Typography
                                  className="fontSailecMedium"
                                  className={props.classes.title}
                                >
                                  {tile.name.slice(0,14)}
                                  
                                </Typography>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <i
                                    className={clsx(props.classes.womenIcon,'fas fa-venus')}></i>{" "}
                                  {`${tile.age} Yrs, ${tile.sex}`}
                                </Box>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <LocationOnOutlinedIcon
                                    className={props.classes.locationIcon}
                                  />
                                  {`${tile.location.slice(0,11)}...`}
                                </Box>
                              </CardContent>
                            </Box>
                          </CardActionArea>

                          <CardActions>                  
                            {props.state[tile.username] === true ?
                            <Button
                            fullWidth
                            className={props.classes.buttonSecondry}
                            size="small"
                            onClick={props.onClickCancelInterest.bind(this,tile.username)}
                            variant="outlined"
                            color="default"
                            // onClick={onClickSelect.bind(this, tile)}
                          >
                              
                            <CancelIcon className={props.classes.icon} /> cancel
                          </Button>
                          :
                          <Button
                              fullWidth
                              className={props.classes.buttonSecondry}
                              size="small"
                              onClick={props.onClickInterest.bind(this,tile.username)}
                              variant="outlined"
                              color="secondary"
                              // onClick={onClickSelect.bind(this, tile)}
                            >
                              <FavoriteIcon className={props.classes.icon} /> Show Interest
                            </Button>
}
                          
                       
                          </CardActions>
                        </Card>
                      ))}
                    </ItemsCarousel>
                  </div>
                  </Box>
                  )}
                  <Box mt={3} mb={2} className={props.classes.divider}></Box>
                  {props.DataSuggestions.length === 0 ? (
               <div>
                <Skeleton variant="text" height={50}/>
                 <Box py={1}/>
                 <Grid container spacing={3}>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   <Box mt={2}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                   <Grid item xs={6}>
                   <Box border="1px solid rgba(0, 0, 0, 0.04)"> 
                   <Skeleton variant="rect" width='100%' height={118} />
                   <Container fixed>
                   <Skeleton variant="text" width="100%" height={30}/>
                   <Skeleton variant="text" width="100%" />
                   <Skeleton variant="text" width="100%" />
                   </Container>
                   
                   <Box mt={1}/>
                  <Container fixed> <Skeleton variant="text" width="100%" height={40} />
                  </Container>
                  </Box>
                   </Grid>
                 </Grid>
                 <Box my={3} />
                 </div>
              ) : (
                <Box>
                  <Grid container>
                    <Grid item xs={8} sm={8}>
                      <Box className="fontSailecMedium" fontSize={16}>
                      You Might be Interested In
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} className={props.classes.textRight}>
                    <Link to="my-interest">
                      <Button
                        className={props.classes.buttonIcon}
                      >
                        <ArrowForwardIcon />
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={2} />
                  <div>
                    <ItemsCarousel
                      requestToChangeActive={activeIndexChange2}
                      activeItemIndex={activeItemIndex2}
                      numberOfCards={2}
                      gutter={20}
                      leftChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowBackIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      rightChevron={
                        <IconButton
                          color="secondary"
                          aria-label="delete"
                          size="small"
                        >
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                      }
                      outsideChevron
                      chevronWidth={chevronWidth}
                    >
                      {props.profiles.map(tile => (
                      
                      <Card>
                      <CardActionArea>
                        {/* <CloseIcon
                          onClick={props.onCancelClick.bind(this, tile)}
                          className={props.classes.cancelButton}
                        /> */}
                        <Box onClick={onClickAction.bind(this, tile)}>
                        {
                                tile.profile_pic === null ? <Box className="discoverAvatar"><Avatar className={props.classes.avatarGreen}>{tile.no_profile_pic}</Avatar></Box>
                              :
                              <CardMedia
                                 className="discoverImg"
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={tile.profile_pic}
                                title={tile.name.slice(0,14)}
                              />
}
                          <CardContent className={props.classes.content}>
                            <Typography
                              className="fontSailecMedium"
                              className={props.classes.title}
                            >
                              {tile.name.slice(0,14)}
                              
                            </Typography>
                            <Box
                              fontSize={12}
                              className="fontSailecRegular"
                              color="#8D92A3"
                            >
                              <i
                                className={clsx(props.classes.womenIcon,'fas fa-venus')}></i>{" "}
                              {`${tile.age} Yrs, ${tile.sex}`}
                            </Box>
                            <Box
                              fontSize={12}
                              className="fontSailecRegular"
                              color="#8D92A3"
                            >
                              <LocationOnOutlinedIcon
                                className={props.classes.locationIcon}
                              />
                              {`${tile.location.slice(0,11)}...`}
                            </Box>
                          </CardContent>
                        </Box>
                      </CardActionArea>

                      <CardActions>
                      {props.state[tile.username] === true ?
   <Button
   fullWidth
   className={props.classes.buttonSecondry}
   size="small"
   onClick={props.onClickCancelInterest.bind(this,tile.username)}
   variant="outlined"
   color="default"
   // onClick={onClickSelect.bind(this, tile)}
 >
   <CancelIcon className={props.classes.icon} /> cancel
 </Button>
 :
 <Button
                              fullWidth
                              className={props.classes.buttonSecondry}
                              size="small"
                              onClick={props.onClickInterest.bind(this,tile.username)}
                              variant="outlined"
                              color="secondary"
                              // onClick={onClickSelect.bind(this, tile)}
                            >
                              <FavoriteIcon className={props.classes.icon} /> Show Interest
                            </Button>
}
                      </CardActions>
                    </Card>
                        ))}
                    </ItemsCarousel>
                  </div>
                  </Box>
              )}
                
              
            </div>
    </div>
        </TabPanel>
        <TabPanel
          className={props.classes.tabpanel}
          value={value}
          index={1}
          dir={theme.direction}
        >
          {/* my Interest Tab started */}
          {/* key={tile.id} */}
          {props.profiles.length === 0 ? (
            <>
          <Box border="1px solid rgba(0, 0, 0, 0.04)" mb={4} pb={3}>
          <Skeleton variant="rect" height={220}/>
          
          <Container fixed>
          <Box py={1} />
          <Skeleton variant="rect" height={30}/>
          <Box py={1} />
          <Skeleton variant="rect" height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={130} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={200} height={20}/>
          <Grid container spacing={3}>
            <Grid item xs={4}>
            <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
          </Grid>
        
          </Container>
          </Box>
          <Box border="1px solid rgba(0, 0, 0, 0.04)" mb={4} pb={3}>
          <Skeleton variant="rect" height={220}/>
          
          <Container fixed>
          <Box py={1} />
          <Skeleton variant="rect" height={30}/>
          <Box py={1} />
          <Skeleton variant="rect" width={130} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={200} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" height={2}/>
          <Grid container spacing={3}>
            <Grid item xs={4}>
            <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
          </Grid>
        
          </Container>
          </Box>
          </>
          ):(
            props.profiles.map(tile => (
              <Card id="boxshadowcard"  onClick={onClickAction.bind(this, tile)} className={props.classes.card}>
                
                    <CardActionArea>
                     {
                                tile.profile_pic === null ? <Box className="discoverAvatar"><Avatar className={props.classes.avatarGreen}>{tile.no_profile_pic}</Avatar></Box>
                              :
                              <CardMedia
                                 className="discoverImg"
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={tile.profile_pic}
                                title={tile.name.slice(0,14)}
                              />
}
                      <CardContent>
                        <Typography className="fontSailecBold" variant="h6" component="h4">
                          {tile.name}
                        </Typography>
                       {/* <Grid container>
                           <Gird item xs
                       </Grid> */}
<Box px={1} bgcolor="#5BC0DE" color="#fff" className={props.classes.boxDisply}>{`${tile.age} Yrs`}</Box>
                    <Box px={1} bgcolor="rgba(191, 74, 63, 0.13)" color="#f50057" className={props.classes.boxDisply}>{tile.sex}</Box>
                    <Box px={1} bgcolor="rgba(76, 191, 63, 0.13)" color="#5CB85C" className={props.classes.boxDisply}>religion</Box>
                      <Box className="fontSailecRegular" className={props.classes.boxMT} fontSize={14} color="#8D92A3"><LocationOnOutlinedIcon className={props.classes.locationIcon}/>{tile.location}</Box>
                      </CardContent>
                    </CardActionArea>
                    <Divider />
                    <Box mb={1} />
                    <CardActions className={props.classes.pt0}>
                        <Grid container>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="secondary" aria-label="favorite" className={props.classes.fab}>
                            <i className="fas fa-2x fa-heart"></i>
          </Fab>
                            </Grid>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="primary" aria-label="add" className={props.classes.fab}>
                            <i className="fas fa-2x fa-comment-dots"></i>
          </Fab>
                            </Grid>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="default" id="defalutButton" aria-label="add" className={props.classes.fab}>
                            <i className="fas fa-2x fa-heart-broken"></i>
          </Fab>
                            </Grid>
                            
                            </Grid>
                   
          
          
          
                    </CardActions>
                  </Card> 
                  ))
          )}
                  {/* my Interest Tab end */}
                  
        </TabPanel>
        <TabPanel
          className={props.classes.tabpanel}
          value={value}
          index={2}
          dir={theme.direction}
        >
                    {/* my Interest Tab started */}
          {/* key={tile.id} */}
          {props.profiles.length === 0 ? (
            <>
          <Box border="1px solid rgba(0, 0, 0, 0.04)" mb={4} pb={3}>
          <Skeleton variant="rect" height={220}/>
          
          <Container fixed>
          <Box py={1} />
          <Skeleton variant="rect" height={30}/>
          <Box py={1} />
          <Skeleton variant="rect" height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={130} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={200} height={20}/>
          <Grid container spacing={3}>
            <Grid item xs={4}>
            <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
          </Grid>
        
          </Container>
          </Box>
          <Box border="1px solid rgba(0, 0, 0, 0.04)" mb={4} pb={3}>
          <Skeleton variant="rect" height={220}/>
          
          <Container fixed>
          <Box py={1} />
          <Skeleton variant="rect" height={30}/>
          <Box py={1} />
          <Skeleton variant="rect" width={130} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" width={200} height={20}/>
          <Box pt={1} />
          <Skeleton variant="rect" height={2}/>
          <Grid container spacing={3}>
            <Grid item xs={4}>
            <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="circle" className={props.classes.skeletonCenter} width={60} height={60} />
            </Grid>
          </Grid>
        
          </Container>
          </Box>
          </>
          ):(
            props.profiles.map(tile => (
              <Card id="boxshadowcard"  onClick={onClickAction.bind(this, tile)} className={props.classes.card}>
                
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt={tile.name}
                        height="240px"
                        image={tile.profile_pic}
                        title={tile.name}
                      />
                      <CardContent>
                        <Typography className="fontSailecBold" variant="h6" component="h4">
                          {tile.name}
                        </Typography>
                       {/* <Grid container>
                           <Gird item xs
                       </Grid> */}
<Box px={1} bgcolor="#5BC0DE" color="#fff" className={props.classes.boxDisply}>{`${tile.age} Yrs`}</Box>
                    <Box px={1} bgcolor="rgba(191, 74, 63, 0.13)" color="#f50057" className={props.classes.boxDisply}>{tile.sex}</Box>
                    <Box px={1} bgcolor="rgba(76, 191, 63, 0.13)" color="#5CB85C" className={props.classes.boxDisply}>religion</Box>
                      <Box className="fontSailecRegular" className={props.classes.boxMT} fontSize={14} color="#8D92A3"><LocationOnOutlinedIcon className={props.classes.locationIcon}/>{tile.location}</Box>
                      </CardContent>
                    </CardActionArea>
                    <Divider />
                    <Box mb={1} />
                    <CardActions className={props.classes.pt0}>
                        <Grid container>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="secondary" aria-label="favorite" className={props.classes.fab}>
                            <i className="fas fa-2x fa-heart"></i>
          </Fab>
                            </Grid>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="primary" aria-label="add" className={props.classes.fab}>
                            <i className="fas fa-2x fa-comment-dots"></i>
          </Fab>
                            </Grid>
                            <Grid className={props.classes.textAlign} item xs={4}>
                            <Fab color="default" id="defalutButton" aria-label="add" className={props.classes.fab}>
                            <i className="fas fa-2x fa-heart-broken"></i>
          </Fab>
                            </Grid>
                            
                            </Grid>
                   
          
          
          
                    </CardActions>
                  </Card> 
                  ))
          )}
                  {/* my Interest Tab end */}
                  
        </TabPanel>
      </SwipeableViews>
                <Box my={7}></Box>
             <NavMenu tabValue={0} />
    </>
  )
}

class RegisterUserName extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
};
    constructor(props) {
        super(props);
        this.state = {
       navTab:0,
       DataSuggestions:'',
       DataRecentJoined:'',
       profileDataSelect:'',
        }
      }
     componentDidMount(){
       console.log(localStorage.getItem('login'))
       if(localStorage.getItem('login') === null){
         this.props.history.push("/")
       }
       else{
       const userData =JSON.parse(localStorage.getItem('login'));
       const {token} = userData.details;
      userService
      .Suggestions(token)
      .then(res=> {
        if(res.status === 'success'){
          this.setState({DataSuggestions:res.users})
        }
      })
      userService
      .recentJoined(token)
      .then(res=> {
        if(res.status === 'success'){
          this.setState({DataRecentJoined:res.users})
        }
      })
    }
     }
      handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({alert:false});
    };
    onClickInterest=(username)=>{
      this.setState({[username]:true})
    const userData =JSON.parse(localStorage.getItem('login'));
    const {token} = userData.details;
    const action ="follow";
    userService
    .interest(username,token,action)
    .then((res)=>{
        res.status === "error" ? this.setState({message:res.message,open:true}) :
        console.log(res)
    })
  }
     onCancelClick = (value)=>{
       const {DataSuggestions}=this.state
          this.setState({profileDataSelect:DataSuggestions.filter(profile=>
          value === profile
           )})
         }
         onClickCancelInterest=(username)=>{
          const action ="unfollow";
          this.setState({[username]:false})
          const userData =JSON.parse(localStorage.getItem('login'));
          const {token} = userData.details;
          userService
          .interest(username,token,action)
          .then((res)=>{
              res.status === "error" ? this.setState({message:res.message,open:true}) :
              console.log(res)
          })
         }
    
    render() { 
      console.log(this.state)
      const { classes } = this.props;
      const {DataSuggestions,alert,DataRecentJoined}=this.state;
        return (
            <div>
<DiscoverNavs classes={classes} props={this.props} onCancelClick={this.onCancelClick} DataRecentJoined={DataRecentJoined} props={this.props} state={this.state} profiles={DataSuggestions} DataSuggestions={DataSuggestions} onClickCancelInterest={this.onClickCancelInterest} onClickInterest={this.onClickInterest}/>
       
    </div>
        )
      }
    }
    export default withStyles(styles, { withTheme: true })(RegisterUserName);
