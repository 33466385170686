import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CheckGreen from '../assets/images/checkGreen.png'
import {Link} from 'react-router-dom';
// import Box from '@material-ui/core/Box';
export default class success extends Component {
    render() {
        return (
            <div>
                <Container fixed>
                    <Grid container className="success-center">
                        <Grid className="col-center success-m-t" item xs={9}>
                                <Box textAlign="center">
                                <Fab
                className="fab-width"
                color="secondary"
                // size="small"
                aria-label="add"
                size="large"
              >
                <img src={CheckGreen}  width="30%"/>
              </Fab>
              <Box color="#5CB85C" fontSize={24} className="SailecMedium">Payment Successful</Box>
              </Box>
                      
                        </Grid>
                        <Link to="/discover" className="achorWidth">
                            <Button fullWidth variant="contained" size="large" color="secondary">continue</Button></Link>  
                    </Grid>
                    
                </Container>
            </div>
        )
    }
}
