import React, { Component } from 'react';
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ExploreIcon from '@material-ui/icons/Explore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import SalarySelect from './SalarySelect';
import EducationSelect from './EducationSelect';
import ProfessionSelect from './ProfessionSelect';
import TextField from '@material-ui/core/TextField';
const styles = (theme) => ({
    input: {
        display: 'none'
    },
    TextField:{   
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius:"0px 6px 6px 0px!important",
            borderColor: '#F1F3F8',
          },
          '&:hover fieldset': {
            borderColor: '#F1F3F8',
            borderWidth:1,
          },
          '&.Mui-focused fieldset': {
            borderColor: '#F1F3F8',
            borderWidth:1,
          },
        },
    },
    TextFieldSelect:{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius:"0px 6px 6px 0px!important",
          borderColor: '#F1F3F8',
        },
        '&:hover fieldset': {
          borderColor: '#F1F3F8',
          borderWidth:1,
        },
        '&.Mui-focused fieldset': {
          borderColor: '#F1F3F8',
          borderWidth:1,
        },
      },
    }
   
});
const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'INR',
      label: '₹',
    },
    {
      value: 'NRS',
      label: 'रू',
    },
  ];
class PhysicalAppearance extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    constructor(props) {
    super(props);
    this.state = {
     salary:"",
     currency:"",
       index:2,
    };
}
  onClickNext = () =>{
    
    const {salary,currency} = this.state;
    const page = 11;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
    localStorage.setItem("registerQuestion", JSON.stringify({state:{
      ...sharedData.state,
      salary:  salary,
      currency:currency,
      page
    }  
    })) 
    this.props.history.push(
       "register-question11"
     )
  }
   onClickCancel = () => {

    const page = 11;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
    localStorage.setItem("registerQuestion", JSON.stringify({state:{
      ...sharedData.state,
      salary:  10000,
      currency:"$",
      page
    }  
    })) 
    this.props.history.push(
       "register-question11"
     )
  }
  handleChangeCurrency = (event) => {
    this.setState({currency:event.target.value});
  };
  onChangeSalary = (e)=>{
    this.setState({salary:e.target.value});
  }

    render() {
        const { classes } = this.props;
     
        return (
          

              
            <div>
                    <Grid container style={style.profileTop} className="profilenav">
                        
                    <Link to="/my-profile"><Grid item style={style.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading} >Profession Info</Box> 
                        </Grid>
                  
                    </Grid>
                    <Container fixed>
                    <Box fontSize={14} mb={1} mt={3} className={classes.fontSailecRegular} color="#8D92A3" textAlign="left">
                    How much your salary per year ?
        </Box>
                    <Grid container>
                <Grid xs={3}>
                <TextField
          id="standard-select-currency-native"
          select
          // label="Currency"
          fullWidth
          variant="outlined"
          value={this.state.currency}
          onChange={this.handleChangeCurrency}
          className={classes.TextFieldSelect} 
          SelectProps={{
            native: true,
          }}
          // helperText="Please select your currency"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.label}>
              {option.label}
            </option>
          ))}
        </TextField>
                </Grid>
                <Grid xs={9}>
                  <TextField className={classes.TextField} onChange={this.onChangeSalary} variant="outlined" fullWidth placeholder="Enter amount" type="number"/>
                </Grid>
              </Grid>
              </Container>
                    <EducationSelect />
                    <ProfessionSelect />
                    <Container fixed><Link  to='./my-profile'>
                <Button
               
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={style.textcapitalize}
                >
                  Done
                </Button>
              </Link>
              </Container>
                  
                
   
     </div>

        )
    }
}
export default withStyles(styles, { withTheme: true })(PhysicalAppearance)
const style = {
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize'
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
       textcapitalize: {
        textTransform: "capitalize",
        marginTop: 120,
        fontSize: 16,
        padding: "15px 0px",
      },
     
   
}