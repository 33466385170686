import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Username from '../assets/images/username.png';
import Email from '../assets/images/email.png';
import Lock from '../assets/images/password.png';
import Notification from '../assets/images/notification.png';
import { withRouter } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
class RegisterUserName extends Component {
    constructor(props) {
        super(props);
        this.state = {
          anchorEl:null,
     
         
       
      
      }
      };
 
      
      handleClick = event => {
        this.setState({anchorEl:event.currentTarget});
      };
    
       handleClose = () => {
        this.setState({anchorEl:null});
      };
      onClickProfile=(images,e)=>{
        this.props.history.push({pathname:"/profile", state:{
          profileDetails:images,
          }
          });
      }
    render() {
     const {anchorEl,allItems,profileShow} =this.state;
         
    
        return (
            <div>
               <Grid container style={style.profileTop} className="profilenav">
                        
                        <Link to="/my-profile"><Grid item style={style.backButton} xs={2}>
                           <IconButton aria-label="delete">
                            <ArrowBackIcon /> 
    </IconButton>
     </Grid></Link>
                            <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading}>Account Settings</Box>  
                            </Grid>
                      
                        </Grid>
             <Box mt={1} />
           
            <Container fixed>
                <Box mt={2} />
<Link to="/change-username"><Grid container>
                    <Grid item xs={1}>
                    <img src={Username} width="24px"/>
                    </Grid>
                    <Grid item xs={7}>
                   
                    <Box fontSize={14} className="fontSailecMedium" color="#22242A" style={style.usernameText}> Username </Box>
                    </Grid>
                    <Grid item xs={4} style={style.Righticon}>
                    <ArrowForwardIosIcon style={style.iconForward} />
                        </Grid>
                </Grid></Link>
                <Box mt={2} />
<Link to="/email-settings"><Grid container>
                    <Grid item xs={1}>
                    <img src={Email} style={style.mailIcon} width="24px"/>
                    </Grid>
                    <Grid item xs={7}>
                   
                    <Box fontSize={14} className="fontSailecMedium" color="#22242A" style={style.usernameText}>Email </Box>
                    </Grid>
                    <Grid item xs={4} style={style.Righticon}>
                    <ArrowForwardIosIcon style={style.iconForward} />
                        </Grid>
                </Grid></Link>
                <Box mt={2} />
<Link to="/password"><Grid container>
                    <Grid item xs={1}>
                    <img src={Lock} width="24px"/>
                    </Grid>
                    <Grid item xs={7}>
                   
                    <Box fontSize={14} className="fontSailecMedium" color="#22242A" style={style.usernameText}>Password </Box>
                    </Grid>
                    <Grid item xs={4} style={style.Righticon}>
                    <ArrowForwardIosIcon style={style.iconForward} />
                        </Grid>
                </Grid></Link>
                <Box mt={2} />
<Link to="/"><Grid container>
                    <Grid item xs={1}>
                    <img src={Notification} width="24px"/>
                    </Grid>
                    <Grid item xs={7}>
                   
                    <Box fontSize={14} className="fontSailecMedium" color="#22242A" style={style.usernameText}>Notification Settings </Box>
                    </Grid>
                    <Grid item xs={4} style={style.Righticon}>
                    <ArrowForwardIosIcon style={style.iconForward} />
                        </Grid>
                </Grid></Link>
                
                
                
            </Container>

            
  
</div>
           
        )
}
    }
    export default withRouter(RegisterUserName)
const style={
    root: {
      borderRadius:39,
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
      },
   
      image:{
        maxWidth:"100%",
        borderRadius:5,
      },
      button:{
        padding:4,
      },
      backArrow:{
        marginTop:10,
      },
      cancelButton:{
        textTransform: "capitalize",
        marginTop:4,
        fontSize:14,
        fontFamily:"SailecRegular!important",
      } ,
      filterImage:{
        width:"85%",
        borderRadius:"50%",
        marginTop:10,
      },
      boxDisplay:{
        display:"inline-block",
        marginRight:4,
        padding:"2px 4px",
        borderRadius:2,
        fontFamily:"SailecRegular!important",
        fontSize:12,
        textTransform:'capitalize',
      },
      locationIcon:{
        marginLeft:-5,
        fontSize:17,
      },
      imgIcon:{
        width: 8,
        marginRight:3,
        marginTop: -3,
      },
      buttonSecondary:{
        fontSize:12,
        textTransform:'capitalize',
        marginTop:7,
      },
      heartIcon:{
        fontSize:17,
        marginRight:3,
      },
      profileDetails:{
        paddingLeft:10,
      },
      profileImg:{
        textAlign:'center',
      },
      profileHeading:{
        paddingTop:10,
        textTransform:'capitalize',
        
    },
    Righticon:{
        textAlign:"right",
    },
    iconForward:{
        fontSize:14,
        color:"#8D92A3",
        marginTop:2,
    },
    usernameText:{
        paddingTop:5,
        paddingLeft:3,
    },
    mailIcon:{
        marginTop:3,
    }
}


