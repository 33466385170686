import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FemaleIcon from '../assets/images/Rectangle -8.png';
import { withRouter } from "react-router-dom";
import Card1 from '../assets/images/Rectangle -8.png';
import { userService } from '../_services/user.service';
import Avatar from '@material-ui/core/Avatar';
const   profiles = [
  {
      img: Card1,
      id:1,
      title: 'Alina',
      author: 'author',
      BodyLook:"",
      Complexion:"",
      Language:"",
      Religion:"",
      Caste:"",
      Diet:"",
      Smoke:"",
      Drinks:"",
      Education:"",
      SalaryPerYear:"",
      Profession:"",
      cols: 4,
      name:'Puspa Chhetri',
      gender:'Female',
      age:22,
      height:173,
      location:'Kathmandu, Nepal',
      status:'female',
      religion:'islam',
      description:'Adipisci quas repellat sed.'
    },
    {
     img: Card1,
     id:2,
     title: 'Alisha',
     author: 'author',
     BodyLook:"",
     Complexion:"",
     Language:"",
     Religion:"",
     Caste:"",
     Diet:"",
     Smoke:"",
     Drinks:"",
     Education:"",
     SalaryPerYear:"",
     Profession:"",
     cols: 4,
     name:'Puspa Chhetri',
     gender:'Female',
     age:25,
     height:163,
     location:'Kathmandu, Nepal',
     status:'male',
     religion:'islam',
     description:'Adipisci quas repellat sed.'
   },
   {
      img: Card1,
      title: 'Heena',
      author: 'author',
      BodyLook:"",
      Complexion:"",
      Language:"",
      Religion:"",
      Caste:"",
      Diet:"",
      Smoke:"",
      Drinks:"",
      Education:"",
      SalaryPerYear:"",
      Profession:"",
      id:3,
      cols: 4,
      name:'Puspa Chhetri',
      gender:'Female',
      age:32,
      height:150,
      location:'Kathmandu, Nepal',
      status:'female',
      religion:'islam',
      description:'Adipisci quas repellat sed.'
    },
    {
     img: Card1,
     title: 'Poudel nishita',
     author: 'author',
     BodyLook:"",
     Complexion:"",
     Language:"",
     Religion:"",
     Caste:"",
     Diet:"",
     Smoke:"",
     Drinks:"",
     Education:"",
     SalaryPerYear:"",
     Profession:"",
     id:4,
     cols: 4,
     name:'Puspa Chhetri',
     gender:'male',
     age:35,
     height:200,
     location:'Kathmandu, Nepal',
     status:'female',
     religion:'islam',
     description:'Adipisci quas repellat sed.'
   },
   {
      img: Card1,
      title: 'Shirisha',
      author: 'author',
      BodyLook:"",
      Complexion:"",
      Language:"",
      Religion:"",
      Caste:"",
      Diet:"",
      Smoke:"",
      Drinks:"",
      Education:"",
      SalaryPerYear:"",
      Profession:"",
      id:5,
      cols: 4,
      name:'Puspa Chhetri',
      gender:'Female',
      age:29,
      height:197,
      location:'Kathmandu, Nepal',
      status:'male',
      religion:'islam',
      description:'Adipisci quas repellat sed.'
    },
    {
     img: Card1,
     title: 'Shubhu',
     author: 'author',
     BodyLook:"",
     Complexion:"",
     Language:"",
     Religion:"",
     Caste:"",
     Diet:"",
     Smoke:"",
     Drinks:"",
     Education:"",
     SalaryPerYear:"",
     Profession:"",
     cols: 4,
     name:'Puspa Chhetri',
     id:6,
     gender:'Female',
     age:25,
     height:166,
     location:'Kathmandu, Nepal',
     status:'female',
     religion:'islam',
     description:'Adipisci quas repellat sed.'
   },
   {
      img: Card1,
      title: 'Soneeya',
      author: 'author',
      BodyLook:"",
      Complexion:"",
      Language:"",
      Religion:"",
      Caste:"",
      Diet:"",
      Smoke:"",
      Drinks:"",
      Education:"",
      SalaryPerYear:"",
      Profession:"",
      cols: 4,
      name:'Puspa Chhetri',
      id:7,
      gender:'Female',
      age:28,
      height:177,
      location:'Kathmandu, Nepal',
      status:'female',
      religion:'islam',
      description:'Adipisci quas repellat sed.'
    },
    {
     img: Card1,
     title: 'Baijanthi',
     author: 'author',
     BodyLook:"",
     Complexion:"",
     Language:"",
     Religion:"",
     Caste:"",
     Diet:"",
     Smoke:"",
     Drinks:"",
     Education:"",
     SalaryPerYear:"",
     Profession:"",
     cols: 4,
     name:'Puspa Chhetri',
     gender:'Female',
     age:25,
     height:171,
     location:'Kathmandu, Nepal',
     status:'female',
     religion:'islam',
     id:8,
     description:'Adipisci quas repellat sed.'
   },
   {
    img: Card1,
    title: 'Balapuspika',
    author: 'author',
    BodyLook:"",
    Complexion:"",
    Language:"",
    Religion:"",
    Caste:"",
    Diet:"",
    Smoke:"",
    Drinks:"",
    Education:"",
    SalaryPerYear:"",
    Profession:"",
    cols: 4,
    name:'Puspa Chhetri',
    gender:'Female',
    age:25,
    height:159,
    location:'Kathmandu, Nepal',
    status:'female',
    religion:'islam',
    id:9,
    description:'Adipisci quas repellat sed.'
  },
  {
    img: Card1,
    title: 'Bhavaroopa',
    author: 'author',
    BodyLook:"",
    Complexion:"",
    Language:"",
    Religion:"",
    Caste:"",
    Diet:"",
    Smoke:"",
    Drinks:"",
    Education:"",
    SalaryPerYear:"",
    Profession:"",
    cols: 4,
    name:'Puspa Chhetri',
    id:10,
    gender:'Female',
    age:25,
    height:144,
    location:'Kathmandu, Nepal',
    status:'female',
    religion:'islam',
    description:'Adipisci quas repellat sed.'
  },
  {
   img: Card1,
   title: 'Bhavisana',
   author: 'author',
   BodyLook:"",
   Complexion:"",
   Language:"",
   Religion:"",
   Caste:"",
   Diet:"",
   Smoke:"",
   Drinks:"",
   Education:"",
   SalaryPerYear:"",
   Profession:"",
   cols: 4,
   name:'Puspa Chhetri',
   gender:'Female',
   age:25,
   height:153,
   location:'Kathmandu, Nepal',
   status:'female',
   religion:'islam',
   id:11,
   description:'Adipisci quas repellat sed.'
 },
 {
  img: Card1,
  title: 'Binsa',
  author: 'author',
  BodyLook:"",
  Complexion:"",
  Language:"",
  Religion:"",
  Caste:"",
  Diet:"",
  Smoke:"",
  Drinks:"",
  Education:"",
  SalaryPerYear:"",
  Profession:"",
  cols: 4,
  name:'Puspa Chhetri',
  gender:'Female',
  age:25,
  height:173,
  location:'Kathmandu, Nepal',
  status:'female',
  religion:'islam',
  id:12,
  description:'Adipisci quas repellat sed.'
}
  ];

class RegisterUserName extends Component {
    constructor(props) {
        super(props);
        this.state = {
          anchorEl:null,
          filterSearch:[],
          allItems:{
            display:'',
          },
          profiles:'',
          profileShow:{
          display:'',
          loading:false,
        } 
      }
      };
      onChange=( profiles,event)=>{
          const value = event.target.value.toLowerCase(); 
          const userData =JSON.parse(localStorage.getItem('login'));
          const {token} = userData.details;
          if(profiles.length !== 0){
          userService
          .SearchProfile(value,token)
        .then(res=> {
         if(res.status === 'success'){
          this.setState({filterSearch:res.users,allItems:{display:'none'},profileShow:{display:'',}})
         }
         else{
          this.setState({filterSearch:[],allItems:{display:'none'},profileShow:{display:'',}})
         }
        })
      }
      //  const  libraries = profiles.filter(l => {
      //     return l.title.toLowerCase().match(value);
      //   });
      //   this.setState({filterSearch:libraries,allItems:{display:'none'},profileShow:{display:'',}})
        // if(value.length === 0){
        //   this.setState({filterSearch:null})
        // }
        
      }
      
      handleClick = event => {
        this.setState({anchorEl:event.currentTarget});
      };
    
       handleClose = () => {
        this.setState({anchorEl:null});
      };
      onClickProfile=(value)=>{
        this.props.history.push({
          pathname:"/profile",
          search: value.username
        })
      }
      onClickBack=()=>{
        const {allItems} = this.state;
        if(allItems.display === 'none'){
        this.setState({allItems:{display:'',},profileShow:{display:'none',}})
      }
      else(
        this.props.history.push("/discover")
      )
      }
      componentDidMount=()=>{
        if(localStorage.getItem('login') === null){
          this.props.history.push("/")
        }
      }
    render() {
     const {anchorEl,allItems,profileShow} =this.state;
        return (
    
         
            <div>
            <Container fixed>
                <Box mt={1} />
           <Grid container>
             <Grid item xs={2}>
            <ArrowBackIcon onClick={this.onClickBack} style={style.backArrow}/>
             </Grid>
             <Grid item xs={8}>
             <Paper component="form" style={style.root}>
  <IconButton style={style.iconButton} aria-label="menu">
  <SearchIcon />
  </IconButton>
  <InputBase
    style={style.input}
    placeholder="Search"
    onChange={this.onChange.bind(this,profiles)}
  />
</Paper>
               </Grid>
               <Grid item xs={2}>
              <Link to="./discover"> <Button color="secondary" style={style.cancelButton}>
    Cancel
  </Button></Link>
               </Grid>
               
             </Grid> 
             </Container>
             <Box mt={1} />
             <Divider />
             <Container fixed>
<Box mt={2}></Box>
<Grid container style={allItems}>
    {profiles.map(images=>(
      
<Grid key={images.id} item xs={images.cols}>
 <Button style={style.button} onClick={this.onClickProfile.bind(this,images)}><img src={images.img} style={style.image} alt={images.title} />
 </Button>
 
</Grid>
    ))}
   
</Grid>


<Grid style={profileShow} container>
    {this.state.filterSearch.slice(0,50).map(profile=>(
<React.Fragment key={profile.id}>
<Grid onClick={this.onClickProfile.bind(this,profile)}  key={profile.id} item xs={3}>
  {profile.profile_pic === null ? <Avatar style={style.profileNoImg} alt="Remy Sharp">{profile.no_profile_pic}</Avatar>:
  <Avatar style={style.profileImg} alt="Remy Sharp"><img src={profile.profile_pic} width="100%" /></Avatar>
  }
    
</Grid>
<Grid style={style.profileDetails} item xs={9}>
  <Box onClick={this.onClickProfile.bind(this,profile)}>
<Box className="fontSailecMedium" fontSize={14}>{profile.name}</Box>
<Box color="#8D92A3" fontSize={12} className="fontSailecRegular"><LocationOnOutlinedIcon style={style.locationIcon}/> {profile.location}</Box>
<Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}><img src={FemaleIcon} style={style.imgIcon}/>{`${profile.age} Yrs`}</Box>
            <Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}>{profile.status}</Box>
            <Box className="fontSailecRegular" bgcolor="#F1F3F8" color="#8D92A3" style={style.boxDisplay}>{profile.religion}</Box>
            </Box>
<Button variant="contained" color="secondary" style={style.buttonSecondary}>
    <FavoriteIcon style={style.heartIcon}/>Show Interest
  </Button>
  
</Grid>
<Grid item xs={12}>
<Box mt={3} />
 <Divider />
 <Box mt={2} />
</Grid>
</React.Fragment>


    ),()=>{})}
     </Grid>


            </Container>
            
  
</div>
          
           
        )
}
    }
    export default withRouter(RegisterUserName)
const style={
    root: {
      borderRadius:39,
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
      },
      input: {
        marginLeft: 1,
        flex: 1,
      },
      iconButton: {
        padding: 10,
        marginLeft: -4,
      },
      image:{
        maxWidth:"100%",
        borderRadius:5,
      },
      button:{
        padding:4,
      },
      backArrow:{
        marginTop:10,
      },
      cancelButton:{
        textTransform: "capitalize",
        marginTop:4,
        fontSize:14,
        fontFamily:"SailecRegular!important",
      } ,
      filterImage:{
        width:"85%",
        borderRadius:"50%",
        marginTop:10,
      },
      boxDisplay:{
        display:"inline-block",
        marginRight:4,
        padding:"2px 4px",
        borderRadius:2,
        fontFamily:"SailecRegular!important",
        fontSize:12,
        textTransform:'capitalize',
      },
      locationIcon:{
        marginLeft:-5,
        fontSize:17,
      },
      imgIcon:{
        width: 8,
        marginRight:3,
        marginTop: -3,
      },
      buttonSecondary:{
        fontSize:12,
        textTransform:'capitalize',
        marginTop:7,
      },
      heartIcon:{
        fontSize:17,
        marginRight:3,
      },
      profileDetails:{
        paddingLeft:10,
      },
      profileImg:{
        marginLeft:"auto",
        marginRight:"auto",
        width: 60,
        height: 60,
      },
      profileNoImg:{
        marginLeft:"auto",
        marginRight:"auto",
        background:"#5CB85C",
        fontSize:20,
        fontFamily: 'SailecBold',
        width: 60,
        height: 60,
      },
}


