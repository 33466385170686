import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Container,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../assets/images/Logo.png";
import heroBg from "../assets/images/background_image-1.png";
import { userService } from "../_services/user.service";
import { validate, validateProperty } from "../_common/validate";
import Joi from "joi";
import LinearProgress from '@material-ui/core/LinearProgress';
import "../../App.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  bg: {
    backgroundImage: `url(${heroBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  textField: {
    "& .MuiInput-underline:after": {
      border: "1px solid #faf9f7 !important"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #faf9f7 !important"
      },
      "&:hover fieldset": {
        border: "1px solid #faf9f7 !important"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #faf9f7 !important"
      }
    }
  },
  loginButton: {
    marginTop: 20,
    padding: "15px 0px",
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    width: "100%",
    textTransform: "none"
  },
  colorPink: {
    color: "#FA396F"
  },
  borderTop: {
    borderTop: "1px solid #8D92A3"
  },
  loader:{
    position:"fixed",
    top:0,
    width:"100%",
    left:0,
  }
}));

// function for rendenering Header section of Login Page
const LoginHeader = () => {
  return (
    <Box textAlign="center">
      <img src={Logo} width="120px" alt="Nepalivivah" />
      <Box fontSize={24} className="fontSailecBold" mt={2} color="#fff">
        Login to your account
      </Box>
      <Box
        textAlign="center"
        className="fontShareenath"
        pt={1}
        fontSize={18}
        mx={2}
        color="#ffffff"
      >
        {`g]kflnljjfxdf klxn] /lh:6/ ug{' ePsf] 5 eg]`}
      </Box>
      <Box
        textAlign="center"
        className="fontShareenath"
        fontSize={18}
        mx={2}
        color="#ffffff"
      >
        {`oxfF nf]lug ug{'xf];`}
      </Box>
    </Box>
  );
};

// schema for validating login form
const schema = {
  email: Joi.string()
    .email()
    .label("Email"),
  password: Joi.string()
    .required()
    .label("Password")
};

// function is for rendenering Form section of Login Page
function LoginForm(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    form: {
      email: "",
      password: ""
    },
    showPassword: false,
    formError: "",
    errors: {},
    loading:false,
  });
 
  // method for handling form field change
  const handleChange = prop => event => {
    const errors = { ...values.errors };
    const error = validateProperty(prop, event.target.value, schema);
    if (error) errors[prop] = error;
    else delete errors[prop];

    setValues({
      ...values,
      form: { ...values.form, [prop]: event.target.value },
      errors
    });
  };

  // method for toggle show password feature
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  // method for handling submit functionality
  const onSubmit = e => {
   
    e.preventDefault();
    
   
    // validate form and return if there are errors
    const errors = validate(values, schema);
    setValues({ ...values, errors: errors, formError: "" });
    if (Object.keys(errors).length) return;

    // API call for verifying login credentials
    setValues({ ...values, loading:true });
    userService
      .login(values.form.email, values.form.password)
      .then(response => {
        setValues({ ...values, loading:false });
        if (response.status === "error")
         { setValues({ ...values, formError: response.message });}
      else {
        console.log(response.user)
      localStorage.setItem('login',
      JSON.stringify({
        login:true,
        details:response.user
      }));
             props.props.history.push({pathname: '/discover'})
   
    }
      });
  };
const { loading } = values;
  // JSX for login form
  return (
    <Box textAlign="center">
      {loading === true ? <LinearProgress color="secondary" className={classes.loader}/>: <Box/>}
      <Box color="red" fontSize={14} className="fontSailecRegular" my={2}>
        {values.formError}
      </Box>
      <Box
        fontSize={14}
        mb={1}
        className="fontSailecRegular"
        color="#fff"
        textAlign="left"
      >
        Email Address
      </Box>
      <TextField
        className={classes.textField}
        type="email"
        name="email"
        helperText={
          <Box color="red" className="fontSailecRegular">
            {values.errors.email}
          </Box>
        }
        onChange={handleChange("email")}
        fullWidth
        placeholder="Enter your email"
        variant="outlined"
        value={values.form.email}
      />
      <Box
        fontSize={14}
        className="fontSailecRegular"
        color="#fff"
        mt={2}
        mb={1}
        textAlign="left"
      >
        Password
      </Box>
      <TextField
        id="outlined-adornment-password"
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
        fullWidth
        helperText={
          <Box color="red" className="fontSailecRegular">
            {values.errors.password}
          </Box>
        }
        type={values.showPassword ? "text" : "password"}
        value={values.form.password}
        placeholder="Enter your password"
        onChange={handleChange("password")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        className={classes.loginButton}
        onClick={onSubmit}
      >
        Login
      </Button>
      <Box
        mt={3}
        fontSize={14}
        className="fontSailecLight"
        fontWeight={500}
        color="#fff"
      >
        पासवर्ड बिर्सनु भयो? यहाँ क्लिक गर्नु होस
      </Box>
      <Box fontSize={14} color="#fff">
        Forgot password?{" "}
        <Link
          to="./reset-password"
          className={clsx(classes.colorPink, "fontSailecLight")}
        >
          Click here
        </Link>
      </Box>
    </Box>
  );
}

// function is for rendenering Footer section of Login Page
const LoginFooter = () => {
  const classes = useStyles();
  return (
    <Box className={classes.borderTop} mt={2}>
      <Box
        mt={3}
        textAlign="center"
        fontSize={14}
        fontWeight={500}
        color="#fff"
        className="fontSailecLight"
      >
        नेपालिविवाहमा पहिले रजिस्टर गर्नु भएको छैन भने
      </Box>
      <Box textAlign="center" className="fontSailecLight" fontSize={14}>
        <Link to="/" className={classes.colorPink}>
          यहाँ क्लिक गर्नुहोस
        </Link>
      </Box>
      <Box
        textAlign="center"
        className="fontSailecLight"
        fontSize={16}
        mt={1}
        color="#fff"
      >
        New to Nepalivivah?{" "}
        <Link to="/register" className={classes.colorPink}>
          Click here
        </Link>
      </Box>
    </Box>
  );
};

// functional component for Login Page
export default function Login(props) {
  if(localStorage.getItem('login') !== null){
    props.history.push("/discover")
  }
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box py={5} className={classes.bg}>
        <Container fixed>
          <LoginHeader />
          <LoginForm props={props}/>
        </Container>
        <LoginFooter />
      </Box>
    </div>
  );
}
