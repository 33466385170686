import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DatePickerModal from "../_common/datePickerModal";
import Modal from "react-bootstrap/Modal";
import AutocompletePlaces from '../_common/AutocompletePlaces';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 48
  },
  iconButton: {
    padding: 10
  },
  buttonWidth: {
    marginTop: 30,
    width: "100%",
    padding: "15px 0px",
    textTransform: "none",
    boxShadow: "none",
    fontWeight: 500,
    fontSize: 16,
    "&:focus ,&:active ": {
      border: "none",
      outlineColor: "#5CB85C"
    }
  },
  textField: {
    backgroundColor: "#faf9f7",
    "& .MuiInput-underline:after": {
      border: "1px solid #f0f0f0"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #f0f0f0"
      },
      "&:hover fieldset": {
        border: "1px solid #f0f0f0 "
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #f0f0f0 "
      }
    }
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
}));

function ButtonAppBar(props) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [value, setValue] = React.useState({
    first_name: "",
    last_name: "",
    birthday: "",
    birth_place: ""
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  
  const handleChange = prop => e => {
    setValue({ ...value, [prop]: e.target.value });
  };

  const handleChangeDate = e => {
    const dateValue = e.target.value;
    setValue({ ...value, [value.birthday]: dateValue });
  };

  const changeLocation = valueSearch => {
    setValue({ ...value, birth_place: valueSearch });
  };
  
  const { first_name, last_name, birthday, birth_place } = value;
  const dateSelect = Childdata => {
    setValue({ ...value, birthday: Childdata });
  };
 
  const sharedData =JSON.parse(localStorage.getItem("register"));
  const page = 2; 

  const onSubmit = () => {
    localStorage.setItem("register", JSON.stringify({
      state: {
        ...sharedData.state,
        ...value,
        page
      }  
    }))
    
    props.history.push("/take-a-picture")
  }

  if(localStorage.getItem('register') !== null) {
    if(sharedData.state.page < 1) {
      props.history.push("/")
    }
  } else {
    props.history.push("/")
  }


  return (
    <div>
      <Container fixed>
        <Box
          className={classes.fontSailecBold}
          textAlign="center"
          fontSize={24}
          color="#000"
          mt={3}
        >
          Tell about yourself
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          fontSize={14}
          color="#707070"
          mb={3}
        >
          कृपया आफ्नो बारेमा लेख्नुहोस
        </Box>
        <Box
          fontSize={14}
          mb={1}
          className={classes.fontSailecRegular}
          color="#8D92A3"
          textAlign="left"
        >
          What is your First Name?
        </Box>
        <TextField
          required
          onChange={handleChange("first_name")}
          id="standard-required"
          fullWidth
          placeholder="Enter First Name"
          className={classes.textField}
          variant="outlined"
        />
        <Box
          fontSize={14}
          mb={1}
          mt={2}
          className={classes.fontSailecRegular}
          color="#8D92A3"
          textAlign="left"
        >
          What is your Last Name?
        </Box>
        <TextField
          onChange={handleChange("last_name")}
          required
          id="standard-required1"
          fullWidth
          placeholder="Enter Last Name"
          className={classes.textField}
          variant="outlined"
        />
        <Box
          fontSize={14}
          mb={1}
          mt={2}
          className={classes.fontSailecRegular}
          color="#8D92A3"
          textAlign="left"
        >
          When were you born?
        </Box>
        <TextField
          onChange={handleChangeDate}
          className={classes.textField}
          onClick={handleShow}
          type="text"
          value={value.birthday}
          InputProps={{
            readOnly: true
          }}
          name="text"
          fullWidth
          placeholder="Select date"
          variant="outlined"
        />

        <Modal show={show} onHide={handleClose} className={classes.modal}>
          <Modal.Body className={classes.modaldis}>
            <DatePickerModal date={dateSelect} />
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              className={classes.buttonWidth}
            >
              Done
            </Button>
            <Button
              fullWidth
              className={classes.buttonWidth3}
              onClick={handleClose}
            >
              Cancle
            </Button>
          </Modal.Body>
        </Modal>

        <Box
          fontSize={14}
          mb={1}
          mt={2}
          className={classes.fontSailecRegular}
          color="#8D92A3"
          textAlign="left"
        >
          Where were you born?
        </Box>

       <AutocompletePlaces location={changeLocation}/>
        
        <Button
          onClick={onSubmit}
          disabled={
            (first_name && last_name && birthday && birth_place) === ""
            ? true
            : false
          }
          variant="contained"
          color="secondary"
          type="submit"
          className={clsx(classes.buttonWidth, classes.fontSailecRegular)}
        >
          Next
        </Button>

        <Box mb={3}></Box>
      </Container>
    </div>
  );
}
export default withRouter(ButtonAppBar);