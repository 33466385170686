import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import Card1 from "./assets/images/17814393_1383883718357660_1174577180424278390_o@2x.png";
import IconButton from "@material-ui/core/IconButton";

import TextField from '@material-ui/core/TextField';
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { ListSubheader } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {  makeStyles} from '@material-ui/core/styles';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// import Container from '@material-ui/core/Container';
const countries = [
    { title: 'Australia', code: "AUS" },
    { title: 'Nepal', code: "NEP" },
    { title: 'India', code: "IND" },
    { title: 'China', code: "CHN" },
    { title: 'USA', code: "USA" },
    { title: "Japan", code: "JPN" },
    { title: 'Canada', code: "CAN" },
    { title: 'UK', code: "UK" },
    { title: 'Hong Kong', code: "HK" },
    { title: 'Taiwan', code: "TAI" },
    { title: 'New Zealand', code: "NZ" },
    { title: 'Thailand', code: "THA" },
    { title: 'Pakistan', code: "PK" },
    { title: 'Bangladesh', code: "BD" },
    { title: "Don't care", code: "D" },
   
  ];
  const ProfessionList =[
    {title:"Accountant"},
    {title:"Army"},
    {title:"Banker"},
    {title:"Doctor"},
    {title:"Business Owner"},
    {title:"Engineer"},
    {title:"Entertainer"},
    {title:"Farmer"},
    {title:"Government Employee"},
    {title:"Lawyer"},
    {title:"Lecturer"},
    {title:"Nurse"},
    {title:"Marketer"},
    {title:"Office Assistant"},
    {title:"Teacher"},
    {title:"Politician"},
    {title:"Police"},
    {title:"Student"},
    {title:"Non Profit Worker"},
    {title:"Not Working"},
    {title:"Other"},
    {title: "Don't care" },
  ]
  const useStyles = makeStyles((theme) => ({
    Autocomplete:{
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              
            //   borderColor: '#F1F3F8',
            },
            '&:hover fieldset': {
            //   borderColor: '#F1F3F8',
              borderWidth:1,
            },
            '&.Mui-focused fieldset': {
            //   borderColor: '#F1F3F8',
              borderWidth:1,
            },
          },
    },
    updateBtn:{
        position: "relative",
    zIndex: -999
    }
  }));
  
export default function LinearDeterminate() {

  const [value, setValue] = React.useState({
    filterGender:"",
    filterAge:"",
    filterProfile:"",
    filterheight:"",
    gender:"Both",
    countries:"",
    ageIntial: [22, 27],
    heightInitial: [160, 210],
    average: true,
    athetic: false,
    slim: false,
    heavy: false,
    DontCareEducation:false,
    DontCareDiet:false,
    fair: true,
    medium: false,
    dark: false,
    Weatish: false,
    magar: true,
    bhojpuri: false,
    nepali: false,
    maithili: false,
    urdu: false,
    buddism: true,
    hinduism: true,
    vhristianity: false,
    islam: false,
    judaism: false,
    brahman: true,
    DontCareReligion:"",
    kayastha: false,
    kashatriya: false,
    sudra: false,
    vaishya: true,
    veg: true,
    nonVeg: false,
    smoker: false,
    DontCareCaste:"",
    nonSmoker: true,
    DontCareComplexion:"",
    DontCareLanguage:"",
    dontCareMangalik:false,
    YesMangalik:true,
    NoMangalik:false,
    dontCare: false,
    drink: false,
    nonDrink: true,
    belowHighSchool: true,
    highSchoolOrIntermediate: false,
    bachelors: false,
    doctorate: false,
    drinkdontCare: false,
    salary1: true,
    salary2: false,
    salary3: false,
    teacher: true,
    programmer: false,
    designer: false,
    politician: false,
    profession:"",
    policeOfficer: false,
    displayBodylook: { display: "none" },
    displayComplexion: { display: "none" },
    displayProfession: { display: "none" },
    displayLanguage: {
      display: "none"
    },
    displayReligion: {
      display: "none"
    },
    displayCaste: {
      display: "none"
    },
    displayDiet: {
      display: "none"
    },
    displaySmoke: {
      display: "none"
    },
    displayLocation: {
        display: "none"
      },
    displayDrinks: {
      display: "none"
    },
    displayMangalik: {
        display: "none"
      },
    displayEducation: {
      display: "none"
    },
    displaySalary: {
      display: "none"
    }
  });
 

  //   const error = [average,slim,athetic,heavy].filter(v => v).length !== 2;

  const {
    average,
    slim,
    athetic,
    profession,
    heavy,
    displayBodylook,
    fair,
    medium,
    dark,
    weatish,
    displayComplexion,
    magar,
    DontCareComplexion,
    displayLocation,
    nepali,
    maithili,
    urdu,
    bhojpuri,
    displayLanguage,
    buddism,
    hinduism,
    heightInitial,
    bodylook, 
    christianity,
    islam,
    judaism,
    displayReligion,
    DontCareReligion,
    DontCareLanguage,
    brahman,
    kayastha,
    DontCareCaste,
    kashatriya,
    sudra,
    vaishya,
    displayCaste,
    veg,
    DontCareDiet,
    nonVeg,
    ageIntial,
    displayDiet,
    smoker,
    nonSmoker,
    dontCare,
    dontCareMangalik,
    YesMangalik,
    NoMangalik,
    displaySmoke,
    displayMangalik,
    gender,
    drink,
    DontCareEducation,
    DontCareBodyLook,
    nonDrink,
    drinkdontCare,
    displayDrinks,
    belowHighSchool,
    highSchoolOrIntermediate,
    bachelors,
    doctorate,
    displayEducation,
    displaySalary,
    salary1,
    salary2,
    salary3,
    displayProfession,
    teacher,
    programmer,
    designer,
    policeOfficer,
    politician
  } = value;
  const value1 = heightInitial[0];
  const value2 = heightInitial[1];
  const inches1 = (value1 * 0.393701) / 12;
  const feets1 = inches1 - parseInt(inches1);
  const inchesShow1 = feets1 * 12;
  const inches2 = (value2 * 0.393701) / 12;
  const feets2 = inches2 - parseInt(inches2);
  const inchesShow2 = feets2 * 12;
  const classes = useStyles();
  const handleChange2 = (event, newValue2) => {
 
  const value1=newValue2[0]
  const value2=newValue2[1]
    // const  libraries = value.filterProfile.filter(l => {
    //   return l.height >= value1 && l.height <= value2
    // });
    setValue({...value,heightInitial: newValue2,  });
};
            //  const  libraries = value.filterGender.filter(l => {
            //   return l.age >= value1 && l.age <= value2
            // });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeDieting = (profiles,e,newValue,) => {
    
    const valueFilter = newValue.toLowerCase(); 
    const  libraries = profiles.filter(l => {
      return l.status.toLowerCase() === valueFilter;
    });
 if(valueFilter === "male" || valueFilter === "female"){
     
     setValue({...value, filterGender:libraries,filterProfile:libraries,filterAge:libraries,filterheight:libraries,gender:newValue})
  
  }
  else{
    setValue({...value, filterGender:libraries,filterProfile:libraries,filterAge:libraries,filterheight:libraries,gender:newValue})

  }
};
const handleChange = (event, newValue) => {
  const value1=newValue[0];
  const value2=newValue[1];
  
   
          //  const  libraries = value.filterGender.filter(l => {
          //   return l.age >= value1 && l.age <= value2
          // });
          setValue({...value, ageIntial: newValue});
     
    };
  const handleChangeDiscover = name => event => {
    setValue({ ...value, [name]: event.target.checked });
  };
  const doneBtn=(dispatch)=>{           
    const {filterGender,filterAge}= value;
    // const di = filterAge.filter(i=> 
    //   {return }
    //   )

    // dispatch({type:'FILTER_MYPROFILE', payload:filterAge})
    // props.history.push('/discover')
  
  }
  const onShow = prop => (event) => {
   setValue({...value, [prop]:{
     display:"",
   }})

  }
  const onHidden = prop => (event) => {
    setValue({...value, [prop]:{
      display:"none",
    }})
   }
   const onChangeCountry=(e,country)=>{
       setValue({...value,countries:country})
   }
   const onChangeProfession=(e,country)=>{
    setValue({...value,profession:country})
}
  return (
  
    <div>
      <Grid container>
        <Link to="/discover">
          <Grid item style={style.backButton} xs={2}>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        </Link>
        <Grid item xs={8}>
          <Box
            textAlign="center"
            fontSize={18}
            className="fontSailecBold"
            style={style.profileHeading}
          >
            Discover Settings
          </Box>
        </Grid>
        <Grid item style={style.menuButton} xs={2}>
          <IconButton aria-label="delete" onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box pt={1} bgcolor="#F1F3F8" />
      {/* <Container fixed>
        <Box
          mt={3}
          mb={1}
          fontSize={14}
          className="fontSailecRegular"
          color="#8D92A3"
        >
          Gender
        </Box>
        
        <BottomNavigation className="partner-btn" value={gender} >
          <BottomNavigationAction label="Male" value="Male" />
          <BottomNavigationAction label="Female" value="Female" />
          <BottomNavigationAction label="Both" value="Both" />
        </BottomNavigation>
      </Container> */}
      <Container fixed>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={style.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div style={style.paper}>
            {/* onClick={doneBtn.bind(this,dispatch)} */}
              <Button  style={style.buttonModal} className="fontSailecMedium">
                Done
              </Button>
              <Link to="discover"> <Button style={style.buttonModal} className="fontSailecMedium">
                cancel
              </Button></Link>
            
            </div>
          </Fade>
        </Modal>
        <Grid container>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Age
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={14}
              className="fontSailecMedium"
              textAlign="right"
              color="#22242A"
            >
              {`${ageIntial[0]}-${ageIntial[1]} Yrs`}
            </Box>
          </Grid>
        </Grid>
        <Slider
          className="sliderButtonStyle"
          value={ageIntial}
          onChange={handleChange}
          color="secondary"
          max={40}
          min={20}
        />
        <Grid container 
         onClick={
            onShow("displayLocation")
          }
          >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Location
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {value.countries.length === 0 ? "Select" : value.countries.map((value)=>(
                 `${value.title}, ` 
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={1} mt={1} bgcolor="#F1F3F8" />
      <Container fixed>
        <Grid container>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Height
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {parseInt(inches1) +
                "'" +
                parseInt(inchesShow1) +
                "'' - " +
                parseInt(inches2) +
                "'" +
                parseInt(inchesShow2) +
                "''"}
            </Box>
          </Grid>
        </Grid>
        <Slider
          value={heightInitial}
          className="sliderButtonStyle"
          onChange={handleChange2}
          min={150}
          step={1}
          color="secondary"
          max={210}
        />
        <Grid
          container
          onClick={
            onShow("displayBodylook")
          }
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Body Look
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {average === true ? `Average${(slim || athetic || heavy || DontCareBodyLook)=== true ? ',':'' }`: ""}
              {slim === true ? ` Slim${(athetic || heavy || DontCareBodyLook)=== true ? ',':'' }` : ""}
              {athetic === true ? ` Athetic${(heavy || DontCareBodyLook ) === true ? ',':'' }` : ""}
              {heavy === true ? ` Heavy${DontCareBodyLook === true ? ',' : ''}` : ""}
              {DontCareBodyLook === true ? ` Don't care` : ""}
              
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          onClick={onShow("displayComplexion")
          }
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Complexion
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {fair === true ? `Fair${(medium || dark || weatish || DontCareComplexion)=== true ? ',':'' }` : ""}
              {medium === true ? ` Medium${( dark || weatish || DontCareComplexion)=== true ? ',':'' }` : ""}
              {dark === true ? ` Dark${(weatish || DontCareComplexion)=== true ? ',':'' }` : ""}
              {weatish === true ? ` Weatish${DontCareComplexion === true ?  ',':''}` : ""}
              {DontCareComplexion === true ? ` Don't care` : ""}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={1} mt={1} bgcolor="#F1F3F8" />
      <Container fixed>
        {/* <Grid
          container
          onClick={onShow("displayLanguage")
          }
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Language
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {magar === true ? `Magar${(bhojpuri || nepali || maithili || urdu || DontCareLanguage)=== true ? ',':'' }` : ""}
              {bhojpuri === true ? ` Bhojpuri${( nepali || maithili || urdu || DontCareLanguage)=== true ? ',':'' }` : ""}
              {nepali === true ? ` Nepali${(maithili || urdu || DontCareLanguage)=== true ? ',':'' }` : ""}
              {maithili === true ? ` Maithili${(urdu || DontCareLanguage)=== true ? ',':'' }` : ""}
              {urdu === true ? ` Urdu${DontCareLanguage === true ? ',': ''}` : ""}
              {DontCareLanguage === true ? ` Don't care` : ""}
            </Box>
          </Grid>
        </Grid> */}
        <Grid
          container
          onClick={onShow("displayReligion")
          }
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Religion
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {buddism === true ? `Buddism${(christianity || hinduism || islam || judaism || DontCareReligion)=== true ? ',':'' }` : ""}
              {christianity === true ? ` Christianity${(hinduism || islam || judaism || DontCareReligion)=== true ? ',':'' }` : ""}
              {hinduism === true ? ` Hinduism${(islam || judaism) || DontCareReligion=== true ? ',':'' }` : ""}
              {islam === true ? ` Islam${(judaism || DontCareReligion)=== true ? ',':'' }` : ""}
              {judaism === true ? ` Judaism${DontCareReligion=== true ? ',':''}` : ""}
              {DontCareReligion === true ? ` Don't care` : ""}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          onClick={onShow("displayCaste")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Caste
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {brahman === true ? `Brahman${(kayastha || kashatriya || sudra || vaishya || DontCareCaste)=== true ? ',':'' }` : ""}
              {kayastha === true ? ` Kayastha${(kashatriya || sudra || vaishya || DontCareCaste)=== true ? ',':'' }` : ""}
              {kashatriya === true ? ` Kashatriya${(sudra || vaishya || DontCareCaste)=== true ? ',':'' }` : ""}
              {sudra === true ? ` Sudra${(vaishya || DontCareCaste)=== true ? ',':'' }` : ""}
              {vaishya === true ? ` Vaishya${DontCareCaste === true ? ',' :''}` : ""}
              {DontCareCaste === true ? ` Don't care` : ""}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          onClick={onShow("displayMangalik")}
        >
        <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Mangalik
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {NoMangalik === true ? `No${(YesMangalik || dontCareMangalik)=== true ? ',':'' }` : ""}
              {YesMangalik === true ? ` Yes${dontCareMangalik=== true ? ',':'' }` : ""}
              {dontCareMangalik === true ? " Don't care" : ""}
            </Box>
          </Grid>
        </Grid>
        
      </Container>
      <Box pt={1} mt={1} bgcolor="#F1F3F8" />
      <Container fixed>
        <Grid
          container
          onClick={onShow("displayDiet")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Diet
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {veg === true ? `Vegetarian${(nonVeg || DontCareDiet)=== true ? ',':'' }` : ""}
              {nonVeg === true ? ` Non Vegetarian${DontCareDiet === true ? ',' : ''}` : ""}
              {DontCareDiet === true ? " Don't care" : ""}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          onClick={onShow("displaySmoke")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Smoke
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {nonSmoker === true ? `No${(smoker || dontCare)=== true ? ',':'' }` : ""}
              {smoker === true ? ` Yes${dontCare=== true ? ',':'' }` : ""}
              {dontCare === true ? " Don't care" : ""}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          onClick={onShow("displayDrinks")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Drinks
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {nonDrink === true ? `No${(drink || drinkdontCare)=== true ? ',':'' }` : ""}
              {drink === true ? ` Yes${drinkdontCare=== true ? ',':'' }` : ""}
              {drinkdontCare === true ? " Don't care" : ""}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box pt={1} mt={1} bgcolor="#F1F3F8" />
      <Container fixed>
        <Grid
          container
          onClick={onShow("displayEducation")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Education
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {belowHighSchool === true ? `Below High School${(highSchoolOrIntermediate || DontCareEducation || bachelors || doctorate)=== true ? ',':'' }` : ""}
              {highSchoolOrIntermediate === true
                ? ` High School or Intermediate${(bachelors || doctorate || DontCareEducation)=== true ? ',':'' }`
                : ""}
              {bachelors === true ? ` Bachelors${(doctorate || DontCareEducation)=== true ? ',':'' }` : ""}
              {doctorate === true ? ` Doctorate${DontCareEducation}` : ""}
              {DontCareEducation === true ? " Don't care" : ""}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid
          container
          onClick={onShow("displaySalary")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Salary Per Year
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {salary1 === true ? `Less than $25,000${(salary2 || salary3)=== true ? ',':'' }` : ""}
              {salary2 === true ? ` $25001 to $350000${salary3=== true ? ',':'' }` : ""}
              {salary3 === true ? " $350001 to $500000 " : ""}
            </Box>
          </Grid>
        </Grid> */}
        <Grid
          container
          onClick={onShow("displayProfession")}
        >
          <Grid item xs={4}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={14}
              color="#8D92A3"
            >
              Profession
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecMedium"
              textAlign="right"
              fontSize={14}
              color="#22242A"
            >
              {profession.length === 0 ? "Select" : profession.map((value)=>(
                 `${value.title}, ` 
                
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
  
      <Box style={displayBodylook}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={average}
                    onChange={handleChangeDiscover("average")}
                    value="average"
                  />
                }
                label="Average"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={slim}
                    onChange={handleChangeDiscover("slim")}
                    value="slim"
                  />
                }
                label="Slim"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={athetic}
                    onChange={handleChangeDiscover("athetic")}
                    value="athetic"
                  />
                }
                label="Athetic"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={heavy}
                    onChange={handleChangeDiscover("heavy")}
                    value="heavy"
                  />
                }
                label="Heavy"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={heavy}
                    onChange={handleChangeDiscover("DontCareBodyLook")}
                    value="DontCareBodyLook"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
              onClick={onHidden("displayBodylook")
              }
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayComplexion}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fair}
                    onChange={handleChangeDiscover("fair")}
                    value="fair"
                  />
                }
                label="Fair"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medium}
                    onChange={handleChangeDiscover("medium")}
                    value="medium"
                  />
                }
                label="Medium"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dark}
                    onChange={handleChangeDiscover("dark")}
                    value="dark"
                  />
                }
                label="Dark"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={weatish}
                    onChange={handleChangeDiscover("weatish")}
                    value="weatish"
                  />
                }
                label="Weatish"
              />
               <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={weatish}
                    onChange={handleChangeDiscover("DontCareComplexion")}
                    value="DontCareComplexion"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayComplexion")
          }
              
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayLanguage}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={magar}
                    onChange={handleChangeDiscover("magar")}
                    value="magar"
                  />
                }
                label="Magar"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bhojpuri}
                    onChange={handleChangeDiscover("bhojpuri")}
                    value="bhojpuri"
                  />
                }
                label="Bhojpuri"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nepali}
                    onChange={handleChangeDiscover("nepali")}
                    value="nepali"
                  />
                }
                label="Nepali"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={maithili}
                    onChange={handleChangeDiscover("maithili")}
                    value="maithili"
                  />
                }
                label="Maithili"
              />

              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={urdu}
                    onChange={handleChangeDiscover("urdu")}
                    value="urdu"
                  />
                }
                label="urdu"
              />
               <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DontCareLanguage}
                    onChange={handleChangeDiscover("DontCareLanguage")}
                    value="DontCareLanguage"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayLanguage")}
              
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayReligion}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={buddism}
                    onChange={handleChangeDiscover("buddism")}
                    value="buddism"
                  />
                }
                label="Buddism"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={christianity}
                    onChange={handleChangeDiscover("christianity")}
                    value="christianity"
                  />
                }
                label="Christianity"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hinduism}
                    onChange={handleChangeDiscover("hinduism")}
                    value="hinduism"
                  />
                }
                label="Hinduism"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={islam}
                    onChange={handleChangeDiscover("islam")}
                    value="islam"
                  />
                }
                label="Islam"
              />

              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={judaism}
                    onChange={handleChangeDiscover("judaism")}
                    value="judaism"
                  />
                }
                label="Judaism"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DontCareReligion}
                    onChange={handleChangeDiscover("DontCareReligion")}
                    value="DontCareReligion"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayReligion")}
             
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayCaste}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={brahman}
                    onChange={handleChangeDiscover("brahman")}
                    value="brahman"
                  />
                }
                label="Brahman"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={kayastha}
                    onChange={handleChangeDiscover("kayastha")}
                    value="kayastha"
                  />
                }
                label="Kayastha"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={kashatriya}
                    onChange={handleChangeDiscover("kashatriya")}
                    value="kashatriya"
                  />
                }
                label="Kashatriya"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sudra}
                    onChange={handleChangeDiscover("sudra")}
                    value="sudra"
                  />
                }
                label="Sudra"
              />

              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vaishya}
                    onChange={handleChangeDiscover("vaishya")}
                    value="vaishya"
                  />
                }
                label="Vaishya"
              />
               <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DontCareCaste}
                    onChange={handleChangeDiscover("DontCareCaste")}
                    value="DontCareCaste"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayCaste")}
              
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayDiet}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={veg}
                    onChange={handleChangeDiscover("veg")}
                    value="veg"
                  />
                }
                label="Vegetarian"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nonVeg}
                    onChange={handleChangeDiscover("nonVeg")}
                    value="nonVeg"
                  />
                }
                label="Non Vegetarian"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DontCareDiet}
                    onChange={handleChangeDiscover("DontCareDiet")}
                    value="DontCareDiet"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
             onClick={onHidden("displayDiet")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displaySmoke}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nonSmoker}
                    onChange={handleChangeDiscover("no")}
                    value="no"
                  />
                }
                label="No"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={smoker}
                    onChange={handleChangeDiscover("smoker")}
                    value="smoker"
                  />
                }
                label="Yes"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontCare}
                    onChange={handleChangeDiscover("dontCare")}
                    value="dontCare"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
              onClick={onHidden("displaySmoke")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayLocation}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
          <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={countries}
      onChange={onChangeCountry}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            // style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </React.Fragment>
      )}
      style={{ width: "100%",marginBottom:270 }}
      renderInput={(params) => (
        <TextField className={classes.Autocomplete} {...params} variant="outlined" label="Select Country" color="secondary" placeholder="Type country name" />
      )}
    />
            <Button
              onClick={onHidden("displayLocation")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayMangalik}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={NoMangalik}
                    onChange={handleChangeDiscover("NoMangalik")}
                    value="NoMangalik"
                  />
                }
                label="No"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={YesMangalik}
                    onChange={handleChangeDiscover("YesMangalik")}
                    value="YesMangalik"
                  />
                }
                label="Yes"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontCareMangalik}
                    onChange={handleChangeDiscover("dontCareMangalik")}
                    value="dontCareMangalik"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
              onClick={onHidden("displayMangalik")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
    
      <Box style={displayDrinks}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nonDrink}
                    onChange={handleChangeDiscover("nonDrink")}
                    value="nonDrink"
                  />
                }
                label="No"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={drink}
                    onChange={handleChangeDiscover("drink")}
                    value="drink"
                  />
                }
                label="Yes"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={drinkdontCare}
                    onChange={handleChangeDiscover("drinkdontCare")}
                    value="drinkdontCare"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayDrinks")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayEducation}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={belowHighSchool}
                    onChange={handleChangeDiscover("belowHighSchool")}
                    value="belowHighSchool"
                  />
                }
                label="Below Hight School"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={highSchoolOrIntermediate}
                    onChange={handleChangeDiscover("highSchoolOrIntermediate")}
                    value="highSchoolOrIntermediate"
                  />
                }
                label="High School or Intermediate"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bachelors}
                    onChange={handleChangeDiscover("bachelors")}
                    value="bachelors"
                  />
                }
                label="Bachelors"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={doctorate}
                    onChange={handleChangeDiscover("doctorate")}
                    value="doctorate"
                  />
                }
                label="Doctorate"
              />
               <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DontCareEducation}
                    onChange={handleChangeDiscover("DontCareEducation")}
                    value="DontCareEducation"
                  />
                }
                label="Don't care"
              />
            </FormGroup>
            <Button
            onClick={onHidden("displayEducation")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displaySalary}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={salary1}
                    onChange={handleChangeDiscover("salary1")}
                    value="salary1"
                  />
                }
                label="Less than $25,000"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={salary2}
                    onChange={handleChangeDiscover("salary2")}
                    value="salary2"
                  />
                }
                label="$25001 to $350000"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={salary3}
                    onChange={handleChangeDiscover("salary3")}
                    value="salary3"
                  />
                }
                label="$350001 to $500000 "
              />
            </FormGroup>
            <Button
            onClick={onHidden("displaySalary")}
             
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
      <Box style={displayProfession}>
        <FormControl
          component="fieldset"
          className="borderCheck"
          style={style.ModalCheckBox}
        >
          <Container fixed>
          <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={ProfessionList}
      onChange={onChangeProfession}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            // style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </React.Fragment>
      )}
      style={{ width: "100%",marginBottom:270 }}
      renderInput={(params) => (
        <TextField className={classes.Autocomplete} {...params} variant="outlined" label="Select Profession" color="secondary" placeholder="Type Profession name" />
      )}
    />
            <Button
            onClick={onHidden("displayProfession")}
              style={style.buttonCheckbox}
              variant="contained"
              color="Secondary"
            >
              Done
            </Button>
          </Container>
        </FormControl>
      </Box>
     
      <Box pt={2} mb={3} className={classes.updateBtn} mt={1} bgcolor="#F1F3F8" >
      <Container fixed>
      <Link to="discover"><Button fullWidth size="large" color="secondary" variant="contained">Update</Button></Link>
      </Container>
      </Box>
    </div>
 
  )
}

const style = {
  profileHeading: {
    paddingTop: 10,
    textTransform: "capitalize"
  },
  menuButton: {
    textAlign: "right"
  },
  backButton: {
    textAlign: "center"
  },
  boxDisply: {
    display: "inline-block",
    marginRight: 5,
    borderRadius: 2,
    textTransform: "capitalize"
  },
  boxTransform: {
    transform: "translate(0px, -50px)"
  },
  locationIcon: {
    fontSize: 18,
    marginTop: -3,
    paddingRight: 3,
    color: "#8D92A3"
  },
  imageIcon: {
    fontSize: 30,
    color: "#8D92A3"
  },
  imageIconGrid: {
    textAlign: "center",
    marginTop: 27
  },
  textAlign: {
    textAlign: "center"
  },
  intrestBorder: {
    borderRadius: 4,
    border: "1px solid #ededed"
  },
  divider: {
    margin: "10px 0px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: "80%",
    backgroundColor: "#fff",
    boxShadow: 5,
    padding: "2px 4px 3px",
    borderRadius: 5
  },
  buttonModal: {
    width: "100%",
    // margin:"2px 0px",
    textTransform: "capitalize"
  },
  BoxInterest: {
    padding: "13px"
  },
  BoxInterestpadding: {
    padding: 5
  },
  ModalCheckBox: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: "#fff",
    padding: "20px 0px"
  },
  buttonCheckbox: {
    marginLeft: "auto",
    display: "block",
    padding: "7px 20px"
  }
};
