import React,{Component} from "react";
import clsx from "clsx";
import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  buttonWidth: {
    fontFamily:'SailecRegular!important',
    padding: "15px 0px",
    width: "100%",
    textTransform: "none",
    fontSize: 16,
  },
  btncolor: {
    color: "#8D92A3",
    fontFamily:'SailecRegular!important',
  },
  fontShareenath:{
    fontFamily:'ShreenathBoldRegular',
  },
  fontSailecMedium:{
    fontFamily:'SailecMedium!important',
  },
  fontSailecRegular:{
    fontFamily:'SailecRegular!important',
  },
  fontSailecBold:{
    fontFamily:'SailecBold!important',
  },
  image:{
    borderRadius:6,
    objectFit:"cover",
    height:130,
    width:130,
  },
  borderUpload:{
    textAlign: "center",
    padding:"37px 25px 24px",
    borderRadius: 8,
    position:"absolute",
    marginTop:"12%",
    left:"30%",
    zIndex:-9,
    backgroundColor:"#e8e8e8",
  },
  addIcon:{
    color:"rgb(220, 0, 78)",
    fontSize:35,
  },
  deleteIcon:{
    position:"absolute",
    color: "#fff",
    marginTop: -4,
    marginLeft: "69%",
  },
  gridRelative:{
      position:"relative",
      zIndex:100,
  },
  iconBtn:{
      padding:"12px 22px",
  },
  displayNone:{
      display:"none",
  },
  display:{
    display:"",
},
imgStyle:{
    position: "absolute",
    top: -2,
    left:0,
},
input: {
  display: 'none'
}
});
var state;
class uploadPicture extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
};
constructor(props){
    super(props);
    this.state={ 
    
        images: "",
       videos: [],
       imagepath:'',
    }
}
componentDidMount=()=>{
  const sharedData =JSON.parse(localStorage.getItem("register"));
  if(localStorage.getItem('register') !== null) {
    if(sharedData.state.page < 2) {
      this.props.history.push("/")
    }
  } else {
    this.props.history.push("/")
  }

  state = this.props.location.state
}
  handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'videos';
    fileReader.readAsDataURL(target.files[0]);
    
    fileReader.onload = (e) => {
     this.setState((prevState) => ({
        
        [name]: [...prevState[name], e.target.result],
             
        }),
        
        );          
    };
    
    const a = new FormData();
   const append = a.append('image',this.state.images)
    
};
onClickCancel=()=>{
this.setState({
  images:"",
})
}
onClickUpload = () =>{
  const sharedData = JSON.parse(localStorage.getItem("register"));
    const page = 3; 
  const image =this.state.images;
  localStorage.setItem("register", JSON.stringify({
    state: {
      ...sharedData.state,
      page
    }  
  }))
  fetch(image)
.then(res => res.blob())
.then(blob =>
  
  this.props.history.push({
  pathname:'/register-step2',
  state: {
    pic_blob: blob
  }
})
)

}

render(){
  const { classes } = this.props;
  const {myProfileData,open,images} = this.state;
  return (
    <div className={classes.root}>
      <Container fixed>
        <Box
          fontSize={24}
          mt={3}
          color="#000"
          textAlign="center"
          className={classes.fontSailecBold}
        >
          Don't want to take selfie?
        </Box>
        <Box
          fontSize={24}
          color="#000"
          textAlign="center"
          className={classes.fontSailecBold}
        >
          No problem!
        </Box>
        <Box fontSize={14} px={2} mt={1} className={classes.fontSailecRegular} color="#8D92A3" textAlign="center">
          सेल्फी नलिने? नो प्रोब्लेम! आफ्नो मोबाइलमा भएको फोटो हाल्नुहोस
        </Box>
        <Box mt={5} />
        <Grid container  className={classes.gridRelative}>
        <Grid item xs={4} />
                <Grid item  lg={4} xl={4} md={4} sm={4} xs={4}>
                
                      <Box className={classes.borderUpload}>
                 <input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-photo"
                          onChange={this.handleCapture}
                          type="file"
                      />
                      <label htmlFor="icon-button-photo">
                          <IconButton component="span" className={classes.iconBtn}>
                              <CameraAltIcon className={classes.addIcon}/>
                          </IconButton>
                      </label>
                      
                      <Box textAlign="center" className={images === "" ? classes.displayNone:classes.display}>
                          <Box className={classes.imgStyle}>
                          <IconButton aria-label="delete" onClick={this.onClickCancel} className={classes.deleteIcon}>
                <CancelIcon fontSize="small" />
              </IconButton>
                      <img src={images} width="100%" height="80px" className={classes.image}/>
                          </Box>
               
                      </Box>
                      </Box>
              </Grid>
              <Grid item xs={4} />
      
             
                      </Grid>
        <Grid container>
          <Grid item xs={12}>
      
            <Box mt={40}>
              {/* <Link to={images.length === 0 ? null :'/personal-details'}> */}
             
                <Button
                // disabled={images.length === 0 ? true: false}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWidth}
                  onClick={this.onClickUpload}
                >
                  Upload Photo
                </Button>
        
              <Link to="/personal-details">
                <Button
                
                  className={clsx(classes.buttonWidth, classes.btncolor)}
                >
                  Skip
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
}
export default withStyles(styles, { withTheme: true })(uploadPicture);
