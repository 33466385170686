import React from "react";
import clsx from "clsx";
import { Link,withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Typography,
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  InputBase,
  IconButton
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Logo from "../assets/images/Logo.png";
import homeBG from "../assets/images/Group 1184.png";
import PlayStore from "../assets/images/playstore.png";
import Register from "../assets/images/Register.png";
import Zoom from "../assets/images/zoom.png";
import Meeting from "../assets/images/meeting.png";
import Marriage from "../assets/images/Marriage.png";
import BlogBg from "../assets/images/background.png";
import Why from "../assets/images/why.png";
import Why2 from "../assets/images/Why-2.png";
import Why3 from "../assets/images/Why-3.png";
import Why4 from "../assets/images/Why-4.png";
import Why5 from "../assets/images/Why-5.png";
import Video from "../_common/video";
import Slider from "../_common/slider";
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../Home.css";
import Growry from '../assets/images/dowry.png';
const useStyles = makeStyles(theme => ({
  homeBG: {
    backgroundImage: `url('${homeBG}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  blogBg: {
    backgroundImage: `url(${BlogBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 48
  }
}));

const HomeNav = () => {
  return (
    <AppBar position="static" className="navBar">
      <div id="top">
        <Container fixed>
          <Toolbar>
            <Button>
              <img src={Logo} width="80px" alt="Logo" />
            </Button>
            <Typography variant="h6" className="title"></Typography>
            <Link to="/login">
              <Button
                variant="contained"
                color="secondary"
                className={clsx("buttonLogin", "fontSailecMedium")}
              >
                Login
              </Button>
            </Link>
          </Toolbar>
        </Container>
      </div>
    </AppBar>
  );
};

const Heading = () => {
  return (
    <Container fixed className="seo-heading">
      <Box
        textAlign="center"
        fontSize={28}
        mt={18}
        mb={4}
        pt={7}
        lineHeight={1.4}
        className={clsx("text-white", "fontSailecMedium")}
      >
        Find Hongkong Nepali Grooms
      </Box>
          <Button fullWidth variant="contained" className={clsx("loginbutton", "fontShareenath")}>
          k|fn]v agfpg'xf]; 
          </Button>
          <Button className={clsx("buttonTransparent2","fontShareenath")}>k|fn]v agfpg'xf]; </Button>
    </Container>
  );
};

const AlreadyRegister = () => {
  return (
    <Grid container className="alreadyRegister">
      <Grid item xs={12} sm={12} className="memberTransform">
       <Box py={5} />
        <img src={Growry} width="100%"/>
        <Box fontSize={24} textAlign="center" pt={2} className="fontShareenath">दाइजो प्रथा विरुद्ध हाम्रो आवाज</Box>
        <Box fontSize={14} textAlign="center" pb={2} className="fontSailecRegular" px={2}>NepaliVivah stands against the ridiculous 
dowry system.</Box>
<Button variant="contained" color="secondary" fullWidth><Box py={1} className="textCapitalize fontSailecRegular">Join us, say No</Box></Button>
      </Grid>
    </Grid>
  );
};
const SeaKeywords = () => {
  return(
    <>
    <Container fixed>
    <Box fontSize={20} my={2} className="fontSailecBold" color="#22242A">
      Nepali Religion Matrimonials
    </Box>
      <Grid container>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Hindu</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Jain</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Muslim</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kirant</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sikh</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Christian</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Buddhist</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Jewish</Box>
        </Grid>
      </Grid>
    </Container>
    <Box bgcolor="#F1F3F8" my={3} py={2}>
    <Container fixed>
    <Box fontSize={20} mb={2} className="fontSailecBold" color="#22242A">
      Nepali Religion Matrimonials
    </Box>
    
      <Grid container>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sudra Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Brahman Matrimony</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Vaishya Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kshatriya Matrimony</Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
    <Container fixed>
    <Box fontSize={20} my={2} className="fontSailecBold" color="#22242A">
    Nepali Community    </Box>
      <Grid container>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sherpa Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Limbu Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kayastha Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Madhesi Matrimony
</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pahadi Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Himali Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Tharu Matrimony
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Newar Matrimony</Box>
        </Grid>
      </Grid>
    </Container>
    <Box bgcolor="#F1F3F8" my={3} py={2}>
    <Container fixed>
    <Box fontSize={20} mb={2} className="fontSailecBold" color="#22242A">
    Nepal Cities    </Box>
    
      <Grid container>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu Brides
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara Brides
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj Brides</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kathmandu Brides
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Pokhara Brides
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj Grooms
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepalgunj Brides</Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
    <Container fixed>
    <Box fontSize={20} my={2} className="fontSailecBold" color="#22242A">
    General Matrimonial  </Box>
      <Grid container>
      <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Matrimonial


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Grooms Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Brides Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Singles

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Single Men

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Nepali Single Women

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Divorcee Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Second Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Widow Matrimonial</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Special Case Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sherpa Divorcee 
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">MatrimonialLimbu
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Divorcee Matrimonial
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Gurung Divorcee
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Matrimonial
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Brahman Divorcee 
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Matrimonial

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Intercaste Matrimonial</Box>
        </Grid>
       
      </Grid>
    </Container>
    <Box bgcolor="#F1F3F8" my={3} py={2}>
    <Container fixed>
    <Box fontSize={20} mb={2} className="fontSailecBold" color="#22242A">
    General Matrimonial    </Box>
    
      <Grid container>
      <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Mechi
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Koshi
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sagarmatha
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Janakpur
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Bagmati
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Narayani
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Gandaki
</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Lumbini
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Dhaulagiri
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Rapti
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Karnali</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Bheri</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Seti</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Mahakali</Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
    <Container fixed>
    <Box fontSize={20} my={2} className="fontSailecBold" color="#22242A">
    Country Matrimonial  </Box>
      <Grid container>
      <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">USA



</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">USA Nepali Grooms


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">USA Nepali Brides


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">UK

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">UK Nepali Grooms


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">UK Nepali Brides


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Canada

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Canada Nepali Grooms

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Canada Nepali Brides</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Australia

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Australia Nepali Grooms
 
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Australia Nepali Brides

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Russia
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Russia Nepali Grooms

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Russia Nepali Brides

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">India 
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">India Nepali Grooms


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">India Nepali Brides</Box>
        </Grid>
       
      </Grid>
    </Container>
    <Box bgcolor="#F1F3F8" my={3} py={2}>
      <Container fixed>
    <Box fontSize={20} mb={2} className="fontSailecBold" color="#22242A">
    World Cities    </Box>
    
      <Grid container>
      <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Grooms

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Brides

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Grooms

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Brides

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Singles

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Men
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Women
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Males



</Box>

        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Females


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single
 
</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Males
 
 </Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single 

</Box>
<Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Females

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Boys
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Girls
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single
</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Boys</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">New York Nepali Single</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Girls</Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
    <Container fixed>
    <Box fontSize={20} my={2} className="fontSailecBold" color="#22242A">
    Last Names </Box>
      <Grid container>
      <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Shrestha



</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">KC


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Shah


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sah

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Rana


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Buddhist


</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Thapa

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Jha

</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Saxena</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Yadav

</Box>
          <Box fontSize={12} mb={2} className="fontSailecRegular" color="#22242A">Raut
 
</Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Koirala</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Mishra</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Neupane</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Paudel</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Karki</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Chand</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Kushwaha</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Giri</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Sarki</Box>
          <Box fontSize={12} mb={1} className="fontSailecRegular" color="#22242A">Bishwakarma</Box>
          <Box fontSize={12} mb={2} className="fontSailecRegular" color="#22242A">Pant</Box>

        </Grid>
       
      </Grid>
    </Container>
  </>
  )
}
const DownloadApp = () => {
  return (
    <Box bgcolor="#F1F3F8">
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box
            textAlign="center"
            className="fontSailecRegular"
            pt={4}
            pb={1}
            fontSize={14}
            color="#8D92A3"
          >
            Download the app
          </Box>
          <Box textAlign="center" mb={2}>
            <a
              href="https://play.google.com/store/apps/details?id=com.main.nepalivivah"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <img src={PlayStore} width="180px" alt="Play Store Logo" />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const EasyToUseCard = props => {
  return (
    <Grid item xs={6} sm={6}>
      <Box pr={1} my={2}>
        <Box
          textAlign="center"
          pb={3}
          pt={2}
          boxShadow={2}
          className="customCard"
        >
          <img src={props.src} width="100px" alt="Card Icon" />
          <Box
            textAlign="center"
            fontSize={14}
            className="fontSailecRegular"
            color="#8D92A3"
          >
            {props.text}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const EasyToUseSection = () => {
  return (
    <Container fixed>
      <Box
        textAlign="center"
        pt={4}
        className="fontSailecRegular"
        pb={2}
        fontSize={24}
        color="#000000"
      >
        नेपालीविवाह चलाउन सजीलो छ
      </Box>
      <Grid container>
        <EasyToUseCard src={Register} text="रजिस्टर गर्नुहोस" />
        <EasyToUseCard src={Zoom} text="जीवनसाथी खोजनुहोस" />
        <EasyToUseCard src={Meeting} text="एकअर्कालाइ जान्नुहोस" />
        <EasyToUseCard src={Marriage} text="विवाह बन्धनमा बांधिनुहोस" />
      </Grid>
    </Container>
  );
};

const BlogSection = props => {
  return (
    <Box className={props.classes.blogBg} py={5}>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              textAlign="center"
              fontSize={14}
              className="fontSailecRegular"
              color="#ffffff"
            >
              नेपालीविवाहले धेरै नेपालीहरुलाई जीवनसाथी खोज्न मदद गरेको छ।
              तपाईंको जीवनसाथीको खोजमा हामी तपाईंसंग छौं
            </Box>
            <Button
              variant="contained"
              color="secondary"
              className={clsx("buttonFull", "fontSailecBold")}
            >
              read blog
            </Button>
            <Box py={2} />
            <Button className="buttonTransparent">REPORT YOUR MARRIAGE</Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const AddProfileCards = props => {
  return (
    <Box
      textAlign="center"
      my={2}
      pb={3}
      pt={2}
      bgcolor={props.bg}
      className={props.compClass}
    >
      <img src={props.src} width="100px" alt="Profile Card Icon" />
      <Box pt={2} fontSize={14} className="fontSailecRegular" color="#8D92A3">
        {props.textLine1}
      </Box>
      <Box fontSize={14} className="fontSailecRegular" color="#8D92A3">
        {props.textLine2}
      </Box>
    </Box>
  );
};

const AddProfileSection = () => {
  return (
    <Container fixed>
      <Box
        textAlign="center"
        pt={8}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        नेपालीविवाहमा हुनु पर्ने
      </Box>
      <Box
        textAlign="center"
        pb={2}
        className="fontSailecRegular"
        fontSize={24}
        color="#22242A"
      >
        मुख्य कारणहरु
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <AddProfileCards
            bg="#F1F3F8"
            compClass="customCard"
            src={Why}
            textLine1="See who expresses interest in you"
            textLine2="and cancels interest in you in real time"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Why2}
            textLine1="See who a member is interested in "
            textLine2="and when cancels interest"
          />
          <AddProfileCards
            bg="#F1F3F8"
            compClass={clsx("customCard", "customCardHeight")}
            src={Why3}
            textLine1="Our commitment against dowry and "
            textLine2="violence against women"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Why4}
            textLine1="Follow the member you are "
            textLine2="interested in"
          />
          <AddProfileCards
            bg="#F1F3F8"
            compClass={clsx("customCard", "customCardHeight")}
            src={Why5}
            textLine1="Cheapest price in the industry"
          />
          <AddProfileCards
            compClass={clsx(
              "customCard",
              "customCardBordered",
              "customCardHeight"
            )}
            src={Register}
            textLine1="Only serious members"
          />
        </Grid>
      </Grid>
      <Box
        textAlign="center"
        pt={5}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        आफ्नो प्रोफाइल यो पेज मा
      </Box>
      <Box
        textAlign="center"
        pb={2}
        className="fontSailecRegular"
        fontSize={24}
        color="#000000"
      >
        हेर्न चाहनु हुन्छ ?
      </Box>
      <Button
        color="secondary"
        className={clsx("buttonFull", "fontSailecMedium")}
        variant="contained"
        href="#top"
      >
        Add your profile
      </Button>
    </Container>
  );
};

const SafeHands = () => {
  return (
    <Box bgcolor="#F1F3F8" mt={6} pb={20}>
      <Container fixed>
        <Box
          textAlign="center"
          pt={5}
          fontSize={24}
          color="#000000"
          className="fontSailecRegular"
        >
          तपाई नेपालीविवाहको सुरक्षित
        </Box>
        <Box
          textAlign="center"
          pb={4}
          fontSize={24}
          color="#000000"
          className="fontSailecRegular"
        >
          हात मा हुनुहुन्छ
        </Box>
        <Box bgcolor="#fff" py={5} px={2} className="borRadius5">
          <Slider />
        </Box>
      </Container>
    </Box>
  );
};

const StartToday = () => {
  return (
    <Container fixed>
      <Box className="transVideo">
        <Video />
      </Box>
      <Box className="transVideoSec">
        <Box
          textAlign="center"
          fontSize={14}
          color="#8D92A3"
          className="fontSailecRegular"
          px={2}
          mt={3}
        >
          Join the exclusive family of NepaliVivah today and enjoy the ride of
          finding someone who truly understands you. Finding a great partner on
          NepaliVivah is a beautiful experience and you will enjoy it.
        </Box>
        <Button
          href="#top"
          variant="contained"
          color="secondary"
          className={clsx("buttonFull", "fontSailecMedium")}
        >
          start today
        </Button>
      </Box>
    </Container>
  );
};

function Home(props) {
  
  const classes = useStyles();
  if(localStorage.getItem('login') !== null){
    props.history.push("/discover")
  }
  const onSubmit = (value) =>{
    var targetwedding=`${value.month} ${value.year}` 
    props.history.push({
      pathname:"/register-step1",
  state:{
    targetwedding:targetwedding,
    sex:value.sex,
    marital_status:value.marital_status,
    location:value.location
  }})
  }
  return (
    <div>
      <Box className={classes.homeBG} pb={10}>
        <HomeNav />
        <Heading />
      </Box>
      <Container fixed>

        <AlreadyRegister />
      </Container>
      <SeaKeywords />
      <DownloadApp />
      <EasyToUseSection />
      <BlogSection classes={classes} />
      <AddProfileSection />
      <SafeHands />
      <StartToday />
    </div>
  );
}
export default withRouter(Home);
