import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Diet } from '../_common/data';


const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  buttonWidth2: {
    marginTop: 244,
    fontFamily: "SailecRegular!important",
    fontSize: 16,
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  progress: {
    position: "fixed",
    width: "100%",
    top: 0
  },
  letterSpacing: {
    letterSpacing: 1,
    fontFamily: "SailecMedium!important"
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(Diet[0]['value']);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickNext = () =>{
    
    const diet = value;
    const page = 8;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

    localStorage.setItem("registerQuestion", JSON.stringify({
      state: {
        ...sharedData.state,
        diet: diet,
        page
      }  
    }))

    props.history.push("register-question8")
  }

  const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

  if(localStorage.getItem('registerQuestion') !== null) {
    if(sharedData.state.page < 7) {
      props.history.push("/")
    }
  } else{
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={58}
      />
      <Container fixed>
        <Box
          fontSize={10}
          className={classes.letterSpacing}
          textAlign="center"
          mt={2}
          color="#8D92A3"
        >
          QUESTION 7 OF 12
        </Box>
        <Box
          fontSize={24}
          mt={3}
          className={classes.fontSailecBold}
          color="#000"
          textAlign="center"
        >
          What's your diet?
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          mt={1}
          color="#707070"
          fontSize={14}
        >
          तपाइको भोजन के हो ?
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={5} />
            <div className="buttonTab">
              <Tabs
                value={value}
                orientation="vertical"
                onChange={handleChange}
                textColor="primary"
                centered
              >
                { 
                  Diet.map((item, key) => {
                    return <Tab key={key} value={item.value} label={item.label} />
                  })
                }
              </Tabs>
            </div>
              <Button
              onClick={onClickNext}
                variant="contained"
                color="secondary"
                className={classes.buttonWidth2}
              >
                Next
              </Button>
            <Box mb={3}></Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
