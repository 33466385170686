import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import Lock from "../assets/images/lock.png";
import Keyhole from "../assets/images/keyhole.png";
import Key from "../assets/images/key.png";
const pstyle = {
  color: "#8D92A3",
  fontSize: 14,
  marginBottom: "24px",
  fontFamily: 'SailecRegular!important',
};
const heading = {
  fontFamily: 'SailecRegular!important',
}
export default function slider() {
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img className="" src={Lock} alt="First slide" width="150px" />
          <Carousel.Caption>
            <h3 style={heading}>गोपनिय</h3>
            <p style={pstyle}>
              NepaliVivah takes your privacy very seriously. Only other members
              can see your profile. Unlike other matrimonial websites, we don't
              allow users to randomly search for people and contact them.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="" src={Keyhole} alt="First slide" width="150px" />
          <Carousel.Caption>
            <h3 style={heading}>विस्वसनिय</h3>
            <p style={pstyle}>
              NepaliVivah takes your privacy very seriously. Only other members
              can see your profile. Unlike other matrimonial websites, we don't
              allow users to randomly search for people and contact them.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="" src={Key} alt="First slide" width="150px" />
          <Carousel.Caption>
            <h3 style={heading}>सुरक्षित</h3>
            <p style={pstyle}>
              NepaliVivah takes your privacy very seriously. Only other members
              can see your profile. Unlike other matrimonial websites, we don't
              allow users to randomly search for people and contact them.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
