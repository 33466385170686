import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import clsx from "clsx";
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import LinearProgress from '@material-ui/core/LinearProgress';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CheckoutForm} from './CheckoutForm';

const styles = theme => ({
  root: {
    borderRadius:39,
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  profileHeading:{
    paddingTop:10,
  },
    textField: {
        "& .MuiInput-underline:after": {
          borderWidth: 1
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderWidth: 1
          },
          "&:hover fieldset": {
            borderWidth: 1
          },
          "&.Mui-focused fieldset": {
            borderWidth: 1
          }
        }
      },
      checkIcon: {
        color: "#5CB85C"
      },
      warning: {
        color: "red"
      }, checkIcon: {
        color: "#5CB85C"
      },
      warning: {
        color: "red"
      },
      btnFull:{
        height:50,
        marginTop:20,
        textTransform:"capitalize",
        fontFamily:"SailecRegular",
        fontSize:16,
      },
      btnCenter:{
        textTransform:"capitalize",
        fontFamily:"SailecRegular",
        fontSize:16, 
        height:50,
        color:"#5BC0DE",
        borderColor:"#5BC0DE",
        borderWidth:2,
      },
      LinkColor:{
        color:"#f50057"
      }
});

const stripePromise = loadStripe('pk_test_y0mmKP6D5Ll3J2Espv0Zqgza');

class RegisterUserName extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };
    
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      showPassword: "",
      loading: false,
    }
  };

  handleLoader = (value) => {
    this.setState({loading: value });
  };

  handleChange =  (event) => {
    this.setState({cardNumber: event.target.value });
  };
    
  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword });
  };
    
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { loading } = this.state;
    const { classes } = this.props;
    
    return (   
      <div>
        { loading === true ? <LinearProgress className={classes.bar} color="secondary" /> :''}
        <Grid container className={clsx(classes.profileTop,"profilenav")}>                  
          <Link to="/my-profile">
            <Grid item className={classes.backButton} xs={2}>
              <IconButton aria-label="delete">
                <ArrowBackIcon /> 
              </IconButton>
            </Grid>
          </Link>
        
          <Grid item xs={10}>
            <Box textAlign="center" ml="-33px" fontSize={18} className={clsx(classes.profileHeading,"fontSailecBold")}>Pay by Card</Box>  
          </Grid>
                      
        </Grid>
        
        <Box mb={1}  borderBottom="1px solid #F1F3F8"/>

        <Container fixed>
          <Elements stripe={stripePromise}>
            <CheckoutForm props={this.props} action={this.handleLoader}/>
          </Elements>

          <Box fontSize={16} my={1} color="#22242A" className="fontSailecBold">You will be billed $9.99 per month</Box>
          <Box fontSize={14} my={1} color="#8D92A3" className="fontSailecRegular">If you do not have a credit or debit card, you can make a payment at our authorized payment center.
          </Box>

        </Container>

        <Box my={3} pt={1} bgcolor="#F1F3F8" />
        <Container fixed>
          <Box fontSize={18} color="#22242A" className="fontSailecBold" >क्रेडिट वा डेबिट कार्ड चलेन?</Box>
          <Box fontSize={14} color="#707070" className="fontSailecRegular" >तपाईंले शुल्क भुक्तानी तल लेखेको बैंकमा गएर पनि गर्न सक्नु हुन्छ</Box>
          <Box  textAlign="left" color="#8D92A3" mt={3} fontSize={14}>
            Account Number
          </Box>
          <Box            
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            012900000529524
          </Box>
          <Box  textAlign="left" color="#8D92A3" mt={2} fontSize={14}>
            Account Name
          </Box>
          <Box
            
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            UMA VENTURES PVT. LTD.
          </Box>
          <Box  textAlign="left" color="#8D92A3" mt={2} fontSize={14}>
            Bank Name
          </Box>
          <Box
            
            mb={2}
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            Bank Of Kathmandu Lumbini LTD.
          </Box>
        </Container>
      </div>       
    )
  }
}

export default withStyles(styles, { withTheme: true })(RegisterUserName)
