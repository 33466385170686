import React, { Component } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';


export default function LifeStyleEdit() {

 
        const [valueDrinking, setValueDrinking] = React.useState('no');
        const [valueSmoking, setValueSmoking] = React.useState('no');
        const [valueDieting, setValueDieting] = React.useState('veg');
      
        const handleChangeDrinking = (event, newValue) => {
          setValueDrinking(newValue);
        };
        const handleChangeSmoking = (event, newValue) => {
          setValueSmoking(newValue);
        };
        const handleChangeDieting = (event, newValue) => {
          setValueDieting(newValue);
        };
        return (
        

              
            <div>
                    <Grid container style={style.profileTop} className="profilenav">
                        
                    <Link to="/my-profile"><Grid item style={style.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading} >Lifestyle</Box> 
                        </Grid>
                  
                    </Grid>
                    <Container fixed>
                    <Box mt={3} mb={1} className="fontSailecRegular" fontSize={10} color="#8D92A3">
                    Do you drink?
        </Box>
        <BottomNavigation className="lifestyle" value={valueDrinking} onChange={handleChangeDrinking} >
      <BottomNavigationAction label="No" value="no"  />
      <BottomNavigationAction label="Yes" value="yes"  />
    </BottomNavigation>
        <Box mt={3} mb={1} fontSize={10} className="fontSailecRegular" color="#8D92A3">
        Do you smoke?
        </Box>
        <BottomNavigation className="lifestyle" value={valueSmoking} onChange={handleChangeSmoking} >
      <BottomNavigationAction label="No" value="no"  />
      <BottomNavigationAction label="Yes" value="yes"  />
    </BottomNavigation>
        <Box mt={3} mb={1} fontSize={10} className="fontSailecRegular" color="#8D92A3">
        What's your diet?
        </Box>
        <BottomNavigation className="lifestyle" value={valueDieting} onChange={handleChangeDieting} >
      <BottomNavigationAction label="Veg" value="veg"  />
      <BottomNavigationAction label="Non.Veg" value="non.veg"  />
    </BottomNavigation></Container>
                    <Container fixed><Link  to='./my-profile'>
                <Button
               
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={style.textcapitalize}
                >
                  Done
                </Button>
              </Link>
              </Container>
     </div>
        )
    }

const style = {
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize'
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
       textcapitalize: {
        textTransform: "capitalize",
        marginTop: 120,
        fontSize: 16,
        padding: "15px 0px",
      },
     
   
}