import React, { Component } from 'react';
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ExploreIcon from '@material-ui/icons/Explore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import { withRouter } from "react-router-dom";

import MyProfileInfoEdit from './MyProfileInfoEdit';
const styles = (theme) => ({
    input: {
        display: 'none'
    },
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize'
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
      borderUpload:{
        textAlign: "center",
        padding:"37px 25px 24px",
        borderRadius: 8,
        position:"absolute",
        marginTop:"12%",
        left:"30%",
        zIndex:-9,
        backgroundColor:"#e8e8e8",
      },
      addIcon:{
        color:"rgb(220, 0, 78)",
        fontSize:35,
      },
      deleteIcon:{
        position:"absolute",
        color: "#fff",
        marginTop: -4,
        marginLeft: "69%",
      },
      gridRelative:{
          position:"relative",
          zIndex:100,
      },
      iconBtn:{
          padding:"12px 22px",
      },
      displayNone:{
          display:"none",
      },
      display:{
        display:"",
    },
    imgStyle:{
        position: "absolute",
        top: -2,
        left:0,
    }
   
});
class profile extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    constructor(props){
        super(props);
        this.state={ 
            myProfileData:{
              images: "",
              id:1,
              title: 'Alina',
              author: 'author',
              cols: 4,
              name:'Puspa Chhetri',
              gender:'Female',
              age:25,
              height:`5'4''`,
              location:'Kathmandu, Nepal',
              status:'female',
              religion:'islam',
              description:'Adipisci quas repellat sed.'
            },
            images: "",
           videos: [],
           imagepath:'',
        }
    }
    handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';
        const {...myProfileData} = this.state;
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
         this.setState((prevState) => ({
            
            [name]: [...prevState[name], e.target.result],
                 
            }),
            
            );          
        };
    };
    onClickCancel=()=>{
    this.setState({
      images:"",
    })
    }


    render() {
        const { classes } = this.props;
       const {myProfileData,open,images} = this.state;
        return (
         
              
            <div>
                    <Grid container className={clsx(classes.profileTop,"profilenav")}>
                        
                    <Link to="/my-profile"><Grid item className={classes.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" className={classes.profileHeading} >Personal Info</Box> 
                        </Grid>
                  
                    </Grid>
                    <Grid container  className={classes.gridRelative}>
                
          <Grid item  lg={4} xl={4} md={4} sm={4} xs={4}>
          
                <Box className={classes.borderUpload}>
           <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-photo"
                    onChange={this.handleCapture}
                    type="file"
                />
                <label htmlFor="icon-button-photo">
                    <IconButton component="span" className={classes.iconBtn}>
                        <CameraAltIcon className={classes.addIcon}/>
                    </IconButton>
                </label>
                
                <Box textAlign="center" className={images === "" ? classes.displayNone:classes.display}>
                    <Box className={classes.imgStyle}>
                    <IconButton aria-label="delete" onClick={this.onClickCancel} className={classes.deleteIcon}>
          <CancelIcon fontSize="small" />
        </IconButton>
                <img src={images} width="100%" height="80px" className={classes.image}/>
                    </Box>
         
                </Box>
                </Box>
        </Grid>

       
                </Grid>
                <MyProfileInfoEdit />
                
   
     </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(profile)
