import axios from "axios";
import qs from "query-string";
import Image from '../assets/images/playstore.png'
import { loadStripe } from "@stripe/stripe-js";
export const userService = {
  login,
  resetPassword,
  Register,
  uploadImage,
  myDetails,
  partner_search,
  SearchProfile,
  profileData,
  interest,
  applyPromoCode,
  getPaymentIntent,
  paid,
  interestedList,
  interestedInList,
  Suggestions,
  recentJoined,
};

const URL_PREFIX = "https://m.nepalivivah.com/accessapi";

async function login(email,password) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
    }
  };

  const { data: response } = await axios.post(
    URL_PREFIX + "/login", 
    qs.stringify({
      email:email,
      password:password
    }),
    options
  );
  
  return response;
}

async function recentJoined(token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" :`${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + "/recent_joined?true",
    options
  );
  return response;
  
}
async function Suggestions(token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" :`${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + "/suggestions?true",
    options
  );
  return response;
  
}


async function Register(formData) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
    }
  };

  const { data: response } = await axios.post(
    URL_PREFIX + "/signup",
    qs.stringify(formData),
    options
  );
  return response;  
}

async function resetPassword(email) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + "/forgot_password",
    {
      params: {
        email: email
      }
    },
    options
  );

  return response;
}

async function uploadImage(token, formData) {
  const options = {
    headers: { 
      "Content-Type": "multipart/form-data;",
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.post(
    URL_PREFIX + '/profile_pic',
    formData,
    options
  );

  return response;
}

async function myDetails(formData, token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.post(
    URL_PREFIX + '/my_detail',
    qs.stringify(formData),
    options
  );
  return response;
}

async function partner_search(formData, Token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${Token}`,
      
    }
  };
 
  // some error habits and some additional fields
  const { data: response } = await axios.post(
    URL_PREFIX + '/partner_detail',
    qs.stringify(formData),
    options
  )

  return response;
}

async function applyPromoCode(promoCode, token) {
  const headers = { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      "Authorization" : `${token}`,
    };

  const { data: response } = await axios.get(
    URL_PREFIX + "/promo_code_validate",
    {
      params: {
        promocode: promoCode
      },
      headers: headers
    }
  );

  return response;
}

async function getPaymentIntent(token) {
  const headers = { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      "Authorization" : `${token}`,
    };

  const { data: response } = await axios.get(
    URL_PREFIX + "/payment_intent?true",
    {
      headers: headers
    }
  );

  return response;
}

async function paid(token) {
  const headers = { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      "Authorization" : `${token}`,
    };

  const { data: response } = await axios.get(
    URL_PREFIX + "/paid?true",
    {
      headers: headers
    }
  );

  return response;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        console.log("Here");
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

async function SearchProfile(value, token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX+`/search_user?query=${value}`,
    options
  );

  return response;
}

async function profileData(username, token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + `/profile?username=${username}`,
    options
  );

  return response;
}

async function interest(username, token, action) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };
  const { data: response } = await axios.post(
    URL_PREFIX + '/follow',
    qs.stringify({
      username:username,
      action:action
    }),
    options
  )

  return response;
}
async function interestedList(username,token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + `/followers?username=${username}`,
    options
  );

  return response;
}
async function interestedInList(username,token) {
  const options = {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      'X-ApiRequest' : true,
      'Access-Control-Allow-Origin':true,
      "Authorization" : `${token}`,
    }
  };

  const { data: response } = await axios.get(
    URL_PREFIX + `/followings?username=${username}`,
    options
  );

  return response;
}
