import React, { Component } from 'react';
import clsx from "clsx";
import SearchIcon from '@material-ui/icons/Search';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {Link} from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ExploreIcon from '@material-ui/icons/Explore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import LogoutIcon from '../assets/images/round-exit_to_app-24px@2x.png'; 
import NavMenu from '../discover/NavMenu';
const styles = (theme) => ({
    input: {
        display: 'none'
    },
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize',
        fontFamily:"SailecBold!important"
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
       textcapitalize: {
        textTransform: "capitalize",
        marginTop: 120,
        fontSize: 16,
        padding: "15px 0px",
      },
      subItem:{
          paddingTop:18,
          fontFamily:"SailecRegular!important",
      },
      FabSettings:{
          color:"#5CB85C",
          backgroundColor:"rgba(76, 191, 63, 0.13)",
      },
      FabStar:{
        color:"#f50057",
        backgroundColor:"rgba(191, 74, 63, 0.13)",
      },
      containerLeft:{
          paddingRight:0,
      },
      FabBlue:{
          backgroundColor:"rgba(91, 192, 222, 0.19)",
          color:"#5BC0DE",
      },
      root2: {
        flexGrow: 1,
        maxWidth: 500,
      },
      tabs:{
        position:"fixed",
        borderTop:"1px solid #E3E3E3",
        bottom:"-8px",
        display:"block",
        width:"100%",
        backgroundColor:'#fff',
      },
      tab:{
        fontSize:"10px!important",
        textTransform:'capitalize!important',
        fontFamily:"SailecRegular!important",
        '&:focus , &$focusVisible':{
            outlineColor:'transparent',
          }
      },
      profilePic:{
        height:44,
        width:44,
        objectFit:"cover",
        borderRadius:"50%"
      },
      Avatar:{
        height:44,
        width:44,
        backgroundColor:"#5CB85C",
        fontFamily: "SailecBold",
        fontSize:16,
      }
   
});
class PhysicalAppearance extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    constructor(props){
      super(props);
      this.state={
        userData:"",
      }
    }
    handleChangeNav = (event, newValue) => {
      this.setState({navTab:newValue});
      switch (newValue) {
        case 0:
        this.props.history.push('/discover')
          break;
        // case 1:
        //   this.props.history.push('/feed')
        //   break;
        // case 2:
        //   this.props.history.push('/feed')
        //   break;
        // case 3:
        //   this.props.history.push('/feed')
        //   break;
        case 4:
          this.props.history.push('/my-account')
          break;
          
      }
    };
 
    onLogout = () =>{
      localStorage.removeItem('login'); 
    }
    onClickViewProfile=()=>{
      const userData =JSON.parse(localStorage.getItem('login'));
      const {username} = userData.details;
      console.log(username)
      this.props.history.push({
        pathname:"/profile",
        search: username
      })
      
    }
    componentDidMount=()=>{
      const userData =JSON.parse(localStorage.getItem('login'));
      this.setState({userData:userData})
      
    }
    render() {
        const { classes } = this.props;
        if(localStorage.getItem('login') === null){
          this.props.history.push("/")
        }
        const MyData =  this.state.userData.details;
        return (
           

              
            <div>
                    <Grid container className={clsx(classes.profileTop,"profilenav")} >
                        
                    <Link to="/my-profile"><Grid item className={classes.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <SearchIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className={classes.profileHeading} >My Account</Box> 
                        </Grid>
                  
                    </Grid>
                    <Container fixed onClick={this.onClickViewProfile}>
                        <Box py={2}>
                        <Grid container >
                            <Grid item xs={2} className={classes.dpPadding}>
        
                                {MyData === undefined ? "" : MyData.profile_pic.length > 3 ? <img src={MyData.profile_pic} width="100%" alt="profile image" className={classes.profilePic}/> : <Avatar className={classes.Avatar}>{MyData.profile_pic}</Avatar>}
                                
                            </Grid>
                            <Grid item xs={10}>
                                <Box fontSize={16} color="#22242A" className="fontSailecBold">
                                {MyData === undefined ? "" : MyData.name}
                                </Box>
                                <Box fontSize={14} color="#8D92A3" className="fontSailecLight">
                                View your profile
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                    </Container>
                    <Box pt={1} bgcolor="#F1F3F8" />
                       <Container fixed>
                           <Box fontSize={12} letterSpacing={1} pt={2} pb={1} className="fontSailecMedium"  color="#8D92A3">
                           PROFILE SETTINGS
                           </Box>
                       </Container>
                       <Box pt={1} borderBottom="1px solid #E3E3E3" />
                      <Link to="/account-settings">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
          <SettingsOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

       <Link to="account-settings"> <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Account setting
                           </Box>
                           </Link>
        </Grid></Grid>
        </Container></Link> 
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container>
   
                          <Container fixed>
            <Link to="/subscription-details">
              <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabStar}>
          <StarBorderOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Subscription details
                           </Box>
        </Grid></Grid></Link>
        </Container>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #fff" />
        </Container>
        {/* <Box pt={1} bgcolor="#F1F3F8" />
                       <Container fixed>
                           <Box fontSize={12} letterSpacing={1} pt={2} pb={1} className="fontSailecMedium"  color="#8D92A3">
                           PROFILE SETTINGS
                           </Box>
                       </Container>
                       <Box pt={1} borderBottom="1px solid #E3E3E3" />
                      <Link to="interested-list">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
          <FavoriteBorderOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Interested in you
                           </Box>
        </Grid></Grid>
        </Container></Link>
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container>
        <Link to="interested-in-list">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabStar}>
          <FavoriteBorderOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        You interested in
                           </Box>
        </Grid></Grid>
        </Container></Link>
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container>
        <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
          <PhotoLibraryOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Photos
                           </Box>
        </Grid></Grid>
        </Container></Link>
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container>
        <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
          <img src={roundFaceGreen} width="25px" alt="face icon"/>
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Partner Preferences 
                           </Box>
        </Grid></Grid>
        </Container></Link>
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container>
        <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabBlue}>
         <img src={roundFaceBlue} width="25px" alt="round face blur"/>
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Partner Info
                           </Box>
        </Grid></Grid>
        </Container></Link>
        <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #fff" />
        </Container> */}
        {/* <Box pt={1} bgcolor="#F1F3F8" />
        <Container fixed>
                           <Box fontSize={12} letterSpacing={1} pt={2} pb={1} className="fontSailecMedium"  color="#8D92A3">
                           HELP AND SUPPORT
                           </Box>
                       </Container> */}
                       {/* <Box pt={1} borderBottom="1px solid #E3E3E3" />
                      <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabBlue}>
          <ErrorOutlineOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        About Us
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabStar}>
          <FavoriteBorderOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Careers
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
          <img src={SupportIcon} width="25px" alt="support icon"/>
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Support
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabBlue}>
          <ReportProblemOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Terms 
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabStar}>
         <LockOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Privacy policy
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabSettings}>
         <LocationOnOutlinedIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Payment locations
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        {/* <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabBlue}>
         <CreditCardIcon />
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Become a payment center
                           </Box>
        </Grid></Grid>
        </Container></Link> */}
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #E3E3E3" />
        </Container> */}
        <Link to="my-account">
                          <Container fixed>
                          <Grid container>
                             <Box pt={1} textAlign="center"> <Grid item xs={2}>
                       
                           <Fab size="small"  aria-label="add" className={classes.FabBlue}>
         <img src={LogoutIcon} width="25px" alt="logout icon"/>
        </Fab></Grid></Box>
        <Grid item xs={10}>

        <Box onClick={this.onLogout} fontSize={14} className={classes.subItem} ml={1} color="#22242A">
        Log out
                           </Box>
        </Grid></Grid>
        </Container></Link>
        {/* <Container fixed className={classes.containerLeft}>
        <Box pt={1} borderBottom="1px solid #fff" />
        </Container>
        <Box my={8} /> */}
     <NavMenu tabValue={3}/>
     </div>

        )
    }
}
export default withStyles(styles, { withTheme: true })(PhysicalAppearance)
