import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeAnimated from "react-select/animated";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import SearchBg from "../assets/images/Group618.png";
import { Religions, Caste, MaritalStatus, Drink, Smoke, Diet } from "../_common/data";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef
      }}
      open={open}
      enterTouchDelay={8}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const style = {
  root: {
    flexGrow: 1
  },
  bgPartner: {
    backgroundImage: `url(${SearchBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  button: {
    width: 100,
    textTransform: "capitalize",
    background: "#F1F3F8",
    boxShadow: "none",
    padding: "13px 10px 13px 10px",
    border: "1px solid #dedede"
  },
  button: {
    fontSize: 16,
    padding: "15px 0px",
    width: "100%",
    textTransform: "none"
  }
};

export default function LinearDeterminate(props) {
  const [value, setValue] = React.useState({
    age_min:26,
    age_max:40,
    drink:"D",
    smoke:"D",
    diet:"D",
    age:[26,40],
    religion:[],
    caste:[],
    marital_status:[]
  });

  const handleChangeAge = (event, newValue) => {
    setValue({...value, age:newValue, age_min:newValue[0], age_max:newValue[1]})
  };

  const handleChangeDrinking = (event, newValue) => {
    setValue({...value, drink: newValue})
  }

  const handleChangeDieting = (event, newValue) => {
  setValue({...value, diet: newValue})
  }

  const handleChangeSmoking = (event, newValue) => {
    setValue({...value, smoke: newValue})
  }

  const onChangeReligion = (e, valueSelected) => {
    const religion = valueSelected.map((mapValue) => {
      return mapValue.value
    })
    setValue({...value, religion: religion})
  }

  const onChangeCaste = (e, valueSelected) => {
    const caste = valueSelected.map((mapValue) => {
      return mapValue.value
    })
    setValue({...value, caste: caste})
  }
  
  const onChangeMarital = (e, valueSelected) => {
    const marital_status = valueSelected.map((mapValue) => {
      return mapValue.value
    })
    
    setValue({...value, marital_status: marital_status})
  }

  const { age, diet, smoke, drink, age_min, age_max, marital_status, caste, religion } = value;
  const onClickSubmit = () => {
    const page = 3;
    localStorage.setItem("registerSearch", JSON.stringify({
      state: {
        ...sharedData.state,
        ...value,
        page 
      }
    }))

    props.history.push("register-partner-search-1d")
  }

  const sharedData =JSON.parse(localStorage.getItem("registerSearch"));
  if(localStorage.getItem('registerSearch') !== null) {
    if(sharedData.state.page < 2) {
      props.history.push("/")
    }
  } else {
    props.history.push("/")
  }

  return (
    <div style={style.root}>
      <Box style={style.bgPartner}>
        <Container fixed>
          <Box
            fontSize={24}
            pt={3}
            className="fontSailecBold"
            color="#fff"
            textAlign="center"
          >
            Now tell us qualities
          </Box>
          <Box
            fontSize={24}
            className="fontSailecBold"
            color="#fff"
            textAlign="center"
          >
            of the life partner
          </Box>
          <Box
            textAlign="center"
            className="fontSailecRegular"
            mt={1}
            pb={1}
            color="#fff"
            fontSize={14}
          >
            तपाईको जीवनसाथी मा हुनु पर्ने स्वभाव उपलब्ध गराउनुहोस
          </Box>
        </Container>
      </Box>
      <Container fixed>
        <Box
          mt={2}
          mb={1}
          className="fontSailecRegular"
          fontSize={14}
          color="#8D92A3"
        >
          Partner's Religion
        </Box>
        <Autocomplete
          multiple
          onChange={onChangeReligion}
          
            readOnly={true}
          
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          getOptionLabel={Religions => Religions.label}
          options={Religions}
          renderOption={(Religions, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {Religions.label}
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField {...params} color="secondary"  variant="outlined" label="Partner's Religion" />
          )}
        />
        <Box
          mt={2}
          mb={1}
          className="fontSailecRegular"
          fontSize={14}
          color="#8D92A3"
        >
          Partner's Caste
        </Box>
        <Autocomplete
      multiple
      onChange={onChangeCaste}
      disableCloseOnSelect
      id="checkboxes-tags-demo"
      options={Caste}
      getOptionLabel={Caste => Caste.label}
      renderOption={(Caste, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {Caste.label}
        </React.Fragment>
      )}
      style={{ width: "100%" }}
      renderInput={params => (
        <TextField {...params} color="secondary" variant="outlined" label="Partner's Caste" />
      )}
    />
        <Grid container>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              className="fontSailecRegular"
              fontSize={10}
              color="#000"
            >
              PARTNER'S AGE
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={10}
              className="fontSailecRegular"
              textAlign="right"
              color="#FA396F"
            >
              {`${age_min} - ${age_max}`}
            </Box>
          </Grid>
        </Grid>
        <Slider
          className="sliderButtonStyle"
          value={age}
          onChange={handleChangeAge}
          color="secondary"
          step={1}
          max={60}
          min={20}
        />
        <Box
          mt={3}
          mb={1}
          className="fontSailecRegular"
          fontSize={10}
          color="#8D92A3"
        >
          PARTNER'S DRINKING HABITS
        </Box>
        <BottomNavigation
          className="partner-btn"
          value={drink}
          onChange={handleChangeDrinking}
        >
          {
            Drink.map((item, key) => {
              return <BottomNavigationAction label={item.label} value={item.value} />
            })
          }
          <BottomNavigationAction label="Don't care" value="D" />
        </BottomNavigation>
        <Box
          mt={3}
          mb={1}
          fontSize={10}
          className="fontSailecRegular"
          color="#8D92A3"
        >
          PARTNER'S SMOKING HABITS
        </Box>
        <BottomNavigation
          className="partner-btn"
          value={smoke}
          onChange={handleChangeSmoking}
        >
          {
            Smoke.map((item, key) => {
              return <BottomNavigationAction label={item.label} value={item.value} />
            })
          }
          <BottomNavigationAction label="Don't care" value="D" />
        </BottomNavigation>
        <Box
          mt={3}
          mb={1}
          fontSize={10}
          className="fontSailecRegular"
          color="#8D92A3"
        >
          PARTNER'S DIETING HABITS
        </Box>
        <BottomNavigation
          className="partner-btn"
          value={diet}
          onChange={handleChangeDieting}
        >
          {
            Diet.map((item, key) => {
              return <BottomNavigationAction label={item.label} value={item.value} />
            })
          }
          <BottomNavigationAction label="Don't care" value="D" />
        </BottomNavigation>
        <Box
          mt={2}
          mb={1}
          fontSize={10}
          className="fontSailecRegular"
          color="#8D92A3"
        >
          PARTNER'S MARITAL STATUS
        </Box>
        <Autocomplete
          multiple
          onChange={onChangeMarital}
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          options={MaritalStatus}
          getOptionLabel={MaritalStatus => MaritalStatus.label}
          clearOnEscape
          renderOption={(MaritalStatus, { selected }) => (        
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {MaritalStatus.label}
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField {...params} color="secondary" variant="outlined" label="Partner's Marital Status" />
          )}
        />
        <Box mb={3}></Box>
          <Button
            variant="contained"
            color="secondary"
            className="fontSailecRegular"
            style={style.button}
            disabled={marital_status.length === 0|| caste.length === 0|| religion.length === 0}
            onClick={onClickSubmit}
          >
            Continue
          </Button>
        <Box mb={3}></Box>
      </Container>
    </div>
  );
}
