  export const Religions = [
    { value: 1, label: 'Hinduism', color: '#00B8D9', isFixed: true, id:3, isDefault: true},
    { value: 2, label: 'Buddhism', color: '#00B8D9', isFixed: true, id:1, isDefault: false},
    { value: 3, label: 'Sikhism', color: '#00B8D9', isFixed: true, id:1, isDefault: false},
    { value: 4, label: 'Christianity', color: '#00B8D9', isFixed: true, id:2, isDefault: false},
    { value: 5, label: 'Islam', color: '#00B8D9', isFixed: true, id:4, isDefault: false},
    { value: 6, label: 'Judaism', color: '#00B8D9', isFixed: true, id:5, isDefault: false},
    { value: 7, label: 'Jainism', color: '#00B8D9', isFixed: true, id:5, isDefault: false},
    { value: "D", label: 'Don\'t Care', color: '#00B8D9', isFixed: true, id:5, isDefault: false},
  ];

  export const Caste = [
    { value: 1, label: 'Brahman', rating: 'safe', id:1, isDefault: false },
    { value: 2, label: 'Kayastha', rating: 'good', id:2, isDefault: false},
    { value: 3, label: 'Kashatriya', rating: 'wild', id:3, isDefault: true},
    { value: 4, label: 'Sudra', rating: 'crazy', id:4, isDefault: false},
    { value: 5, label: 'Vaishya', rating: 'crazy', id:5, isDefault: false},
    { value: 6, label: 'Not Applicable', rating: 'crazy', id:5, isDefault: false},
    { value: "D", label: 'Don\'t Care', rating: 'crazy', id:5, isDefault: false},
  ];

  export const Education = [
    { value: 1, label: 'Below High School', isDefault: false },
    { value: 2, label: 'High School or Intermediate', isDefault: false },
    { value: 3, label: 'Bachelors', isDefault: true },
    { value: 4, label: 'Masters', isDefault: false },
    { value: 5, label: 'Doctorate', isDefault: false },
    { value: "D", label: 'Don\'t Care', isDefault: false },
  ];

  export const MaritalStatus = [
    { value: 1, label: 'Single', id:1 },
    { value: 2, label: 'Seperated', id:2 },
    { value: 3, label: 'Divorced', id:3 },
    { value: 4, label: 'Widowed', id:4 },
    { value: 5, label: 'Annuled', id:5 },
    { value: "D", label: 'Don\'t Care', id:6 },
  ];

  export const Built = [
    { value: 1, label: 'Slim', rating: 'safe', id:1},
    { value: 2, label:'Average' , rating: 'good', id:2 },
    { value: 3, label:'Athetic' , rating: 'good', id:3},
    { value: 4, label:'Heavy' , rating: 'good', id:4},
    { value: "D", label: 'Don\'t Care', rating: 'good', id:5},
  ];
  export const Complexion = [
    { value: 1, label: 'Fair', rating: 'safe', id:1 },
    { value: 2, label: 'Medium' , rating: 'good', id:2 },
    { value: 3, label: 'Dark' , rating: 'good', id:3 },
    { value: 4, label: 'Weatish' , rating: 'good', id:4 },
    { value: "D", label: 'Don\'t Care', rating: 'good', id:5 },
  ];

  export const Diet = [
    { value: 1, label: 'Veg'},
    { value: 2, label: 'Non-Veg'},
  ];

  export const Smoke = [
    { value: 1, label: 'Yes'},
    { value: 2, label: 'No'},
  ];

  export const Drink = [
    { value: 1, label: 'Yes'},
    { value: 2, label: 'No'},
  ];

  export const Profession = [
    { value: 'Accountant', label: 'Accountant', rating: 'safe', isDefault: false },
    { value: 'Architect', label: 'Architect', rating: 'safe', isDefault: false },
    { value: 'Army', label:'Army' , rating: 'good', isDefault: false },
    { value: 'Banker', label:'Banker' , rating: 'good', isDefault: false },
    { value: 'Business Owner', label:'Business Owner' , rating: 'good', isDefault: false },
    { value: 'Doctor', label:'Doctor' , rating: 'good', isDefault: false },
    { value: 'Engineer', label:'Engineer' , rating: 'good', isDefault: true },
    { value: 'Entertainer', label:'Entertainer' , rating: 'good', isDefault: false },
    { value: 'Farmer', label:'Farmer' , rating: 'good', isDefault: false },
    { value: 'Government Employee', label:'Government Employee' , rating: 'good', isDefault: false },
    { value: 'Lawyer', label:'Lawyer' , rating: 'good', isDefault: false },
    { value: 'Lecturer', label:'Lecturer' , rating: 'good', isDefault: false },
    { value: 'Nurse', label:'Nurse' , rating: 'good', isDefault: false },
    { value: 'Marketer', label:'Marketer' , rating: 'good', isDefault: false },
    { value: 'Office Assistant', label:'Office Assistant' , rating: 'good', isDefault: false },
    { value: 'Teacher', label:'Teacher' , rating: 'good', isDefault: false },
    { value: 'Politician', label:'Politician' , rating: 'good', isDefault: false },
    { value: 'Police', label:'Police' , rating: 'good', isDefault: false },
    { value: 'Student', label:'Student' , rating: 'good', isDefault: false },
    { value: 'Non Profit Worker', label:'Non Profit Worker' , rating: 'good', isDefault: false },
    { value: 'Not Working', label:'Not Working' , rating: 'good', isDefault: false },
    { value: 'Other', label:'Other' , rating: 'good', isDefault: false },
    { value: "D", label: 'Don\'t Care', rating: 'good', isDefault: false },
  ];

  export const Language = [
    { value: 1, label: "Avadhi", isDefault: false },
    { value: 2, label: "Assamese", isDefault: false },
    { value: 3, label: "Bengali", isDefault: false },
    { value: 4, label: "Bhojpuri", isDefault: false },
    { value: 5, label: "English", isDefault: false },
    { value: 6, label: "Garhwali", isDefault: false },
    { value: 7, label: "Gurung", isDefault: false },
    { value: 8, label: "Hindi", isDefault: false },
    { value: 9, label: "Limbu", isDefault: false },
    { value: 10, label: "Magar", isDefault: false },
    { value: 11, label: "Maithali", isDefault: false },             
    { value: 12, label:  "Nepali", isDefault: true, },
    { value: 13, label: "Newari", isDefault: false },
    { value: 14, label: "Rai", isDefault: false },
    { value: 15, label: "Sherpa", isDefault: false },
    { value: 16, label: "Tamang", isDefault: false },
    { value: 17, label: "Tharu", isDefault: false },
    { value: 18, label: "Tibetan", isDefault: false },
    { value: 19, label: "Urdu", isDefault: false },
    { value: 20, label: "Other", isDefault: false },
    { value: "D", label: 'Don\'t Care', isDefault: false }
  ];

  
  export const Currencies = [
    { value: 1, label:'USD', symbol: '$' },
    { value: 2, label: 'EUR', symbol: '€' },
    { value: 3, label: 'INR', symbol: '₹' },
    { value: 4, label: 'NRS', symbol: 'रू' },
  ];

  
  
  export const groupedOptions = [
    {      
      options: Religions,
    },
    {      
      options: Caste,
    },
    {      
      options:MaritalStatus,
    },
    {      
      options:Built,
    },
    {      
      options:Complexion,
    },
    {      
      options:Profession,
    }
  ];
  