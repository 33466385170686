import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import CameraAltIcon from '@material-ui/icons/CameraAlt';


export default class TakePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgDisplay: { display:'none' },
      imageURL: '',
      propsData: '',
      camDisplay: {
        display:'',
      },
      image:"",
      pic_blob: null
    }
  }

  componentDidMount = () => {
    const sharedData =JSON.parse(localStorage.getItem("register"));

    if(localStorage.getItem('register') !== null) {
      if(sharedData.state.page < 2) {
        this.props.history.push("/")
      }
    } else {
      this.props.history.push("/")
    }

    const {state} = this.props.location;
    this.setState({propsData: state});

    var video = document.getElementById('video');
    // Get access to the camera!
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Not adding `{ audio: true }` since we only want video now
      navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
        //video.src = window.URL.createObjectURL(stream);
        video.srcObject = stream;
        video.play();
      });
    }
    
    // Elements for taking the snapshot
    var canvas = document.getElementById('canvas');
    var context = canvas.getContext('2d');
    var video = document.getElementById('video');
    // Trigger photo take
    document.getElementById("snap").addEventListener("click", function() {
      context.drawImage(video, 0, 0, 640, 840);  
    });
  }

  onClickShow = () => {
    var canvas = document.getElementById('canvas');
    
    var dataURL = canvas.toDataURL();
    console.log(canvas)
    this.setState({imgDisplay: {display: ''},
      camDisplay: {
        display: 'none'
      },
      imageURL: dataURL
    })
  }

  imageDisplay = () => {
    this.setState({
      imgDisplay: {
        display: 'none'
      },
      camDisplay: {
        display: '',
      }
    })
  }
  
  onSelect = () => {
    const sharedData = JSON.parse(localStorage.getItem("register"));
    const page = 3; 

    var canvas = document.getElementById('canvas');
    var elem = this;
    this.setState({image:canvas})

    canvas.toBlob(function(blob) { 
      
      localStorage.setItem("register", JSON.stringify({
        state: {
          ...sharedData.state,
          page
        }  
      }))

      elem.props.history.push({
        pathname:'/register-step2',
        state: {
          pic_blob: blob
        }
      })
    });
  }

  onClickCancel = () => {
    // const {propsData} = this.state;
    this.props.history.push({
      pathname:'/upload-picture',
      state: {
        // ...propsData
      }
    })
  }

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Box style={this.state.camDisplay}>
              <video id="video" width="100%" autoPlay className="camera"></video>
              <Box fontSize={24} className="fontSailecBold" textAlign="center">Take a selfie!</Box>
              <Fab style={style.captureIcon} id="snap" onClick={this.onClickShow} size="small" color="secondary" aria-label="add" >
                <CameraAltIcon />
              </Fab>      
              <Box fontSize={24} style={style.boxFont} textAlign="center">ल एउटा सेल्फी ली हालौं</Box>
              <Box fontSize={14} color="#8D92A3" textAlign="center" className="fontSailecRegular">Photos with picture attract more views</Box>
            </Box>
          </Grid>
        </Grid>
          
        <Box style={this.state.imgDisplay}>
          <canvas className="camera" id="canvas" width="640" height="840" style={style.img} ></canvas>
          <Box bgcolor="#000" style={style.imgBtnBg} py={3}>
            <Container fixed>
              <Button style={style.buttonSecondary} onClick={this.onSelect} fullWidth variant="contained" color="secondary">
                Looks Good
              </Button>
              <Button onClick={this.imageDisplay} style={style.button2} fullWidth color="inherit">
                Recapture
              </Button>
            </Container>
          </Box>
        </Box>
    
        <IconButton onClick={this.onClickCancel} style={style.iconCancel}  aria-label="delete">
          <ClearIcon style={style.colorIcon} fontSize="small" />
        </IconButton>

      </div>
    )
  }
}

const style = {
  iconCancel:{
    position: "absolute",
    top: "15px",
    left: "15px",
  },
  colorIcon:{
    color:"#fff",
  },
  captureIcon:{
    position: "absolute",
    left: "44%",
    marginLeft:"auto",
    width:50,
    height:50,
    transform:"translate(0px, -104px)",
  },
  img:{
    width:"100%",
    height:"100%",
  },
  buttonSecondary:{
    textTransform: "capitalize",
    fontSize: 14,
    padding: 15,
  },
  button2:{
    textTransform: "capitalize",
    color:"#fff",
    fontSize: 14,
    padding: 15,
  },
  boxFont:{
    fontFamily:"ShreenathBoldRegular!important",
  },
  imgBtnBg:{
    transform: "translate(0px, -7px)"
  }
}
