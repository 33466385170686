import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Smoke } from '../_common/data';


const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  buttonWidth3: {
    marginTop: 0,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none",
    color: "#8D92A3"
  },
  buttonWidth2: {
    marginTop: 200,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  progress: {
    position: "fixed",
    width: "100%",
    top: 0
  },
  letterSpacing: {
    letterSpacing: 1,
    fontFamily: "SailecRegular!important"
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(Smoke[0]['value']);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickNext = (valueBind) => {
    const smoke = value;
    const page = 9;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

    if(valueBind === 'next') {
      localStorage.setItem("registerQuestion", JSON.stringify({
        state: {
          ...sharedData.state,
          smoke: smoke,
          page
        }  
      })) 

      props.history.push("register-question9")
    } else {
      const smoke = 'D';
      localStorage.setItem("registerQuestion", JSON.stringify({
        state: {
          ...sharedData.state,
          smoke: smoke,
          page
        }
      })) 
      props.history.push("register-question9")
    }
  }

  const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
  
  if(localStorage.getItem('registerQuestion') !== null) {
    if(sharedData.state.page < 8) {
      props.history.push("/")
    }
  } else{
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={66}
      />
      <Container fixed>
        <Box
          fontSize={10}
          className={classes.letterSpacing}
          textAlign="center"
          mt={2}
          color="#8D92A3"
        >
          QUESTION 8 OF 12
        </Box>
        <Box
          fontSize={24}
          mt={3}
          className={classes.fontSailecBold}
          color="#000"
          textAlign="center"
        >
          Do you smoke?
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          mt={1}
          color="#707070"
          fontSize={14}
        >
          के तपाईं धूम्रपान गर्नुहुन्छ ?
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={5} />
            <div className="buttonTab">
              <Tabs
                value={value}
                orientation="vertical"
                onChange={handleChange}
                textColor="primary"
                centered
              >
                { 
                  Smoke.map((item, key) => {
                    return <Tab key={key} value={item.value} label={item.label} />
                  })
                }
              </Tabs>
            </div>
        
              <Button
                onClick={onClickNext.bind(this, 'next')}
                variant="contained"
                color="secondary"
                className={classes.buttonWidth2}
              >
                Next
              </Button>
 
            <Box mb={3}></Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
