import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeAnimated from "react-select/animated";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import SearchBg from "../assets/images/Group618.png";
import Select from "react-select";
import { Profession } from "../_common/data";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import AutocompletePlaces from '../_common/AutocompletePlaces';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import {userService} from '../_services/user.service';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef
      }}
      open={open}
      enterTouchDelay={8}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  bgPartner: {
    backgroundImage: `url(${SearchBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  button: {
    width: 100,
    textTransform: "capitalize",
    fontWeight: 300,
    background: "#F1F3F8",
    boxShadow: "none",
    padding: "13px 10px 13px 10px",
    border: "1px solid #dedede"
  },
  margin: {
    marginRight: 12
  },
  bgColor: {
    background: "#FA396F!important",
    color: "#fff"
  },
  buttonWidth: {
    marginTop: 164,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  },
  bar:{
    position:'fixed',
    width:'100%',
    top:0,
  },
  TextField:{   
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius:"0px 6px 6px 0px!important",
        borderColor: '#F1F3F8',
      },
      '&:hover fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
    },
},
TextFieldSelect:{
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius:"0px 6px 6px 0px!important",
      borderColor: '#F1F3F8',
    },
    '&:hover fieldset': {
      borderColor: '#F1F3F8',
      borderWidth:1,
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F1F3F8',
      borderWidth:1,
    },
  },
}
}));
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'INR',
    label: '₹',
  },
  {
    value: 'NRS',
    label: 'रू',
  },
];
export default function LinearDeterminate(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState({
    salary:"",
    location:null,
    profession:'',
    loading:false,
    alert:false,
    vertical: 'top',
    horizontal: 'center',
    error:'',
    currency:"",
  });

  const { salary, location,currency, profession, vertical, horizontal, alert, loading, error} = value; 
  const value1 = salary[0];
  const value2 = salary[1];

  const changeLocation = valueSearch => {
    setValue({ ...value, location: valueSearch });
  };
  const handleChange = (event, newValue) => {
    setValue({...value, salary: newValue});
  };
  const onChangeProfession = (e, selectedValue) => {
    const professionID = selectedValue.map((mapValue) => {
      return mapValue.value
    })
    setValue({...value, profession: professionID});
  }
  
  const parseFormData = (salary, location, profession) => {
    let formData = {
      religion: sharedData.state.religion,
      caste: sharedData.state.caste,
      age_min: sharedData.state.age[0],
      age_max: sharedData.state.age[1],
      drink: sharedData.state.drink,
      smoke: sharedData.state.smoke,
      diet: sharedData.state.diet,
      marital_status: sharedData.state.marital_status,
      built: sharedData.state.built,
      complexion: sharedData.state.complexion,
      height_from: sharedData.state.height[0],
      height_to: sharedData.state.height[1],
      profession: profession,
      education: "D",
      location: location.description,
      city: (location.terms.length >= 1) ? location.terms[0].value : '',
      state: (location.terms.length >= 2) ? location.terms[1].value : '',
      country: (location.terms.length >= 3) ? location.terms[2].value : '',
      salary_nation: currency,
      salary_min: salary[0],
      salary_max: salary[1],
      native_language: "D"
    }

    return formData;
  }

  const onSubmit = () => {
    const sharedData = JSON.parse(localStorage.getItem("registerSearch"));

    const {salary, location, profession} = value;
    let formData = parseFormData(salary, location, profession)

    setValue({...value, loading:true})
  
    userService.partner_search(formData, sharedData.state.Token)
    .then(res => {
      if (res.status === 'success') {
        
        const token = {token:sharedData.state.Token}
        const userData = {...res.user,...token}
        console.log("test",userData)
        localStorage.setItem("login", JSON.stringify({login:true,
          details:userData
        }))

        localStorage.removeItem("registerSearch")
        localStorage.removeItem("register")
        props.history.push({pathname: '/subscription'})
      } else { 
        setValue({...value, error:res.message, loading:false, alert:true,})
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setValue({...value,alert:false});
  };
  const handleChangeCurrency = (event) => {
    setValue({...value,currency:event.target.value});
  };
  const sharedData =JSON.parse(localStorage.getItem("registerSearch"));

  if(localStorage.getItem('registerSearch') !== null) {
    if(sharedData.state.page < 4) {
      props.history.push("/")
    }
  } else {
    props.history.push("/")
  }
const onChangeSalary=(e)=>{
  setValue({...value,salary:e.target.value})
}
  return (
    <div>
       { loading === true ? <LinearProgress className={classes.bar} color="secondary" /> :''}
      <Box className={classes.bgPartner}>
        <Container fixed>
          <Box
            fontSize={24}
            pt={3}
            className={classes.fontSailecBold}
            color="#fff"
            textAlign="center"
          >
            Your partner's
          </Box>
          <Box
            fontSize={24}
            className={classes.fontSailecBold}
            color="#fff"
            textAlign="center"
          >
            profession & location
          </Box>
          <Box
            textAlign="center"
            mt={1}
            pb={1}
            color="#fff"
            className={classes.fontSailecRegular}
            fontSize={14}
          >
            तपाईको जीवनसाथी मा हुनु पर्ने स्वभाव उपलब्ध गराउनुहोस
          </Box>
        </Container>
      </Box>
      <Container fixed>
        <Box
          mt={2}
          mb={1}
          fontSize={10}
          className={classes.fontSailecRegular}
          color="#8D92A3"
        >
          PARTNER'S LOCATION
        </Box>
        <AutocompletePlaces location={changeLocation}/>
        <Box
          mt={2}
          mb={1}
          fontSize={10}
          className={classes.fontSailecRegular}
          color="#8D92A3"
        >
          PARTNER'S PROFESSION
        </Box>
        <Autocomplete
          multiple
          onChange={onChangeProfession}
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          options={Profession}
          getOptionLabel={Profession => Profession.label}
          renderOption={(Profession, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {Profession.label}
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField {...params} readOnly color="secondary" variant="outlined" label="Select Profession" />
          )}
        />
        <Grid container>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={10}
              className={classes.fontSailecRegular}
              color="#22242A"
            >
              PARTNER'S SALARY PER YEAR
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={10}
              className={classes.fontSailecRegular}
              textAlign="right"
              color="#FA396F"
            >
              {/* {`$${value1}K - $${value2}K`} */}
           
            </Box>
          </Grid>
        </Grid>
        <Grid container>
                <Grid xs={3}>
                <TextField
          id="standard-select-currency-native"
          select
          // label="Currency"
          fullWidth
          variant="outlined"
          value={value.currency}
          onChange={handleChangeCurrency}
          className={classes.TextFieldSelect} 
          SelectProps={{
            native: true,
          }}
          // helperText="Please select your currency"
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.label}>
              {option.label}
            </option>
          ))}
        </TextField>
                </Grid>
                <Grid xs={9}>
                  <TextField className={classes.TextField} onChange={onChangeSalary} variant="outlined" fullWidth placeholder="Enter amount" type="number"/>
                </Grid>
              </Grid>
          <Button
            variant="contained"
            color="secondary"
            disabled = {profession.length === 0 || location === null || value.salary === "" ? true :false}
            onClick={onSubmit}
            className={classes.buttonWidth}
          >
            Continue
          </Button>
        <Box mb={3}></Box>
        <Snackbar anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`} open={alert} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {error}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
