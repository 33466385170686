import React, { Component } from "react";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import FavoriteIcon from "@material-ui/icons/Favorite";
import TabSubscription from "./TabSubscription";
import SubBg from '../assets/images/starsBg.png';
import Hearts from '../assets/images/hearts.png';

const styles = theme => ({
  input: {
    display: "none"
  },
  profileHeading: {
    paddingTop: 10,
    textTransform: "capitalize",
    fontFamily: "SailecBold!important"
  },
  imgBorder: {
    borderRadius: 6,
    border: "3px solid #fff"
  },
  boxTransform: {
    maxWidth: "20%",
    position: "absolute",
    top: 67,
    left: "47%",
    zIndex: -8,
    borderRadius: 6,
    transform: "rotate(20deg)",
    padding: 26
  },
  iconSize: {
    fontSize: "20px!important"
  },
  buttonIcon: {
    background: "#5CB85C",
    textTransform: "capitalize",
    fontFamily: "SailecRegular!important",
    color: "#fff"
  },
  SubBg:{
    backgroundImage: `url(${SubBg})`,
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    height:"100px",
  },
  mxauto:{
    marginLeft:"auto",
    marginRight:"auto",
  },
  Fab:{
    width:90,
    height:90,
  }

});

class PhysicalAppearance extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          container
          className={clsx(classes.profileTop, "profilenav")}
        >
                
          <Grid item className={classes.backButton} xs={2}>
          </Grid>
                
          <Grid item xs={10}>
            <Box
              textAlign="center"
              ml="-33px"
              fontSize={18}
              className={classes.profileHeading}
            >
              Subscribe Now
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}  borderBottom="1px solid #F1F3F8"/>
              
          <Container fixed>
            <Box
              textAlign="center"
              mt={4}
              fontSize={18}
              className="fontSailecBold"
            >
              सब्सक्राइब गरिसकेपछि तपाइको सदस्यता एक्टीव  हुनेछ  
            </Box>
            <Box
              textAlign="center"
              mt={3}
              fontSize={14}
              color="#707070"
              className="fontSailecRegular"
            >
              सदस्यता लिए पछि तपाइंले नेपालीविवाह को पुरा सेवा लिन 
सक्नु हुने छ
            </Box>
          </Container>
          <Box py={1} mt={2} bgcolor="#F1F3F8"/>
              
          <Container fixed>
            <Grid conatiner>
              <Grid className={classes.mxauto} xs={8}>
                <Box className={classes.SubBg} mt={2} pt={2} pb={4}>
                  <Box textAlign="center" >
                    <Fab
                      className={classes.Fab}
                      color="secondary"
                      // size="small"
                      aria-label="add"
                      size="large"
                      // className={classes.Fab}
                    >
                      <img src={Hearts}  width="60%"/>
                    </Fab> 
                  </Box>
                </Box>
              </Grid>
            </Grid>
              
            <Box
              textAlign="center"
              mt={3}
              fontSize={14}
              className="fontSailecBold"
            >
              Subscribe and start chatting now 
            </Box>
            <Box textAlign="center" my={2}></Box>
          </Container>
          <TabSubscription props={this.props} />
        </div>
        
    );
  }
}
export default withStyles(styles, { withTheme: true })(PhysicalAppearance);
