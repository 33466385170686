import React, { Component } from "react";

import PropTypes from 'prop-types';


import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import { Currencies } from '../_common/data';


const styles = (theme) => ({
  TextField:{   
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius:"0px 6px 6px 0px!important",
        borderColor: '#F1F3F8',
      },
      '&:hover fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
    },
  },
  TextFieldSelect:{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius:"0px 6px 6px 0px!important",
        borderColor: '#F1F3F8',
      },
      '&:hover fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F1F3F8',
        borderWidth:1,
      },
    },
  }
})
class App extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      salary: "",
      currency: 1,
      index: 2,
    };
  }

  onClickNext = () =>{ 
    const {salary, currency} = this.state;
    const page = 11;
    const sharedData = JSON.parse(localStorage.getItem("registerQuestion"));

    localStorage.setItem("registerQuestion", JSON.stringify({
      state: {
        ...sharedData.state,
        salary: salary,
        salary_nation: currency,
        page
      }  
    })) 

    this.props.history.push("register-question11")
  }

  onClickCancel = () => {
    const page = 11;
    const sharedData = JSON.parse(localStorage.getItem("registerQuestion"));

    localStorage.setItem("registerQuestion", JSON.stringify({
      state: {
        ...sharedData.state,
        salary: "",
        salary_nation: "",
        page
      }  
    })) 
    
    this.props.history.push("register-question11")
  }

  handleChangeCurrency = (event) => {
    this.setState({currency:event.target.value});
  };

  onChangeSalary = (e)=>{
    this.setState({salary:e.target.value});
  }
  render() {
    const { optionGroups, valueGroups } = this.state;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

    if(localStorage.getItem('registerQuestion') !== null) {
      if(sharedData.state.page < 10) {
        this.props.history.push("/")
      }
    } else {
      this.props.history.push("/")
    }

    const { classes } = this.props;

    return (
      <div>
        <LinearProgress style={progress} variant="determinate" value={82} />
        <Container fixed>
          <Box
            fontSize={10}
            style={letterSpacing}
            className="fontSailecRegular"
            textAlign="center"
            mt={2}
            color="#8D92A3"
          >
            QUESTION 10 OF 12
          </Box>
          <Box
            fontSize={24}
            px={3}
            className="fontSailecBold"
            mt={3}
            color="#000"
            textAlign="center"
          >
            How much your salary
          </Box>
          <Box
            fontSize={24}
            px={3}
            className="fontSailecBold"
            color="#000"
            textAlign="center"
          >
            per year ?
          </Box>
          <Box
            textAlign="center"
            className="fontSailecRegular"
            mt={1}
            color="#707070"
            fontSize={14}
          >
            प्रति वर्ष तपाइको तलब कति हो ?
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={3} />
              <Grid container>
                <Grid xs={3}>
                  <TextField
                    id="standard-select-currency-native"
                    select
                    // label="Currency"
                    fullWidth
                    variant="outlined"
                    value={this.state.currency}
                    onChange={this.handleChangeCurrency}
                    className={classes.TextFieldSelect} 
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {
                      Currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.symbol}
                        </option>
                      ))
                    }
                  </TextField>
                </Grid>
                <Grid xs={9}>
                  <TextField className={classes.TextField} onChange={this.onChangeSalary} variant="outlined" fullWidth placeholder="Enter amount" type="number"/>
                </Grid>
              </Grid>
            
              <Button
                variant="contained"
                onClick={this.onClickNext}
                color="secondary"
                className="fontSailecRegular"
                style={buttonWidth2}
              >
                Next
              </Button>

              <Button onClick={this.onClickCancel} className="fontSailecRegular" style={buttonWidth3}>
                I'd rather not say
              </Button>
              <Box mb={3}></Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
const buttonWidth3 = {
  marginTop: 20,
  fontSize: 16,
  padding: "15px 0px",
  display: "block!important",
  bottom: 13,
  position: "fixed",
  width: "91%",
  textTransform: "none"
};
const buttonWidth2 = {
  marginTop: 20,
  fontSize: 16,
  padding: "15px 0px",
  display: "block!important",
  bottom: 73,
  position: "fixed",
  width: "91%",
  textTransform: "none"
};
const progress = {
  position: "fixed",
  width: "100%",
  top: 0
};
const letterSpacing = {
  letterSpacing: 1
};

export default withStyles(styles, { withTheme: true })(App);
