import React from "react";
import clsx from "clsx";
import {
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Container
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import Joi from "joi";
import { userService } from "../_services/user.service";
import { validate, validateProperty } from "../_common/validate";
import "../../App.css";

const useStyles = makeStyles(theme => ({
  buttonWidth: {
    marginTop: 250,
    padding: "15px 0px",
    width: "100%",
    textTransform: "none",
    fontSize: 16
  },
  textField: {
    "& .MuiInput-underline:after": {
      borderWidth: 1
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1
      },
      "&:hover fieldset": {
        borderWidth: 1
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1
      }
    }
  },
  checkIcon: {
    color: "#5CB85C"
  },
  warning: {
    color: "red"
  },
  lineHeight: {
    lineHeight: 1.6
  }
}));

const Header = () => {
  return (
    <>
      <Box fontSize={24} textAlign="center" className="fontSailecBold" mt={3}>
        Reset your password?
      </Box>
      <Box
        fontSize={14}
        mt={1}
        color="#707070"
        className="fontSailecRegular"
        textAlign="center"
      >
        पासवर्ड बिर्सनु भएको छ भने आफ्नो इमेल अड्रेस
      </Box>
      <Box
        fontSize={14}
        className="fontSailecRegular"
        color="#707070"
        textAlign="center"
      >
        टाइप गर्नुहोस
      </Box>
    </>
  );
};

// schema for validating login form
const schema = {
  email: Joi.string()
    .min(5)
    .email()
    .label("Email")
};

const successMsg =
  "नया पासवर्ड सेट गर्ने लिंक तपाइको इमेल मा पठाइएको छ। कृपया आफ्नो इमेल चेक गर्नुहोस।";
const errorMsg = "तपाइले टाइप गर्नु भएको इमेल नेपलिविवाहमा रजिस्टर छैन ";

const Form = () => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    form: {
      email: ""
    },
    formError: "",
    errors: {},
    submitted: false,
    success: false
  });

  // method for handling form field change
  const handleChange = prop => event => {
    const errors = { ...values.errors };
    const error = validateProperty(prop, event.target.value, schema);

    if (error) errors[prop] = error;
    else delete errors[prop];

    setValues({
      ...values,
      form: { ...values.form, [prop]: event.target.value },
      errors,
      submitted: false
    });
  };

  function onSubmit(e) {
    e.preventDefault();

    // validate form and return if there are errors
    const errors = validate(values, schema);
    console.log(errors);
    setValues({ ...values, errors: errors, formError: "" });
    if (Object.keys(errors).length) return;

    // API call for verifying login credentials
    userService.resetPassword(values.form.email).then(response => {
      if (response.status === "error")
        setValues({
          ...values,
          errors: { email: errorMsg },
          success: false,
          submitted: true
        });
      else
        setValues({
          ...values,
          success: true,
          submitted: true,
          errors: { email: successMsg }
        });
    });
  }

  return (
    <>
      <Box
        fontSize={14}
        className="fontSailecRegular"
        mt={3}
        mb={1}
        color="#8D92A3"
      >
        My Email Address Is
      </Box>
      <TextField
        error={values.submitted && !values.success ? true : false}
        fullWidth
        id="outlined-required"
        type="email"
        name="email"
        placeholder="Enter your email"
        className={classes.textField}
        variant="outlined"
        value={values.form.email}
        onChange={handleChange("email")}
        helperText={
          <Box
            color={values.success ? "#5CB85C" : "red"}
            className="fontSailecRegular"
          >
            {values.errors.email}
          </Box>
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" aria-label="email icon">
                {values.submitted ? (
                  values.success ? (
                    <CheckIcon className={classes.checkIcon} />
                  ) : (
                    <ErrorOutlineIcon className={classes.warning} />
                  )
                ) : (
                  ""
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button
        disabled={values.submitted}
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        className={clsx(classes.buttonWidth, "fontSailecRegular")}
      >
        Request Reset Link
      </Button>
    </>
  );
};

export default function ResetPassword(props) {
  if(localStorage.getItem('login') !== null){
    props.history.push("/discover")
  }
  return (
    <div>
      <Container fixed>
        <Header />
        <Form />
      </Container>
    </div>
  );
}
