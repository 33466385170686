import React,{useEffect} from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SearchBg from "../assets/images/group.png";
import IconSearch from "../assets/images/undraw_super_thank_you_obwk.png";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {userService} from '../_services/user.service';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  searchBg: {
    backgroundImage: `url(${SearchBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  buttonWidth: {
    padding: "15px 0px",
    width: "100%",
    textTransform: "none",
    fontFamily:'SailecRegular!important',
    fontSize: 16
  },
  fontShareenath:{
    fontFamily:'ShreenathBoldRegular',
  },
  fontSailecMedium:{
    fontFamily:'SailecMedium!important',
  },
  fontSailecRegular:{
    fontFamily:'SailecRegular!important',
  },
  fontSailecBold:{
    fontFamily:'SailecBold!important',
  },
}));
export default function ButtonAppBar(props) {
  const classes = useStyles();
  
  const onClickNext = () => {
    const page = 2;
    localStorage.setItem("registerSearch", JSON.stringify({
      state: {
        ...sharedData.state,
        page 
      }
    }))

    props.history.push("register-partner-search")
  }

  const sharedData =JSON.parse(localStorage.getItem("registerSearch"));
  const registerData =JSON.parse(localStorage.getItem("register"));

  if(localStorage.getItem('registerSearch') !== null) {
    if(sharedData.state.page < 1) {
      props.history.push("/")
    }
  } else {
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      <Box className={classes.searchBg}>
        <Container fixed>
          <Grid container>
            <Grid item xs={12}>
              <Box textAlign="center" pt={8}>
                <img src={IconSearch} width="200px" alt="icon"/>
              </Box>
              <Box
                fontSize={24}
                textAlign="center"
                className={classes.fontSailecBold}
                mt={3}
              >
                Great! Wasn't that easy?
              </Box>
              <Box
                fontSize={14}
                textAlign="center"
                px={5}
                color="#8D92A3"
                className={classes.fontSailecRegular}
                mt={1}
              >
                {" "}
                So you are a {registerData.state.marital_status_label} {registerData.state.sex.charAt(0).toUpperCase() + registerData.state.sex.slice(1)} looking to get married by {registerData.state.targetwedding}.
              </Box>
              <Box
              className={classes.fontSailecRegular}
                fontSize={14}
                textAlign="center"
                px={5}
                color="#707070"
                mt={4}
                mb={1}
              >
                ल त खोज सुरु गरौँ
              </Box>
           
                <Button
                onClick={onClickNext}
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWidth}
                >
                  Begin Search
                </Button>
    
              <Box py={9}></Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
