import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { withStyles,useTheme } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import clsx from "clsx";
import {Link} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from '@material-ui/core/Snackbar';
import filterIcon from '../assets/images/filterIcon.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ExploreIcon from '@material-ui/icons/Explore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import { userService } from "../_services/user.service";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ItemsCarousel from "react-items-carousel";
import DpImage from '../assets/images/Rectangle -8.png';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  root2: {
    flexGrow: 1,
    maxWidth: 500,
  },
  tabs:{
    position:"fixed",
    bottom:"-8px",
    display:"block",
    width:"100%",
    backgroundColor:'#fff',
    borderTop:"1px solid #E3E3E3",
  },
  tab:{
    fontSize:"10px!important",
    textTransform:'capitalize',
    fontFamily:"SailecRegular!important",
    '&:focus , &$focusVisible':{
        outlineColors:'transparent',
      }
  },
  appbar: {
    backgroundColor: "#fff",
    marginTop: 1,
    boxShadow: "none",
    borderBottom: "1px solid #cccdcf"
  },
  tab: {
    textTransform: "capitalize",
    fontFamily: "SailecMedium!important",
    fontSize: 16,
    "&:focus ": {
      outlineColor: "transparent"
    }
  },
  tabpanel: {
    paddingTop: 0
  },
  textRight: {
    textAlign: "right"
  },
  buttonIcon: {
    padding: "0px",
    minWidth: 20
  },
  divider: {
    height: "3px",
    width: "100%",
    backgroundColor: "#F1F3F8"
  },
  gridpassing: {
    padding: "0px 3px"
  },
  title: {
    fontSize: 14
  },
  buttonSecondry: {
    fontSize: 12,
    textTransform: "capitalize",
    borderColor: "#f2f2f2"
  },
  icon: {
    fontSize: '14px!important',
    marginRight: '5px!important'
  },
  locationIcon: {
    fontSize: '14px!important',
    marginRight: '5px!important'
  },
  womenIcon: {
    fontSize: '13px!important',
    marginLeft: '3px!important',
    marginRight: '5px!important'
  },
  content: {
    padding:'9px!important'
  },
  cancelButton: {
    position: "absolute",
    top: 6,
    right: 6,
    color: "#fff",
    background: "rgba(34,34,34,0.5)",
    borderRadius: "50%",
    fontSize: 20,
    padding: 3
  },
  card: {
    maxWidth: "100%",
    marginBottom:20,
  },
  boxDisply:{
      display:"inline-block",
      marginRight:4,
      borderRadius:2,
      fontFamily:"SailecRegular!important",
      fontSize:12,
  },
  locationIcon:{
      fontSize:18,
      marginTop:-3,
      paddingRight:3,
      color:'#8D92A3',
  },
  boxMT:{
      marginTop:3,
  },
  textAlign:{
      textAlign:'center',
  },
  fab:{
      boxShadow:"none!important",
  },
  pt0:{
      paddingTop:0,
  },
  skeletonCenter:{
    marginTop:10,
    marginLeft:'auto',
    marginRight:'auto',
  },
  colMarginTop:{
    marginTop:1,
  },
  profileTop:{
    position:'fixed',
    top:0,
    backgroundColor:"#fff",
    zIndex:1000,
    borderBottom:"1px solid #f2f2f2",
},
})
class Recent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
};
    constructor(props) {
        super(props);
        this.state = {
       profilesData:'',
       interestProfile:[],
       profilesDataSelect:[],
       message:"",
       open:false,
      }
    }
  componentDidMount(){
    if(localStorage.getItem('login') === null){
      this.props.history.push("/")
    }
    else{
    const userData =JSON.parse(localStorage.getItem('login'));
    const {token} = userData.details;
   userService
   .user(token)
   .then(res=> {
     if(res.status === 'success'){
       this.setState({profilesData:res.users})
     }
   })
 }
  }
   onClickAction = (value) => {
    this.props.props.history.push({
      pathname:"/profile",
      search: value.username
    })
  }
  onClickInterest=(username)=>{
      this.setState({[username]:true})
    const userData =JSON.parse(localStorage.getItem('login'));
    const {token} = userData.details;
    const action ="follow";
    userService
    .interest(username,token,action)
    .then((res)=>{
        res.status === "error" ? this.setState({message:res.message,open:true}) :
        console.log(res)
    })
  }
  onClickCancel=(username)=>{
    const action ="unfollow";
    this.setState({[username]:false})
    const userData =JSON.parse(localStorage.getItem('login'));
    const {token} = userData.details;
    userService
    .interest(username,token,action)
    .then((res)=>{
        res.status === "error" ? this.setState({message:res.message,open:true}) :
        console.log(res)
    })
  }
   handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open:false});
  };
  render() {
    const {profilesData}=this.state;
    const { classes } = this.props;
    console.log(this.state)
    return (
      <div>
          <Grid container className={classes.profileTop} >
                    
                    <Link to="/discover"><Grid item className={classes.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon /> 
    </IconButton>
    </Grid></Link>
                        <Grid item xs={8}>
                        <Box fontSize={20} mt={1} textAlign="center" className="fontSailecBold">My Interest</Box>
                        </Grid>
                        <Grid item className={classes.menuButton} xs={2}>
                      
                        <IconButton aria-label="delete">
                        <img src={filterIcon} />
    </IconButton>
                        </Grid>
                    
                    </Grid>
        <Container fixed>
          <Box py={4}/>
          <Grid container spacing={2}>
          {
          profilesData === "" ? <Box textAlign="center">Loading</Box>:
          profilesData.map(tile => (
                        <Grid item xs={6} className={classes.colMarginTop}>
                        <Card>
                          <CardActionArea>
                            {/* <CloseIcon
                              // onClick={onCancelClick.bind(this, tile)}
                              className={classes.cancelButton}
                            /> */}
                            {/* <Box onClick={onClickAction.bind(this, tile)}> */}
                            <Box>
                              <CardMedia
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={tile.profile_pic}
                                title={tile.name.slice(0,14)}
                              />
                              <CardContent className={classes.content}>
                                <Typography
                                  className="fontSailecMedium"
                                  className={classes.title}
                                >
                                  {tile.name.slice(0,14)}
                                  
                                </Typography>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <i
                                    className={clsx(classes.womenIcon,'fas fa-venus')}></i>{" "}
                                  {`${tile.age} Yrs, ${tile.sex}`}
                                </Box>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <LocationOnOutlinedIcon
                                    className={classes.locationIcon}
                                  />
                                  {`${tile.location.slice(0,11)}...`}
                                </Box>
                              </CardContent>
                            </Box>
                          </CardActionArea>

                          <CardActions>
                  

  


  {this.state[tile.username] === true ?
   <Button
   fullWidth
   className={classes.buttonSecondry}
   size="small"
   onClick={this.onClickCancel.bind(this,tile.username)}
   variant="outlined"
   color="default"
   // onClick={onClickSelect.bind(this, tile)}
 >
     
   <CancelIcon className={classes.icon} /> cancel
 </Button>
 :
 <Button
                              fullWidth
                              className={classes.buttonSecondry}
                              size="small"
                              onClick={this.onClickInterest.bind(this,tile.username)}
                              variant="outlined"
                              color="secondary"
                              // onClick={onClickSelect.bind(this, tile)}
                            >
                              <FavoriteIcon className={classes.icon} /> Show Interest
                            </Button>
}
                          
                          </CardActions>
                        </Card>
                        </Grid>
                      ))}
                      
                  </Grid>
        </Container>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.open}
        autoHideDuration={1000}
        onClose={this.handleClose}
        message={this.state.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
      
    )
  }
}
export default withStyles(styles, { withTheme: true })(Recent);