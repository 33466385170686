import React from "react";
import clsx from "clsx";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CountrySelect from "../_common/phone-number-api";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import {userService} from '../_services/user.service';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  buttonWidth: {
    marginTop: 20,
    padding: "15px 0px",
    width: "100%",
    textTransform: "none",
    fontFamily: "SailecRegular!important",
    fontSize: 16
  },
  textField: {
    "& .MuiInput-underline:after": {
      borderWidth: 1
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1
      },
      "&:hover fieldset": {
        borderWidth: 1
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1
      }
    }
  },
  textField2: {
    "& .MuiInput-underline:after": {
      border: "1px solid #ebedeb!important"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #ebedeb!important"
      },
      "&:hover fieldset": {
        border: "1px solid #ebedeb !important"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #ebedeb !important"
      }
    }
  },
  checkIcon: {
    color: "#5CB85C"
  },
  warning: {
    color: "red"
  },
  lineHeight: {
    lineHeight: 1.6
  },
  transform: {
    transform: "translate(-2px, 0px)",
    borderLeft: "none"
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  },
  bar:{
    position:'fixed',
    width:'100%',
    top:0,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    number: "",
    countryCode: "",
    showPassword: false,
    email: "",
    emailError: "",
    loading:false,
    Snackbar:false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const codeChange = code => {
    setValues({ ...values, countryCode: code });
  };
  
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const sharedData =JSON.parse(localStorage.getItem("register"));
  const {state} = props.location;
  console.log(state)
  const { countryCode, email, password, number, showPassword } = values;
  
  const parseFormData = (phone_number) => {
    let formData = {
      targetwedding: sharedData.state.targetwedding,
      first_name: sharedData.state.first_name,
      last_name: sharedData.state.last_name,
      sex: sharedData.state.sex,
      marital_status: sharedData.state.marital_status,
      birthday: sharedData.state.birthday,
      email: email,
      password: password,
      location: sharedData.state.location.description,
      city: (sharedData.state.location.terms.length >= 1) ? sharedData.state.location.terms[0].value : '',
      state: (sharedData.state.location.terms.length >= 2) ? sharedData.state.location.terms[1].value : '',
      country: (sharedData.state.location.terms.length >= 3) ? sharedData.state.location.terms[2].value : '',
      birth_place: sharedData.state.birth_place.description,
      phone_number: phone_number,
      register_platform: "MobileWeb"
    }

    return formData
  }
  
  const onSubmit = () => {
    const phone_number = `${countryCode}${number}`;
    setValues({...values, loading:true})
    
    let formData = parseFormData(phone_number);

    // Call register API
    userService.Register(formData).then(res => {
      if(res.status === 'success') {
        var formData = new FormData()
        formData.append('image', state.pic_blob, 'profile_pic.png');
        
        userService.uploadImage(res.token, formData)
        .then(response => {
          if(response.status === 'success') {  
            const page = 1;
            const Token = {Token:res.token}
            
            //localStorage.removeItem("register");
            localStorage.setItem("registerQuestion", JSON.stringify({state:{
              ...Token,
              page
            }  
            }))

            props.history.push("/register-question1")
          } else {
            setValues({...values, emailError:response.message, loading:false, Snackbar:true,})
        }});

      } else {
        setValues({...values, emailError:res.message, loading:false, Snackbar:true,})
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setValues({...values, Snackbar:false});
  };
 
  const {vertical, horizontal} = values;

  if(localStorage.getItem('register') !== null) {
    if(sharedData.state.page < 3) {
      props.history.push("/")
    }
  } else{
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      { values.loading === true ? <LinearProgress className={classes.bar} color="secondary" /> :''}
      <Container fixed>
        <Box
          fontSize={24}
          mt={3}
          color="#000"
          textAlign="center"
          className={classes.fontSailecBold}
        >
          Almost done! Just a bit
        </Box>
        <Box
          fontSize={24}
          color="#000"
          textAlign="center"
          className={classes.fontSailecBold}
        >
          more info
        </Box>
        <Box
          fontSize={14}
          px={2}
          className={classes.fontSailecRegular}
          mt={1}
          color="#8D92A3"
          textAlign="center"
        >
          लगभग सकियो! केहि थोरै जानकारी{" "}
        </Box>
        <Box
          fontSize={10}
          className={classes.fontSailecRegular}
          mb={1}
          color="#8D92A3"
          textAlign="left"
          mt={3}
        >
          MY MOBILE NUMBER IS
        </Box>
        <Grid container>
          <Grid item xs={4} sm={4}>
            <CountrySelect code={codeChange} />
          </Grid>
          <Grid item xs={8} sm={8}>
            <Box>
              {" "}
              <TextField
                id="outlined-required"
                fullWidth
                className={clsx(classes.textField2, classes.transform)}
                placeholder="Mobile Number"
                onChange={handleChange("number")}
                name="number"
                type="number"
                value={number}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Box
            fontSize={10}
            className={classes.fontSailecRegular}
            mb={1}
            color="#8D92A3"
            textAlign="left"
            mt={3}
          >
            MY EMAIL ADDRESS IS
          </Box>

          <TextField
            error={email.includes(".com") ? false : true}
            id="outlined-error"
            className={classes.textField}
            type="email"
            value={email}
            fullWidth
            onChange={handleChange("email")}
            placeholder="Enter your email"
            helperText={
              values.email.includes(".com") ? (
                <Box color="transparent" className={classes.lineHeight}>
                  vasvs
                </Box>
              ) : (
                <Box color="red" className={classes.lineHeight}>
                  तपाइले टाइप गर्नु भएको इमेल नेपलिविवाहमा रजिस्टर छैन{" "}
                </Box>
              )
            }
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {values.email.includes(".com") ? (
                      <CheckIcon className={classes.checkIcon} />
                    ) : (
                      <ErrorOutlineIcon className={classes.warning} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box
            fontSize={10}
            className={classes.fontSailecRegular}
            color="#8D92A3"
            mt={2}
            mb={1}
            textAlign="left"
          >
            CHOOSE A PASSWORD
          </Box>
          <TextField
            id="outlined-adornment-password"
            error={password.length >= 8 ? false : true}
            className={clsx(classes.margin, classes.textField)}
            variant="outlined"
            helperText={
              password.length >= 8
                ? false
                : "Must contain at least 8 or more characters"
            }
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            placeholder="Enter your password"
            onChange={handleChange("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={8}>
              
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={
                    (countryCode && number).length != 0 &&
                    password.length >= 8 &&
                    values.email.includes(".com")
                      ? false
                      : true
                  }
                  onClick={onSubmit}
                  className={classes.buttonWidth}
                >
                  Continue
                </Button>
                <Snackbar 
                  anchorOrigin={{ vertical, horizontal }}
                  key={`${vertical},${horizontal}`} open={values.Snackbar} autoHideDuration={8000} onClose={handleClose}
                >
                  <Alert className={classes.AlertBg} onClose={handleClose} severity="error">
                    {values.emailError}
                  </Alert>
                </Snackbar>
              <Box mb={3}></Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
