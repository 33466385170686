import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/login/Login";
import ResetPassword from "./components/login/ResetPassword";
import Home from "./components/Home";
import RegisterStep1 from './components/register/registerstep1'
import RegisterStep2 from './components/register/registerstep2'
import TakePicture from "./components/register/TakePicture";
import UploadPicture from "./components/register/upload-picture";
import RegisterSearch from "./components/RegisterSearch/register-search";
import RegisterQuestion1 from "./components/register/RegisterQuestion1";
import RegisterQuestion2 from "./components/register/RegisterQuestion2";
import RegisterQuestion3 from "./components/register/RegisterQuestion3";
import RegisterQuestion4 from "./components/register/RegisterQuestion4";
import RegisterQuestion5 from "./components/register/RegisterQuestion5";
import RegisterQuestion6 from "./components/register/RegisterQuestion6";
import RegisterQuestion7 from "./components/register/RegisterQuestion7";
import RegisterQuestion8 from "./components/register/RegisterQuestion8";
import RegisterQuestion9 from "./components/register/RegisterQuestion9";
import RegisterQuestion10 from "./components/register/RegisterQuestion10";
import RegisterQuestion11 from "./components/register/RegisterQuestion11";
import RegisterQuestion12 from "./components/register/RegisterQuestion12";
import RegisterQuestion13 from "./components/register/RegisterQuestion13";
import RegisterPartnerSearch from "./components/RegisterSearch/RegisterPartnerSearch";
import RegisterPartnerSearch1D from "./components/RegisterSearch/RegisterPartnerSearch1D";
import RegisterPartnerSearch1E from "./components/RegisterSearch/RegisterPartnerSearch1E";
import searchA from "./components/discover/search-a";
import Discover from "./components/discover/Discover";
import Profile from "./components/profile/profile";
import Stripe from './components/stripe/stripe'
import MyAccount from './components/myAccount/MyAccount'
import SEO from './components/SEO/seo.js';
import API from './components/stripe/apis/api';
import RecentlyJoind from './components/discover/recently-joined';
import MyInterest from './components/discover/myinterest-profiles';
import Subcription from './components/subcription/SubscriptionDetails';
import PayByCard from './components/subcription/payByCard';
import Success from './components/subcription/success';
import DiscoverSettings from './components/discover/discoverSettings';
import InterestedList from './components/interestList/InterestedList';
import InterestedInList from './components/interestList/InterestedInList';
import MyProfileInfoEdit from './components/myProfile/MyProfileInfo';
import AboutEdit from './components/myProfile/aboutEdit';
import PhysicalAppearanceEdit from './components/myProfile/PhysicalAppearanceEdit';
import LifeStyleEdit from './components/myProfile/LifeStyleEdit';
import SocialAppearanceEdit from './components/myProfile/SocialAppearanceEdit';
import ProfessionInfoEdit from './components/myProfile/ProfessionInfoEdit';
import AccountSettings from './components/myAccount/AccountSettings';
import Popup from './components/stripe/Popup';
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <Router>
        <div>
        <Switch>
        
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/register-step1" component={RegisterStep1} />
          <Route path="/register-step2" component={RegisterStep2} />
          <Route path="/register-question1" component={RegisterQuestion1} />
          <Route path="/register-question2" component={RegisterQuestion2} />
          <Route path="/register-question3" component={RegisterQuestion3} />
          <Route path="/take-a-picture" component={TakePicture} />
          <Route path="/upload-picture" component={UploadPicture} />
          <Route path="/register-search" component={RegisterSearch} />
          <Route path="/register-question1" component={RegisterQuestion1} />
          <Route path="/register-question2" component={RegisterQuestion2} />
          <Route path="/register-question3" component={RegisterQuestion3} />
          <Route path="/register-question4" component={RegisterQuestion4} />
          <Route path="/register-question5" component={RegisterQuestion5} />
          <Route path="/register-question6" component={RegisterQuestion6} />
          <Route path="/register-question7" component={RegisterQuestion7} />
          <Route path="/register-question8" component={RegisterQuestion8} />
          <Route path="/register-question9" component={RegisterQuestion9} />
          <Route path="/register-question10" component={RegisterQuestion10} />
          <Route path="/register-question11" component={RegisterQuestion11} />
          <Route path="/register-question12" component={RegisterQuestion12} />
          <Route path="/register-question13" component={RegisterQuestion13} />
          <Route path="/register-partner-search" component={RegisterPartnerSearch} />
          <Route path="/register-partner-search-1d" component={RegisterPartnerSearch1D} />
          <Route path="/register-partner-search-1e" component={RegisterPartnerSearch1E} />
          <Route path="/discover" component={Discover} />
          <Route path="/search-a" component={searchA} />
          <Route path="/profile" component={Profile} />
          <Route path="/stripe" component={Stripe} />
          <Route path="/my-account" component={MyAccount} />
          <Route path="/seo" component={SEO} />
          <Route path="/api" component={API} />
          <Route path="/recently-joined" component={RecentlyJoind} />
          <Route path="/my-interest" component={MyInterest} />
          <Route path="/subscription" component={Subcription} />
          <Route path="/pay-by-card" component={PayByCard} />
          <Route path="/success" component={Success} />
          <Route path="/discover-settings" component={DiscoverSettings} />
          <Route path="/Interested-list" component={InterestedList} />
          <Route path="/Interested-in-list" component={InterestedInList} />
          <Route path="/my-profile-info-edit" component={MyProfileInfoEdit} />
          <Route path="/about-edit" component={AboutEdit} />
          <Route path="/physical-appearance-edit" component={PhysicalAppearanceEdit} />
          <Route path="/lifestyle-edit" component={LifeStyleEdit} /> 
          <Route path="/social-appearance-edit" component={SocialAppearanceEdit} />
          <Route path="/profession-info-edit" component={ProfessionInfoEdit} />
          <Route path="/account-settings" component={AccountSettings} />
          <Route path="/popup" component={Popup} />
          
        </Switch>
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
