import React, { Component } from 'react';
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom';

import Button from '@material-ui/core/Button';
import PhysicalHeight from './PhysicalHeight';
import PhysicalBodyLook from './PhysicalBodyLook';
import PhysicalBodyComplexion from './PhysicalBodyComplexion';
const styles = (theme) => ({
    input: {
        display: 'none'
    },
   
});
class PhysicalAppearance extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
  
    render() {
        const { classes } = this.props;
     
        return (
         

              
            <div>
                    <Grid container style={style.profileTop} className="profilenav">
                        
                    <Link to="/my-profile"><Grid item style={style.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading} >Physical Appearance </Box> 
                        </Grid>
                  
                    </Grid>
                    <PhysicalHeight />
                    <PhysicalBodyLook />
                    <PhysicalBodyComplexion />
                    <Container fixed><Link  to='./my-profile'>
                <Button
               
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={style.textcapitalize}
                >
                  Done
                </Button>
              </Link>
              </Container>
                  
                
   
     </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(PhysicalAppearance)
const style = {
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize'
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
       textcapitalize: {
        textTransform: "capitalize",
        marginTop: 120,
        fontSize: 16,
        padding: "15px 0px",
      },
     
   
}