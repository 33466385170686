import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {userService} from '../_services/user.service';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  buttonWidth2: {
    marginTop: 100,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  buttonWidth3: {
    marginTop: 0,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none",
    color: "#8D92A3"
  },
  textarea: {
    backgroundColor: "#faf9f7",
    "& .MuiInput-underline:after": {
      border: "1px solid #ebedeb!important"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #ebedeb!important"
      },
      "&:hover fieldset": {
        border: "1px solid #ebedeb !important"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #ebedeb !important"
      }
    }
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  },
  bar:{
    position:'fixed',
    width:'100%',
    top:0,
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [value, setValue] =React.useState({
    aboutMe: '',
    loading: false,
    alert: false,
    vertical: 'top',
    horizontal: 'center',
    response: '',
    error: '',
    invalid_fields: '',
  })

  const onChange = (e) => {
    setValue({...value, aboutMe: e.target.value})
  }

  const parseFormData = (about_me) => {
    let formData = {
      height: sharedData.state.height,
      complexion: sharedData.state.complexion,
      built: sharedData.state.built,
      native_language: sharedData.state.native_language,
      religion: sharedData.state.religion,
      caste: sharedData.state.caste,
      diet: sharedData.state.diet,
      smoke: sharedData.state.smoke,
      drink: sharedData.state.drink,
      education: sharedData.state.education,
      profession: sharedData.state.profession,
      salary_nation: sharedData.state.salary_nation,
      salary: sharedData.state.salary,
      about_me: about_me
    }

    return formData
  }

  const onSubmit = (valueBind) => {
    const page = 1;
    const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
    setValue({...value, loading: true, alert: false,})

    let formData = {}
    if(valueBind === 'next') {
      formData = parseFormData(value.aboutMe);
    } else {
      formData = parseFormData("D");
    }

    userService.myDetails(formData, sharedData.state.Token)
    .then(res => {
      if(res.status === 'success'){
        localStorage.setItem("registerSearch", JSON.stringify({
          state: {
            ...sharedData.state,
            page 
          }  
        }))
        localStorage.removeItem("registerQuestion");
        props.history.push("register-search")
      }
      else {
        setValue({...value, error: res.message, loading: false, invalid_fields: res.invalid_fields, alert: true,})
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setValue({...value, alert: false});
  };

  const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));

  if(localStorage.getItem('registerQuestion') !== null) {
    if(sharedData.state.page < 13) {
      props.history.push("/")
    }
  } else{
    props.history.push("/")
  }

  const {vertical, horizontal, error, invalid_fields} = value;
  return (
    <div className={classes.root}>
    {value.loading === true ? <LinearProgress className={classes.bar} color="secondary" /> :''}
      <Container fixed>
        <Box
          fontSize={24}
          mt={3}
          className={classes.fontSailecBold}
          color="#000"
          textAlign="center"
        >
          Describe a little bit about you and your family
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          mt={1}
          color="#707070"
          fontSize={14}
        >
          तपाई र तपाइको परिवार बारेमा वर्णन गर्नुहोस्
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={5} />
            <TextField
              className={classes.textarea}
              id="outlined-multiline-static"
              multiline
              rows="10"
              placeholder="Write about you and your family"
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={onChange}
            />
              <Button
              onClick={onSubmit.bind(this,'next')}
                variant="contained"
                color="secondary"
                className={classes.buttonWidth2}
                disabled={value.aboutMe === undefined || value.aboutMe === '' ? true : false}
              >
                Next
              </Button>
              <Button onClick={onSubmit.bind(this, 'skip')} className={classes.buttonWidth3}>Skip</Button>
              <Snackbar anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`} open={value.alert} autoHideDuration={3000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    { error === '' ? '' : `${error} \n ${invalid_fields}` }
                  </Alert>
              </Snackbar>
            <Box mb={3}></Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
