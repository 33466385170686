import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Cost from "../assets/images/cost.png"; 
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { userService } from "../_services/user.service";
import LinearProgress from '@material-ui/core/LinearProgress';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  textTransform: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "SailecMedium!important"
  },
  btnGreen:{
    borderColor: 'green!important',
    borderWidth:2,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
  TabBg: {
    backgroundColor: "transparent!important",
    boxShadow: "none"
    //   borderBottom:"6px solid #e3e3e3",
    // paddingBottom:" 10px",
  },
  my: {
    padding: "10px 0px 0px 0px!important"
  },
  mImage: {
    marginTop: 10
  },
  socialImage: {
    marginTop: 15
  },
  SpeakIcon: {
    marginTop: 4
  },
  casteIcon: {
    marginTop: 17
  },
  EngineerIcon: {
    marginTop: 10
  },
  BtnHeading: {
    fontFamily: "SailecBold!important"
  },
  TabBadge: {
    width: 96
  },
  buttonIcon: {
    background: "#5CB85C",
    textTransform: "capitalize",
    fontFamily: "SailecRegular!important",
    color: "#fff",
    '&:hover': {
      background: "#5CB85C",
    },
  },
  textField: {
    padding: "10px 0px"
  }
  ,text:{
    textTransform:"lowercase"
  }
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState({
    tabMain: 0,
    TabValue: 0,
    dialog: false,
    promoCode: "",
    promoError: "",
    loading: false,
  });

  const handleChange = (event, newValue) => {
    setValue({ ...value, tabMain: newValue });
  };

  const handleChangeIndex = index => {
    setValue({ ...value, tabMain: index });
  };
  const handleChangeTab = (event, newValue) => {
    // setValue({ ...value, TabValue: newValue });
    props.history.push("/login")
  };
  const { TabValue, tabMain, dialog } = value;
  const handleClickOpen = () => {
    setValue({ ...value, dialog: true });
  };

  const handleClose = () => {
    setValue({ ...value, dialog: false });
  };
  
  const handlePromoCodeChange = prop => e => {
    setValue({ ...value, promoCode: e.target.value });
  };

  const handleApply = () => {
    setValue({...value, loading:true})
    const sharedData = JSON.parse(localStorage.getItem("login"));

    console.log(sharedData.details.token)
    userService
      .applyPromoCode(value.promoCode, sharedData.details.token)
      .then(response => {

        if (response.status === "error") {
          setValue({ ...value, promoError: response.message });
        } else {
          localStorage.setItem("login", JSON.stringify({
            ...sharedData,
            subscription: "Done"
          }))
          props.props.history.push("/success")
        }
      });
  };

  return (
    <div className={classes.root}>

      <AppBar position="static" className={classes.TabBg}>
        <Tabs
          value={tabMain}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {/* <Tab
            className={classes.textTransform}
            label="ipay"
            {...a11yProps(0)}
          /> */}
          <Tab
            className={classes.textTransform}
            label="Pay By Card"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.textTransform}
            label="Pay in Person"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      
      <Box pt={1} bgcolor="#ebebeb" />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabMain}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          className="tabpaddings"
          value={tabMain}
          index={0}
          dir={theme.direction}
        >
          <Box
            mx={3}
            mb={2}
            textAlign="center"
            color="#8D92A3"
            mt={3}
            fontSize={14}
          >
            Finding a partner takes some time. Best plan always works the best.
          </Box>
          <Box mx={2}>
            
            <Link to="/pay-by-card">
              <Button variant="outlined" className={classes.btnGreen} fullWidth>
                <Grid container>
                  <Grid item xs={9}>
                    <Box
                      fontSize={12}
                      bgcolor="#5CB85C"
                      className={classes.TabBadge}
                      color="#fff"
                      pl={1}
                      borderRadius={4}
                    >
                      Easy Plan
                    </Box>
                    <Box
                      fontSize={16}
                      textAlign="left"
                      className={classes.BtnHeading}
                      color="#22242A"
                      borderRadius={4}
                    >
                      Monthly Charges
                    </Box>
                    <Box textAlign="left" className={classes.text} color="#8D92A3" fontSize={14}>
                    $9.99 for a month
                    </Box>
                  </Grid>

                  <Grid item xs={3}>
                    <Box textAlign="right" mt={2}>
                      <img src={Cost} width="70%" />
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </Link>

            <Box my={2}>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel className="tabpaddings" value={tabMain} index={1}>
          <Box
            mx={3}
            mb={1}
            className="fontSailecBold"
            textAlign="center"
            color="#22242A"
            mt={3}
            fontSize={14}
          >
            डेबिट वा क्रेडिट कार्ड चलेन
          </Box>
          <Box mx={3} textAlign="center" color="#8D92A3" fontSize={14}>
            नेपालीविवाह पेमेंट सेंटर मा गएर भुक्तानी गर्नुहोस्
          </Box>
          <Box textAlign="center" my={2}>

          </Box>
          <Box pb={1} bgcolor="#F1F3F8" />
          <Box
            mx={3}
            mb={2}
            className="fontSailecBold"
            textAlign="center"
            color="#22242A"
            mt={3}
            fontSize={14}
          >
            तपाईंले शुल्क भुक्तानी तल लेखेको बैंकमा गएर पनि गर्न सक्नु हुन्छ
          </Box>
          <Box mx={3} textAlign="left" color="#8D92A3" mt={3} fontSize={14}>
            Account Number
          </Box>
          <Box
            mx={3}
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            012900000529524
          </Box>
          <Box mx={3} textAlign="left" color="#8D92A3" mt={2} fontSize={14}>
            Account Name
          </Box>
          <Box
            mx={3}
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            UMA VENTURES PVT. LTD.
          </Box>
          <Box mx={3} textAlign="left" color="#8D92A3" mt={2} fontSize={14}>
            Bank Name
          </Box>
          <Box
            mx={3}
            mb={2}
            className="fontSailecBold"
            textAlign="left"
            color="#22242A"
            fontSize={14}
          >
            Bank Of Kathmandu Lumbini LTD.
          </Box>
          <Box pb={1} bgcolor="#F1F3F8" />
          <Box
            mx={3}
            mb={1}
            className="fontSailecBold"
            textAlign="center"
            color="#22242A"
            mt={3}
            fontSize={14}
          >
            नेपालीविवाह निः शुल्क चलाउन प्रोमो कोड हाल्नुहोस् !
          </Box>
          <Box mx={3} textAlign="center" color="#8D92A3" fontSize={14}>
            Do you have a Promo Code?
          </Box>
          <Box textAlign="center" my={2}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonIcon}
              onClick={handleClickOpen}
            >
              Apply
            </Button>
          </Box>
          <Box pb={6} bgcolor="#F1F3F8" />
          <Dialog
            open={dialog}
            onClose={handleClose}
            className="DialogSub"
            aria-labelledby="form-dialog-title"
          >
            { value.loading === true ? <LinearProgress className={classes.bar} color="secondary" /> :''}
            <Box
              borderBottom="1px solid #F1F3F8"
              fontSize={12}
              className="fontSailecMedium"
              textAlign="center"
              letterSpacing={2}
              p={2}
            >
              ENTER THE PROMO CODE
            </Box>
            { value.promoError && 
              <Box 
                color="red" 
                fontSize={12} 
                className="fontSailecRegular"
                p={2}
              >
                {value.promoError}
              </Box>
            }
            <DialogContent className="dialogContentP">
              <Box
                border="1px solid #F1F3F8"
                borderRadius={4}
                px={1}
                bgcolor="#faf9f7"
              >
                <TextField
                  required
                  autoFocus
                  className={classes.textField}
                  id="name"
                  type="text"
                  onChange={handlePromoCodeChange()}
                  placeholder="Enter Promo Code"
                  fullWidth
                />
                
              </Box>
              
            </DialogContent>
            <DialogActions>
              
              <Button
                onClick={handleApply}
                className={classes.buttonIcon}
                variant="contained"
                fullWidth
                size="large"
                disabled={value.loading || value.promoCode.length < 2}
              >
                Continue
              </Button>
              
            </DialogActions>
          </Dialog>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
