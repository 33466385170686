import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SearchBg from "../assets/images/Group618.png";
import { Complexion, Built } from "../_common/data";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef
      }}
      open={open}
      enterTouchDelay={8}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  bgPartner: {
    backgroundImage: `url(${SearchBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  button: {
    fontFamily: "SailecRegular!important",
    width: 100,
    textTransform: "capitalize",
    fontWeight: 300,
    background: "#F1F3F8",
    boxShadow: "none",
    padding: "13px 10px 13px 10px",
    border: "1px solid #dedede"
  },
  margin: {
    marginRight: 12
  },
  bgColor: {
    background: "#FA396F!important",
    color: "#fff"
  },
  buttonWidth: {
    fontFamily: "SailecRegular!important",
    marginTop: 157,
    fontSize: 16,
    padding: "15px 0px",
    display: "block",
    width: "100%",
    textTransform: "none"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
}));

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState({
    height:[10,20],
    built:[],
    complexion:[]
  });

  const {height, built, complexion} = value;
  const value1 = height[0];
  const value2 = height[1];
  const init_feet = 4
  const init_inch = 0

  const feets1 = init_feet + parseInt((value1 - 1) / 12)
  const inches1 = init_inch + parseInt((value1 - 1) % 12)
  const feets2 = init_feet + parseInt((value2 - 1) / 12)
  const inches2 = init_inch + parseInt((value2 - 1) % 12)

  // const inches1 = (((value1 * 3) + 121)* 0.393701) / 12;
  
  // const feets1 = inches1 - parseInt(inches1);
  // const inchesShow1 = feets1 * 12;

  // const inches2 = (((value2* 3) + 121)* 0.393701) / 12;
  // const feets2 = inches2 - parseInt(inches2);
  // const inchesShow2 = feets2 * 12;

  const handleChange = (event, newValue) => {
    setValue({...value, height: newValue});
  };
  const onChangeBuilt = (e, valueSelected) =>{
    const builtID = valueSelected.map((mapValue)=>{
      return mapValue.value
    })
    setValue({...value, built: builtID})
  }
  const onChangeComplexion = (e, valueSelected) =>{
    const complexionID = valueSelected.map((mapValue)=>{
      return mapValue.value
    })
    setValue({...value, complexion: complexionID})
  }
  const onSubmit = () => {
    const page = 4;
    localStorage.setItem("registerSearch", JSON.stringify({
      state: {
        ...sharedData.state,
        ...value,
        page     
      }
    }))

    props.history.push("register-partner-search-1e")
  }
  
  const sharedData =JSON.parse(localStorage.getItem("registerSearch"));
  if(localStorage.getItem('registerSearch') !== null) {
    if(sharedData.state.page < 3) {
      props.history.push("/")
    }
  } else {
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      <Box className={classes.bgPartner}>
        <Container fixed>
          <Box
            fontSize={24}
            pt={3}
            className={classes.fontSailecBold}
            color="#fff"
            textAlign="center"
          >
            Now tell us qualities of the life partner
          </Box>
          <Box
            textAlign="center"
            mt={1}
            pb={1}
            className={classes.fontSailecRegular}
            color="#fff"
            fontSize={14}
          >
            तपाईको जीवनसाथी मा हुनु पर्ने स्वभाव उपलब्ध गराउनुहोस
          </Box>
        </Container>
      </Box>
      <Container fixed>
        <Box
          mt={2}
          mb={1}
          fontSize={10}
          className={classes.fontSailecRegular}
          color="#8D92A3"
        >
          PARTNER'S BODY TYPE SHOULD BE
        </Box>
        <Autocomplete
          multiple
          onChange={onChangeBuilt}
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          options={Built}
          getOptionLabel={Built => Built.label}
          renderOption={(Built, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {Built.label}
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField {...params} color="secondary" variant="outlined" label="Select Body Type" />
          )}
        />
        <Box
          mt={2}
          mb={1}
          fontSize={10}
          className={classes.fontSailecRegular}
          color="#8D92A3"
        >
          PARTNER'S COMPLEXION SHOULD BE
        </Box>
        <Autocomplete
          multiple
          onChange={onChangeComplexion}
          disableCloseOnSelect
          id="checkboxes-tags-demo"
          options={Complexion}
          getOptionLabel={Complexion => Complexion.label}
          renderOption={(Complexion, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {Complexion.label}
            </React.Fragment>
          )}
          style={{ width: "100%" }}
          renderInput={params => (
            <TextField {...params} color="secondary" variant="outlined" label="Select Complexion" />
          )}
        />
        <Grid container>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={10}
              className={classes.fontSailecRegular}
              color="#22242A"
            >
              HEIGHT SGOULD BE
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              mt={2}
              mb={1}
              fontSize={10}
              className={classes.fontSailecRegular}
              textAlign="right"
              color="#FA396F"
            >
              {parseInt(feets1) +
                "'" +
                parseInt(inches1) +
                "'' - " +
                parseInt(feets2) +
                "'" +
                parseInt(inches2) +
                "''"}
            </Box>
          </Grid>
        </Grid>
        <Slider
          value={height}
          className="sliderButtonStyle"
          onChange={handleChange}
          min={1}
          step={1}
          color="secondary"
          max={37}
        />
          <Button
          onClick={onSubmit}
          disabled={built.length === 0 || complexion.length === 0}
            variant="contained"
            color="secondary"
            className={classes.buttonWidth}
          >
            Continue
          </Button>
        <Box mb={3}></Box>
      </Container>
    </div>
  );
}
