import React, { Component } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import clsx from "clsx";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Card1 from '../assets/images/17814393_1383883718357660_1174577180424278390_o@2x.png';
import IconButton from '@material-ui/core/IconButton';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import ShowInterest from '../assets/images/show-interest.svg'
import Modal from '@material-ui/core/Modal';
import SwipeableViews from "react-swipeable-views";
import { withStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import DoubleHeart from '../assets/images/Group 852@2x.png';
import ProfileActivate from '../assets/images/Profile-Active@2x.png';
import DpImage from '../assets/images/Rectangle -8.png';
import Wedding from "../assets/images/Group 956@2x.png";
import Single from "../assets/images/Group 954@2x.png";
import Location from "../assets/images/Group 958@2x.png";
import SpeakIcon from "../assets/images/Group 961@2x.png";
import ReligionIcon from "../assets/images/Notification@2x.png";
import CasteIcon from "../assets/images/Group 966@2x.png";
import Veg from "../assets/images/cssc.png";
import NoDrink from "../assets/images/Group 725sca@2x.png";
import NonSmoke from "../assets/images/Group 725cas@2x.png";
import NonVeg from "../assets/images/NonVeg@2x.png";
import Drink from "../assets/images/Drink@2x.png";
import Smoke from "../assets/images/Smoke@2x.png";
import School from "../assets/images/school@2x.png";
import Wollet from "../assets/images/Group 961@cas2x.png";
import Engineer from "../assets/images/Group 961a@2x.png";
import Typography from "@material-ui/core/Typography";
import ItemsCarousel from "react-items-carousel";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import ChatIcon from '@material-ui/icons/Chat';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { userService } from '../_services/user.service';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import EditIcon from "../assets/images/EditIcon.svg"
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Paper from '@material-ui/core/Paper';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CancelIcon from '@material-ui/icons/Cancel';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  }

const styles = (theme) => ({
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize',
    },
    menuButton:{
        textAlign:"right",
    },
    backButton:{
        textAlign:"center",
    },
    boxDisply:{
        display:'inline-block',
        marginRight:5,
        borderRadius:2,
        textTransform:"capitalize",
    },
    locationIcon:{
        fontSize:18,
        marginTop:-3,
        paddingRight:3,
        color:'#8D92A3',
    },
    imageIcon:{
        fontSize: 30,
        color:'#8D92A3',
    },
    imageIconGrid:{
        textAlign:'center',
        marginTop:27,
    },
    textAlign:{
        textAlign:'center',
    },
    intrestBorder:{
        borderRadius:4,
        border:'1px solid #ededed',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {   
        width:"80%",
        backgroundColor:"#fff",
        boxShadow: 5,
        padding: "2px 4px 3px",
        borderRadius:5,
      },
      buttonModal:{
        width:"100%",
        // margin:"2px 0px",
        textTransform:'capitalize',
    },
    BoxInterest:{
        padding:"13px!important",
        height:"100%",
    },
    BoxInterestpadding:{
        padding:5,
    },
    imgTransform1:{
        transform:"rotate(-25deg)",
        position: "relative",
        borderRadius:4,
        zIndex:1000,
        float:"right"
    },
    imgTransform2:{
        transform:"rotate(5deg)",
        borderRadius:4,
        height:106,
        width:106,
        borderRadius:5,
        objectFit:"cover",
    },
    AlignButton:{
        textAlign:'center',
        transform: "translate(0px, -55px)",
        zIndex: 10000,
    },
    heartIconButton:{
        
        margin:"7px 3px",
    },
    buttonLike:{
        textTransform:"none",
        fontFamily:"SailecRegular!important",
    },
    
    profileTop:{
        position:"fixed!important",
        top:0,
        backgroundColor:"#fff",
        zIndex:100000,
    },
    root: {
        width: "100%"
      },
      textTransform: {
        textTransform: "capitalize",
        fontSize: 14,
        fontFamily: "SailecMedium!important"
      },
      TabBg: {
        backgroundColor: "transparent!important",
        boxShadow: "none"
        //   borderBottom:"6px solid #e3e3e3",
        // paddingBottom:" 10px",
      },
      my: {
        padding: "10px 0px 0px 0px!important"
      },
      mImage: {
        marginTop: 10
      },
      socialImage: {
        marginTop: 15
      },
      SpeakIcon: {
        marginTop: 4
      },
      casteIcon: {
        marginTop: 17
      },
      EngineerIcon: {
        marginTop: 10
      },
      textRight: {
        textAlign: "right"
      },
      buttonIcon: {
        padding: "0px",
        minWidth: 20
      },
      divider: {
        height: "1px",
        width: "100%",
        backgroundColor: "#F1F3F8",
        margin:"10px 0px",
      },
      gridpassing: {
        padding: "0px 3px"
      },
      title: {
        fontSize: 14
      },
      buttonSecondry: {
        fontSize: 12,
        textTransform: "capitalize",
        borderColor: "#f2f2f2"
      },
      icon: {
        fontSize: 14,
        marginRight: 5
      },
      locationIcon: {
        fontSize: 16,
        marginRight: 3
      },
      womenIcon: {
        fontSize: 13,
        marginLeft: 3,
        marginRight: 5
      },
      content: {
        padding: 9
      },
      cancelButton: {
        position: "absolute",
        top: 6,
        right: 6,
        color: "#fff",
        background: "rgba(34,34,34,0.5)",
        borderRadius: "50%",
        fontSize: 20,
        padding: 3
      },
      btnSlider: {
        textTransform: "capitalize"
      },
      dp:{
        borderRadius:"50%",
        width:'80%',
    },
    cardPaddings:{
        paddings:8,
    },
    fontProfile:{
        fontFamily:"SailecRegular!important",
        color:'#22242A',
    },
    button:{
        fontSize:12,
        height:32,
        color:'#8D92A3',
        fontFamily:"SailecRegular!important",
        textTransform:"capitalize",
        marginRight:8,
        marginLeft:2,
    },
    icon:{
      color:"#f50057",
        fontSize:16,
        marginLeft: -5,
        marginRight: 5,
    },
    iconLike:{
      color:"#8D92A3",
        fontSize:16,
        marginLeft: -5,
        marginRight: 5,
    },
    iconChat:{
      color:"#8D92A3",
        fontSize:16,
        marginLeft: -5,
        marginRight: 5,
    },
    icon2:{
        color:'#8D92A3',
        fontSize:26,
        marginTop:5,
        borderColor:'red',
    },
      media: {
        height: 0,
        paddingTop: '100%',
        borderRadius:4,
      },
      avatar:{
        width:43,
        height:43,
        fontFamily: "SailecBold",
        marginRight:0,
        
        },
        CardActions:{
          padding:"8px 0px",
        },
        profileLink:{
          color:"#f50057",
          fontFamily:"SailecBold!important",
          fontSize:14,
        },
        profileImg:{
        height: 360,
    objectFit: "cover",
    marginTop:48,
        },
        Avatar:{
          height: 360,
    width: "100%",
    borderRadius: 0,
    fontSize: 72,
    background: "#5CB85C",
        },
        userImages:{
          height:106,
          width: 106,
          borderRadius:"5px!important",
          objectFit:"cover"
        },
        AvatarTogether:{
          color:"#fafafa",
          backgroundColor: "#5CB85C",
          height: 106,
          width: "106px!important",
          objectFit:"cover",
          fontSize:36,
          fontFamily: "SailecBold",
          borderRadius: 5,
        },
        FabShowInterest:{
          background:"#FED7E2!important",
        },
        favoriteIconSize:{
          fontSize:34,
        },
        btnEditNav:{
          marginTop: 6,
          textTransform: "capitalize",
        },
        btnEditBody:{
          float: "right",
        marginTop: -8,
          textTransform: "capitalize",
        },
        btnEditNavImg:{
          marginRight:5,
        },
        positionR:{
          position:"relative",
        },
        addPhoto:{
          position: "absolute",
          right: -13,
          top: 0,
        },
        sliderStyle: {
          position:"fixed",
          width:"100%",
          top:0,
          height:"100%",
          zIndex:100000,
          backgroundColor:"#000",
          display:"flex",
          alignItems:"center",
        },
        header: {
          display: 'flex',
          alignItems: 'center',
          height: 50,
          paddingLeft: theme.spacing(4),
          backgroundColor: theme.palette.background.default,
        },
        img: {
          display: 'block',
          maxWidth: "100%",
          overflow: 'hidden',
          width: '100%',
          
        },
        disBlock:{
          display:"block",
        },
        disNone:{
          display:"none",
        },
        btnCancelIcon:{
          position: "absolute",
    zIndex: 1000,
    top: 0,
    right: 0,
        }
})
const ActivityTab=(props)=> {
 const [state,setState] = React.useState({
    alignments:'',
 });
    const handleAlignment = (event, newAlignment) => {
      setState({...state,alignments:newAlignment});
    };
    
    const { alignments } = state;
        return (
            <div className="Containerbg">
           
              <Grid container className="rowPadding">
                
              <Grid item xs={2}>
                    {/* <img src[0].img} className={props.classes.dp}/> */}
                </Grid>
                <Grid item xs={10}>
          <Box color="#000" fontSize={14} className={props.classes.fontProfile}><Link to="" className="profileColor">title</Link> is looking for Someone whose height is between 4' 5'' (134 cm) 
to 6' 10'' (207 cm). </Box>
<Box color="#8D92A3" mb={1} fontSize={12} className="fontSailecRegular">1 day ago</Box> 
                </Grid>
                <Grid item xs={10}>
                  <Box mt={1}>
                  <ToggleButtonGroup
            value={alignments}
            exclusive
            color="secondary"
            onChange={handleAlignment}
            aria-label="text alignment"
          > <ToggleButton className={props.classes.button}  value="left" aria-label="left aligned">
          <FavoriteIcon className={alignments=== "left" ? props.classes.icon : props.classes.iconLike}/> Like
        </ToggleButton>
        <Button className={props.classes.button} className="fontSailecRegular" variant="outlined"><ChatIcon className={props.classes.iconChat}/>2 Comments</Button></ToggleButtonGroup>
                   
                    </Box></Grid>
                <Grid item xs={2}>
                <Box textAlign="right">
             <IconButton aria-label="add to favorites">
          <MoreVertIcon />
        </IconButton>
            </Box>
                </Grid>
              </Grid>
              <Grid container className="rowPadding mt-10">
                
              <Grid item xs={12}>
                <Card className="profileCard">
      <CardHeader
      className={props.classes.cardHeaderProfile}
        avatar={
          <Avatar aria-label="recipe" className={props.classes.avatar}>
            {/* <img src={profiles[0].img} width="100%" /> */}
          </Avatar>
        }
       
        title={<Box className={props.classes.fontProfile}><Link to="/" className="profileColor">title</Link> added a new photo</Box>}
        subheader="September 14, 2016"
      />
      <Box fontSize={14} mb={1} color="#4D4D4D" className="fontSailecRegular">
      Wedding photography 📷 
      </Box>
      <CardMedia
        className={props.classes.media}
        // image={profiles[0].img}
        title="Paella dish"
      />
     
      <CardActions disableSpacing className={props.classes.CardActions}>
        <Grid container>
        <Grid item xs={10}>
                  <Box mt={1}>
                  <ToggleButtonGroup
            value={alignments}
            exclusive
            color="secondary"
            onChange={handleAlignment}
            aria-label="text alignment"
          > <ToggleButton  className={props.classes.button} value="left" aria-label="left aligned">
          <FavoriteIcon className={alignments=== "left" ? props.classes.icon : props.classes.iconLike}/> Like
        </ToggleButton>
        <Button className={props.classes.button} className="fontSailecRegular" variant="outlined"><ChatIcon className={props.classes.iconChat}/>2 Comments</Button></ToggleButtonGroup>
                   
                    </Box></Grid>
                <Grid item xs={2}>
                <Box textAlign="right"><IconButton aria-label="add to favorites">
          <MoreVertIcon />
        </IconButton></Box>
                </Grid></Grid>
      </CardActions>
    
    </Card></Grid>
                </Grid>


                   
                          </div>
      )}
const Profile=(props)=>{
    const [state,setState] = React.useState({
        open:false,
        interest:false,
        sliderOpen:false,
    })
   const handleOpen = () => {
        setState({...state,open:true});
      };
    
     const handleClose = () => {
        setState({...state,open:false});
      }
      const onClickShowInterest =()=>{
        setState({...state,interest:true,})
      }
      const onClickCancelInterest =()=>{
        setState({...state,interest:false,})
      }
      const onClickInterested=(profileData)=>{
        console.log(profileData);
        props.props.history.push({
          pathname:"/interested-list",
          search: profileData.username
        })
      }
      const onClickInterestedIn=(profileData)=>{
        console.log(profileData);
        props.props.history.push({
          pathname:"/interested-in-list",
          search: profileData.username
        })
      }
      
      const theme = useTheme();
      const [activeStep, setActiveStep] = React.useState(0);
      
    
      const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleStepChange = (step) => {
        setActiveStep(step);
      };
      const onClickImage =()=>{
        setState({...state,sliderOpen:true,})
      }
    const  onClickIconCancel =()=>{
        setState({...state,sliderOpen:false,})
      }
      const {open}= state;
      const userNameSearch = props.props.location.search.slice(1);
    const userData =JSON.parse(localStorage.getItem('login'));
    
  const {username} = userData.details;
  
    return (
        <div>
                <Grid container className={props.classes.profileTop} >
                    
                <Link to="/discover"><Grid item className={props.classes.backButton} xs={2}>
                   <IconButton aria-label="delete">
                    <ArrowBackIcon /> 
</IconButton>
</Grid></Link>
                    <Grid item xs={8}>
                    <Box fontSize={20} mt={1} textAlign="center" className="fontSailecBold">{props.profileData.length === 0 ? <Skeleton animation="wave" /> : props.profileData.name}</Box>
                    </Grid>
                    <Grid item className={props.classes.menuButton} xs={2}>
                  
                    {userNameSearch === username ? <Link to="my-profile-info-edit"><Button className={props.classes.btnEditNav} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> </Link>:
                    
                    <IconButton aria-label="delete" onClick={handleOpen}>
                    <MoreVertIcon />
</IconButton>
}
                    </Grid>
                
                </Grid>
                <Box className={props.classes.imgmargin} />
                {props.profileData.profile_pic === undefined ? <Skeleton variant="rect" animation="wave" width="100%" height={360} /> :"" }
                {
                  props.profileData.profile_pic === null ? <Avatar className={props.classes.Avatar}>{props.profileData.no_profile_pic}</Avatar> :   <img src={props.profileData.length === 0 ? "" : props.profileData.profile_pic} width="100%" className={props.classes.profileImg} alt="profile picture"/>
                }
          
   
   <Box bgcolor="#fff" borderRadius={4} >
     <Box px={2} pb={0} pt={2}>
       <Grid container>
           <Grid item xs={9}>
    <Box fontSize={20} className="fontSailecBold">{props.profileData.length === 0 ? <Skeleton animation="wave" /> : props.profileData.name}</Box>
   {props.profileData.length === 0 ? <Skeleton animation="wave" /> :<Box>
   <Box px={1} fontSize={12} bgcolor="#5BC0DE" color="#fff" className={props.classes.boxDisply}>{`${props.profileData.age} Yrs`}</Box>
    <Box px={1} fontSize={12} bgcolor="rgba(191, 74, 63, 0.13)" color="#f50057" className={props.classes.boxDisply}>{props.profileData.marital_status}</Box>
    <Box px={1} fontSize={12} bgcolor="rgba(76, 191, 63, 0.13)" color="#5CB85C" className={props.classes.boxDisply}>{props.profileData.religion}</Box></Box>}
    {props.profileData.length === 0 ? <Skeleton animation="wave" /> :
    <Box className="fontSailecRegular" mt={1} className={props.classes.boxMT} fontSize={14} color="#8D92A3"><LocationOnOutlinedIcon className={props.classes.locationIcon}/>{props.profileData.location}</Box>
    }

   
           </Grid>
           <Grid item className={props.classes.imageIconGrid} xs={3}>
           <Badge color="secondary" badgeContent={props.profileData.photos === undefined ? "" : props.profileData.photos.length} > <PanoramaOutlinedIcon className={props.classes.imageIcon}/></Badge>
           </Grid>
       </Grid>
       </Box>
       <Box borderTop="1px solid #F1F3F8" my={1}/>
       <Box  px={2}>
       <Grid container spacing={1}>
           <Grid item xs={6} onClick={onClickInterested.bind(this,props.profileData)}>
           <Grid container className={props.classes.intrestBorder}>
               <Grid item xs={4}>
  <Box borderRadius={3} bgcolor="rgba(191, 74, 63, 0.13)" textAlign="center" className={clsx(props.classes.BoxInterest,"fontSailecBold")} fontSize={14} color="#f50057">{props.profileData.followers}</Box>
               </Grid>
               <Grid item xs={8}><Box fontSize={12}  className={clsx(props.classes.BoxInterestpadding,"fontSailecRegular")}>Interested In{` ${props.profileData.name}`} </Box></Grid>
           </Grid>
           </Grid>
           <Grid item xs={6} onClick={onClickInterestedIn.bind(this,props.profileData)}>
           <Grid container className={props.classes.intrestBorder}>
               <Grid item xs={4}>
                   <Box borderRadius={3} bgcolor="rgba(27, 212, 212, 0.07)" textAlign="center" fontSize={14} className={clsx(props.classes.BoxInterest,"fontSailecBold")} color="#5BC0DE">{props.profileData.following}</Box>
               </Grid>
               <Grid item xs={8}><Box fontSize={12}  className={clsx(props.classes.BoxInterestpadding,"fontSailecRegular")}>
               {props.profileData.name} Interested In</Box></Grid>
           </Grid>
           </Grid>
           </Grid>
           <Box mt={2} />
           {userNameSearch === username ?
           ""
           :
           <Grid container>
                        <Grid className={props.classes.textAlign} item xs={4}>
                          {
                            state.interest === false ?   <Fab aria-label="favorite" onClick={onClickShowInterest} className={props.classes.FabShowInterest}>
                            <img src={ShowInterest} width="50%"/>
          </Fab> :
            <Fab aria-label="favorite" color="secondary" onClick={onClickCancelInterest} >
            <FavoriteIcon className={props.classes.favoriteIconSize} />
</Fab>

                          }
                      
                        </Grid>
                        <Grid className={props.classes.textAlign} item xs={4}>
                        <Fab color="primary" aria-label="add" className={props.classes.fab}>
                        <i className="fas fa-2x fa-comment-dots"></i>
      </Fab>
                        </Grid>
                        <Grid className={props.classes.textAlign} item xs={4}>
                        <Fab color="default" id="defalutButton" aria-label="add" className={props.classes.fab}>
                        <i className="fas fa-2x fa-heart-broken"></i>
      </Fab>
                        </Grid>
                        
                        </Grid>
}
                        <Divider className={props.classes.divider} />
                     
   <Box fontSize={14} mb={1} className="fontSailecMedium" color="#707070">Photos</Box>
       <Grid container spacing={1}>
         {
           props.profileData.photos !== undefined 
           ?
           props.profileData.photos.length === 0 && props.profileData.profile_pic === null ?
           <Box fontSize={14} mt={1} pl={0.5} mb={3} className="fontSailecLight" color="#707070">No Photos</Box> :
            props.profileData.photos.length === 0 
            ? 
           <Grid item xs={4} onClick={onClickImage} className={props.classes.positionR}> 
          {userNameSearch === username ? <Button className={props.classes.addPhoto} color="secondary"><CameraAltIcon /></Button> :""}
           <img src={props.profileData.profile_pic} className={props.classes.userImages} alt="user image" width="100%" />
      </Grid> 
      :
      props.profileData.photos.map((photos)=>(
        <Grid item xs={4} onClick={onClickImage} className={props.classes.positionR}> 
        {userNameSearch === username ? <Button className={props.classes.addPhoto} color="secondary"><CameraAltIcon /></Button> :""}
        <img src={photos} className={props.classes.userImages} alt="user image" width="100%" />
   </Grid> 
      ))
     
 : ""


         }
           
       </Grid>
       <Box fontSize={14} mb={1} mt={2} className="fontSailecMedium" color="#707070">About {userNameSearch === username ? <Link to="about-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button></Link> : ""}</Box>
        <Box fontSize={14} mt={1} mb={3} className="fontSailecLight" color="#707070">{props.profileData.about_me} </Box>
        {userNameSearch === username ? "": 
        <Box>
       <Grid container>
           <Grid item xs={2} />
           <Grid item xs={4}> 
           <Box className={props.classes.imgTransform1}>
           {props.userData.details.profile_pic === undefined ? "" :
           props.userData.details.profile_pic.length > 3 ? 
           <img className={props.classes.AvatarTogether} alt="relation image 2" src={props.userData.details.profile_pic} width="100%" />
           :
        <Avatar className={props.classes.AvatarTogether}>{props.userData.details.profile_pic}</Avatar>
}
        </Box>
                {/* <img className={props.classes.imgTransform1} alt="relation image 1" src={props.profileData.profile_pic} width="100%" /> */}
           </Grid>
           <Grid item xs={4}> 
           {props.profileData.profile_pic === undefined ? "" :
           props.profileData.profile_pic === null ? 
           <Avatar className={props.classes.AvatarTogether}>{props.profileData.no_profile_pic}</Avatar> :
           <img className={props.classes.imgTransform2} alt="relation image 2" src={props.profileData.profile_pic} width="100%" />
           
        
}
                {/* <img className={props.classes.imgTransform2} alt="relation image 2" src={props.profileData.profile_pic} width="100%" /> */}
           </Grid>
           <Grid item xs={2} />
           <Grid item xs={12} className={props.classes.AlignButton}>
           <Fab color="secondary" aria-label="edit">
<img src={DoubleHeart} width={30} />
</Fab>
           </Grid>
           
       </Grid>

        
       <Box fontSize={16} textAlign="center" mb={3} className="fontSailecMedium" color="#22242A"> See how you look together </Box> 
      
       {state.interest === false ? 
                             <Button variant="outlined" onClick={onClickShowInterest} className={props.classes.buttonLike} fullWidth color="secondary"><FavoriteBorderIcon className={props.classes.heartIconButton} /> Show Interest</Button>
                          :
                          <Button variant="contained" onClick={onClickCancelInterest} className={props.classes.buttonLike} fullWidth color="secondary"><FavoriteIcon className={props.classes.heartIconButton}/>You are interested</Button>
                          }  
                          </Box>      
                        }    
    </Box>
    </Box>
    <Box className={state.sliderOpen === true ? props.classes.disBlock : props.classes.disNone}>
      <Box className={props.classes.sliderStyle}>
    <div className={clsx(props.classes.rootSlider,"sliderWidth")}>
    <IconButton onClick={onClickIconCancel} className={props.classes.btnCancelIcon}><CancelIcon color="secondary"/></IconButton>
      {/* <Paper square elevation={0} className={props.classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        { props.profileData.photos === undefined ? "" : 
        props.profileData.photos.length === 0 ?  
          <div key={props.profileData.profile_pic}>

              <img className={props.classes.img} width="100%" src={props.profileData.profile_pic} alt={props.profileData.profile_pic} />
            
          </div>
         :
        props.profileData.photos.map((step, index) => (
          <div key={step.step}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={props.classes.img} width="100%" src={step} alt={step} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
      className="stepperBg"
        steps={ props.profileData.photos === undefined ? 0 : props.profileData.photos.length 
        }
        position="static"
        variant="dots"
        activeStep={activeStep}
        
      />
    </div>
    </Box>
    </Box>
    <Grid container>
       <Grid item xs={12}>
               <ProfileTabs classes={props.classes} userNameSearch={userNameSearch}  username={username} onCancelClick={props.onCancelClick} props={props.props} profileData={props.profileData} profiles={props.profiles} />
           </Grid></Grid> 

    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={props.classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div className={props.classes.paper}>
      <Button className={props.classes.buttonModal} className="fontSailecMedium" >Profile details</Button>
      <Button className={props.classes.buttonModal} className="fontSailecMedium" >Report</Button>
      <Button className={props.classes.buttonModal} className="fontSailecMedium" >Block</Button>
      <Button className={props.classes.buttonModal} className="fontSailecMedium" >Copy URL</Button>
      <Button className={props.classes.buttonModal} className="fontSailecMedium" >Share This URL</Button>
      </div>
    </Fade>
  </Modal>
        </div>
    )
}

const ProfileTabs=(props)=> {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
console.log( "test3",props.userNameSearch , props.username )
  return (
    <div className={props.classes.root}>
      <Container fixed>
        <AppBar position="static" className={props.classes.TabBg}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              className={props.classes.textTransform}
              label="Info"
              {...a11yProps(0)}
            />
            <Tab
              className={props.classes.textTransform}
              label="Preferences"
              {...a11yProps(1)}
            />
            {/* <Tab
              className={props.classes.textTransform}
              label="Activity"
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </AppBar>
      </Container>
      <Box pt={1} bgcolor="#ebebeb" />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          className="tabpaddings"
          value={value}
          index={0}
          dir={theme.direction}
        >
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              About {props.profileData.first_name} {props.userNameSearch === props.username ? <Link to="my-profile-info-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button></Link> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img src={Wedding} alt="wedding" width="30px" />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
{props.profileData.length === 0 ? <Skeleton animation="wave" /> : `Target Wedding ${props.profileData.target_wedding}`}
                  
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Single}
                  alt="single"
                  className={props.classes.mImage}
                  width="30px"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.length === 0 ? <Skeleton animation="wave" /> : `${props.profileData.marital_status}, ${props.profileData.age} Yrs`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Location}
                  alt="location"
                  className={props.classes.mImage}
                  width="30px"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.length === 0 ? <Skeleton animation="wave" /> : `Lives in ${props.profileData.location}`}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Physical Appearance {props.userNameSearch === props.username ? <Link to="physical-appearance-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> </Link>: ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid container>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  color="#fff"
                  py={2}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  bgcolor="#5BC0DE"
                  fontSize={16}
                >
                  {props.profileData.length === 0 ? '' : props.profileData.height.substring(0, 7)}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Height
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  bgcolor="#5CB85C"
                  mt={2}
                  textAlign="center"
                  borderRadius={4}
                  color="#fff"
                  py={2}
                  className="fontSailecBold mxTab"
                  fontSize={16}
                >
                  {props.profileData.built}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Body Look
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  bgcolor="#f50057"
                  borderRadius={4}
                  color="#fff"
                  py={2}
                  fontSize={16}
                  className="fontSailecBold mxTab"
                >
                  {props.profileData.complexion}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Complexion
                </Box>
              </Grid>
            </Grid>
            <Box mb={2} />
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Social Appearance {props.userNameSearch === props.username ? <Link to="social-appearance-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> </Link>: ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img
                  src={SpeakIcon}
                  className={props.classes.SpeakIcon}
                  width="30px"
                  alt="Speak"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {`Speaks ${props.profileData.native_language}`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={ReligionIcon}
                  className={props.classes.socialImage}
                  width="30px"
                  alt="Religion"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.religion}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={CasteIcon}
                  className={props.classes.casteIcon}
                  width="30px"
                  alt="Caste"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.caste}
                </Box>
                
              </Grid>
            </Grid>
            <Box pb={2}/>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Lifestyle {props.userNameSearch === props.username ? <Link to="lifestyle-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button></Link> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid container>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  {props.profileData.diet === "Non-Veg" ? <img src={NonVeg} width="50px" alt="NonVeg" /> :
                  <img src={Veg} width="50px" alt="Veg" /> }                  
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  {props.profileData.diet}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  {props.profileData.smoke === "No" ? <img src={NonSmoke} width="50px" alt="NonSmoke" /> : <img src={Smoke} width="50px" alt="Smoke" />}
                  
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  {props.profileData.smoke === "No" ? "Non Smoke" : "Smoke"}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  {props.profileData.drink === "No" ? <img src={NoDrink} width="50px" alt="No Drink" /> :<img src={Drink} width="50px" alt="Drink" />}
                  
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  {props.profileData.drink === "No" ? "No Drink" : "Drink"}
                </Box>
              </Grid>
            </Grid>
            <Box mb={2} />
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Profession Info {props.userNameSearch === props.username ? <Link to="profession-info-edit"><Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button></Link> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img
                  src={School}
                  className={props.classes.SpeakIcon}
                  alt="school"
                  width="30px"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.education}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Wollet}
                  className={props.classes.socialImage}
                  width="30px"
                  alt="wollet"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {`Earns Upto ${props.profileData.salary_nation} ${props.profileData.salary} annually`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Engineer}
                  className={props.classes.EngineerIcon}
                  width="30px"
                  alt="engineer"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.profession} 
                </Box>
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
        <TabPanel
          className="tabpaddings"
          value={value}
          index={1}
          dir={theme.direction}
        >
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              About Partner {props.userNameSearch === props.username ? <Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img src={Wedding} width="30px" alt="wedding" />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.length === 0 ? <Skeleton animation="wave" /> : `Target Wedding ${props.profileData.target_wedding}`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Single}
                  className={props.classes.mImage}
                  width="30px"
                  alt="single"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                {props.profileData.length === 0 ? <Skeleton animation="wave" /> : `${props.profileData.partner_info.marital_status}`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={ProfileActivate}
                  className={props.classes.mImage}
                  width="30px"
                  alt="single"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                {props.profileData.length === 0 ? <Skeleton animation="wave" /> : ` ${props.profileData.partner_info.sex}, ${props.profileData.partner_info.age_min}-${props.profileData.partner_info.age_max} Yrs`}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Location}
                  className={props.classes.mImage}
                  width="30px"
                  alt="location"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                    {props.profileData.length === 0 ? <Skeleton animation="wave" /> : `Lives in ${props.profileData.partner_info.location}`}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Physical Appearance {props.userNameSearch === props.username ? <Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid container>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  color="#fff"
                  py={2}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  bgcolor="#5BC0DE"
                  fontSize={16}
                >
                  {props.profileData.length === 0 ? '' : `${props.profileData.partner_info.height_from.substring(0, 7)}- ${props.profileData.partner_info.height_to.substring(0, 7)}`}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                {props.profileData.length === 0 ? '' : `${props.profileData.partner_info.height_from.substring(0, 7)}- ${props.profileData.partner_info.height_to.substring(0, 7)}`}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  bgcolor="#5CB85C"
                  mt={2}
                  textAlign="center"
                  borderRadius={4}
                  color="#fff"
                  py={2}
                  className="fontSailecBold mxTab"
                  fontSize={16}
                >
                  {props.profileData.length === 0 ? '' : props.profileData.partner_info.built}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Body Look 
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  bgcolor="#f50057"
                  borderRadius={4}
                  color="#fff"
                  py={2}
                  fontSize={16}
                  className="fontSailecBold mxTab"
                >
                  {props.profileData.length === 0 ? '' : props.profileData.partner_info.complexion}
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Complexion
                </Box>
              </Grid>
            </Grid>
            <Box mb={2} />
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Social Appearance {props.userNameSearch === props.username ? <Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img
                  src={SpeakIcon}
                  className={props.classes.SpeakIcon}
                  width="30px"
                  alt="speak"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  Speaks Nepali
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={ReligionIcon}
                  className={props.classes.socialImage}
                  width="30px"
                  alt="religion"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.length === 0 ? '' : props.profileData.partner_info.religion}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={CasteIcon}
                  className={props.classes.casteIcon}
                  width="30px"
                  alt="caste"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                {props.profileData.length === 0 ? '' : props.profileData.partner_info.caste}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Lifestyle {props.userNameSearch === props.username ? <Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                {props.profileData.diet === "Non-Veg" ? <img src={NonVeg} width="30px" className={props.classes.SpeakIcon} alt="NonVeg" /> :
                  <img src={Veg} width="30px" className={props.classes.SpeakIcon} alt="Veg" /> } 
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.diet === "Non-Veg" ? "Non Veg" : "Veg"}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
              {props.profileData.smoke === "No" ? <img src={NonSmoke} width="30px" className={props.classes.socialImage} alt="NonSmoke" /> : <img src={Smoke} width="30px" className={props.classes.socialImage} alt="Smoke" />}
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2.5}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  {props.profileData.smoke === "No" ? "Non Smoke" : "Smoke"}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
              {props.profileData.drink === "No" ? <img src={NoDrink} width="30px" className={props.classes.casteIcon} alt="No Drink" /> :<img src={Drink} width="30px" className={props.classes.casteIcon} alt="Drink" />}
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={3}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                {props.profileData.drink === "No" ? "No Drink" : "Drink"}
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          {/* <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Lifestyle
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid container>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  <img src={Veg} width="50px" alt="veg" />
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Non.Veg
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  <img src={Smoke} width="50px" alt="smoke" />
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Smoke
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  textAlign="center"
                  mt={2}
                  py={1}
                  borderRadius={4}
                  className="fontSailecBold mxTab"
                  border="1px solid #e6e6e6"
                  fontSize={16}
                >
                  <img src={Drink} width="50px" alt="drink" />
                </Box>
                <Box
                  textAlign="center"
                  fontSize={12}
                  className="fontSailecRegular"
                  mt={1}
                  p={0}
                >
                  Drink
                </Box>
              </Grid>
            </Grid>
            <Box mb={2} />
          </Container>
          <Box pt={1} bgcolor="#ebebeb" /> */}
          <Container fixed>
            <Box
              fontSize={14}
              my={2}
              color="#707070"
              className="fontSailecMedium mlTab"
            >
              Profession Info {props.userNameSearch === props.username ? <Button className={props.classes.btnEditBody} color="secondary"><img src={EditIcon} className={props.classes.btnEditNavImg} width="16px"/> Edit</Button> : ""}
            </Box>
          </Container>
          <Divider />
          <Container fixed>
            <Grid className={props.classes.my} container>
              <Grid item xs={2}>
                <img
                  src={School}
                  className={props.classes.SpeakIcon}
                  width="30px"
                  alt="school"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={1}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  High School Certified in computer IT
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Wollet}
                  className={props.classes.socialImage}
                  width="30px"
                  alt="wollet"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  Earns Upto NRP 5Lakh annually
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={Engineer}
                  className={props.classes.EngineerIcon}
                  width="30px"
                  alt="engineer"
                />
              </Grid>
              <Grid item xs={10}>
                <Box
                  fontSize={14}
                  mt={2}
                  mb={2}
                  color="#22242A"
                  className="fontSailecRegular"
                >
                  Engineer
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box pt={1} bgcolor="#ebebeb" />
          <Container fixed>
            {" "}
            <Box mt={1} />
            {/* <ProfileSlider classes={props.classes} onCancelClick={props.onCancelClick} props={props.props} profiles={props.profiles}/> <Box mt={2} /> */}
          </Container>
        </TabPanel>
        <TabPanel className="tabpaddings" value={value} index={2}>
          <ActivityTab classes={props.classes}/>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
const ProfileSlider=(props)=> {
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);
    const [activeItemIndex2, setActiveItemIndex2] = React.useState(0);
    const chevronWidth = 0;
    
    const onClickHandler = (profile, e) => {
  
      props.history.push({
        pathname: "/profile",
        state: {
          profileDetails: profile
        }
      });
    };
  
    const onDeleteClick = (tile, dispatch) => {
      dispatch({ type: "DELETE_PROFILE", payload: tile.id });
    };
    const onDeleteClickIntreset = (tile, dispatch) => {
      dispatch({ type: "DELETE_PROFILE", payload: tile.id });
    };
    const onClickAction = (value) => {
      props.props.history.push({
        pathname:"/profile",
        search: value.username
      })
    }
    return (
      <div>
    
              <div className={{ padding: `0 ${chevronWidth}px` }}>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Box
                      className="fontSailecMedium"
                      mt={1}
                      fontSize={14}
                      color="#707070"
                    >
                      People similar to Shreega
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} className={props.classes.textRight}>
                    <Button
                      color="secondary"
                      href="/register-question5"
                      className="fontSailecRegular"
                      className={props.classes.btnSlider}
                    >
                      See All
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={2} />
                <div>
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={2}
                    gutter={20}
                    leftChevron={
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        size="small"
                      >
                        <ArrowBackIosIcon fontSize="inherit" />
                      </IconButton>
                    }
                    rightChevron={
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        size="small"
                      >
                        <ArrowForwardIosIcon fontSize="inherit" />
                      </IconButton>
                    }
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >
                      {props.profiles === "" || props.profiles === undefined ? "" : props.profiles.map(tile => (
                        
                        <Card>
                          <CardActionArea>
                            <CloseIcon
                              onClick={props.onCancelClick.bind(this, tile)}
                              className={props.classes.cancelButton}
                            />
                            <Box onClick={onClickAction.bind(this, tile)}>
                              <CardMedia
                                component="img"
                                alt={tile.name.slice(0,14)}
                                height="140"
                                image={DpImage}
                                title={tile.name.slice(0,14)}
                              />
                              <CardContent className={props.classes.content}>
                                <Typography
                                  className="fontSailecMedium"
                                  className={props.classes.title}
                                >
                                  {tile.name.slice(0,14)}
                                  
                                </Typography>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <i
                                    className={clsx(props.classes.womenIcon,'fas fa-venus')}></i>{" "}
                                  {`${tile.age} Yrs, ${tile.sex}`}
                                </Box>
                                <Box
                                  fontSize={12}
                                  className="fontSailecRegular"
                                  color="#8D92A3"
                                >
                                  <LocationOnOutlinedIcon
                                    className={props.classes.locationIcon}
                                  />
                                  {`${tile.location.slice(0,11)}...`}
                                </Box>
                              </CardContent>
                            </Box>
                          </CardActionArea>

                          <CardActions>
                            <Button
                              fullWidth
                              className={props.classes.buttonSecondry}
                              size="small"
                              variant="outlined"
                              color="secondary"
                            >
                              <FavoriteIcon className={props.classes.icon} /> Show Interest
                            </Button>
                          </CardActions>
                        </Card>
                      ))}
                    {/* ))} */}
                  </ItemsCarousel>
                </div>
              </div>
      </div>
    );
  }

class Main extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
    constructor(props){
        super(props);
        this.state={
         profileData:'',
         navTab:0,
         profiles:'',
        }
    };
  
componentDidMount=()=>{
  const username = this.props.location.search.slice(1);
  
  const userData =JSON.parse(localStorage.getItem('login'));
  const {token} = userData.details;
  if(localStorage.getItem('login') === null){
    this.props.history.push("/login")
  }
  else{
  
  userService
      .profileData(username,token)
      .then(res=> {
        this.setState({profileData:res.user})
      });
    //  userService
    //  .user(token)
    //  .then(res=> {
    //    if(res.status === 'success'){
    //      this.setState({profiles:res.users})
    //    }
    //  })
    }
}   
onCancelClick = (value)=>{
  const {profiles}=this.state
     this.setState({profiles:profiles.filter(profile=>
     value.username !== profile.username
      )})
    }

    render() {
      const userData =JSON.parse(localStorage.getItem('login'));
      const {profileData,profiles} = this.state;
      const { classes } = this.props;
       console.log(profileData,userData)
        return (
            <>
            <Profile classes={classes} props={this.props} onCancelClick={this.onCancelClick} profileData={profileData} userData={userData}  profiles={profiles} />
            </>
        )
       
    }
}
export default withStyles(styles, { withTheme: true })(Main);