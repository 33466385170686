import React, { Component } from "react";
import Picker from "react-mobile-picker";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
const optionGroups = {
  days : [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ],
  months : [
  "01","02","03","04","05","06","07","08","09",10,11,12,
  ],
  years: [
    1960,
    1961,
    1962,
    1963,
    1964,
    1965,
    1966,
    1967,
    1968,
    1969,
    1970,
    1971,
    1972,
    1973,
    1974,
    1975,
    1976,
    1977,
    1978,
    1979,
    1980,
    1981,
    1982,
    1983,
    1984,
    1985,
    1986,
    1987,
    1988,
    1989,
    1990,
    1991,
    1992,
    1993,
    1994,
    1995,
    1996,
    1997,
    1998,
    1999,
    2000,
    2001,
    2002,
    2003,
    2004,
    2005
  ]
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        days: "05",
        months: "03",
        years: 1985
      },
      
      date: "Select date"
    };
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      },
    }),
      () => {
        const { valueGroups } = this.state;
        const dateSelect = `${valueGroups.months}/${valueGroups.days}/${valueGroups.years}`;

      
        this.props.date(dateSelect)
      }
    );
   
  };

  render() {
    const {  valueGroups } = this.state;
    return (
      <div>
        <Container fixed>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={1} />
              <Box fontSize={16} color="#8D92A3" textAlign="center">
                Select your birthday
              </Box>
              <Picker

                optionGroups={optionGroups}
                valueGroups={valueGroups}
                onChange={this.handleChange}
              />
              <Box />

            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default App;
