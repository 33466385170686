import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import StarsBg from '../assets/images/starsBg2.png';
import Hearts from '../assets/images/hearts.png';
export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open responsive dialog
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
          <DialogActions>
        
                           <IconButton aria-label="delete" autoFocus onClick={handleClose}>
                            <CloseIcon /> 
    </IconButton>

      
        </DialogActions>
        <Grid container>
        <Grid item xs={1}></Grid>
          <Grid item style={bodyBgStars} xs={9}>
          <Fab color="secondary" style={FabStyle} aria-label="add">
            <img src={Hearts} />
          </Fab>
          
        
          </Grid>
        </Grid>
        
        <Box textAlign="center" mt={2} className="SailecMedium" color="#22242A" fontSize={16}>No Interest Yet</Box>
        
        <Box textAlign="center" color="#707070" className="SailecRegular" fontSize={16}>
          कुनै रुचि देखिएको छैन 
          </Box>
        
      
      </Dialog>
    </div>
  );
}
const bodyBgStars ={
backgroundImage:`url(${StarsBg})`,
backgroundSize:"cover",
backgroundRepeat:"no-repeat",
margin:"0 auto",
textAlign:"center",
height:110,
marginTop:20,
}
const FabStyle ={
  height: 90,
    width: 90,
}