import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Slider from "@material-ui/core/Slider";
import { userService } from "../_services/user.service";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  buttonWidth2: {
    marginTop: 160,
    fontSize: 16,
    fontFamily: "SailecRegular!important",
    padding: "15px 0px",
    top: 50,
    width: "100%",
    textTransform: "none"
  },
  progress: {
    position: "fixed",
    width: "100%",
    top: 0
  },
  slider: {
    marginLeft: 15,
    "&Mui-focusVisible": {
      boxShadow: "none"
    }
  },
  letterSpacing: {
    letterSpacing: 1,
    fontFamily: "SailecRegular!important"
  },
  fontShareenath: {
    fontFamily: "ShreenathBoldRegular"
  },
  fontSailecMedium: {
    fontFamily: "SailecMedium!important"
  },
  fontSailecRegular: {
    fontFamily: "SailecRegular!important"
  },
  fontSailecBold: {
    fontFamily: "SailecBold!important"
  }
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([1]);
  const value1 = value[0];
  const sharedData =JSON.parse(localStorage.getItem("registerQuestion"));
  const init_feet = 4
  const init_inch = 0

  const feets = init_feet + parseInt((value1 - 1) / 12)
  const inches = init_inch + parseInt((value1 - 1) % 12)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onClickNext = () => {
    
    const page = 2;
    localStorage.setItem("registerQuestion", JSON.stringify({
      state: {
        ...sharedData.state,
        height:value[0],
        page
      }  
    })) 

    props.history.push("register-question2")
  }

  if(localStorage.getItem('registerQuestion') !== null) {
    if(sharedData.state.page < 1) {
      props.history.push("/")
    }
  } else{
    props.history.push("/")
  }

  return (
    <div className={classes.root}>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={8}
      />
      <Container fixed>
        <Box
          fontSize={10}
          className={classes.letterSpacing}
          textAlign="center"
          mt={2}
          color="#8D92A3"
        >
          QUESTION 1 OF 12
        </Box>
        <Box
          fontSize={24}
          className={classes.fontSailecBold}
          mt={3}
          color="#000"
          textAlign="center"
        >
          How tall are you?
        </Box>
        <Box
          textAlign="center"
          className={classes.fontSailecRegular}
          mt={1}
          color="#707070"
          fontSize={14}
        >
          तपाई को उचाई कति छ ?
        </Box>
        <Box mt={5} />
        <Grid container>
          <Grid item xs={5}>
            <Box
              color="#f50057"
              className={classes.fontSailecRegular}
              textAlign="right"
              pr={2}
              fontSize={24}
              mt={18}
            >
              {parseInt(feets)}
              <sup>ft </sup>
              {parseInt(inches)}
              <sup>in</sup>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Slider
              onChange={handleChange}
              value={value}
              orientation="vertical"
              step={1}
              color="secondary"
              min={1}
              marks
              aria-labelledby="discrete-slider"
              className={classes.slider}
              max={37}
            />
          </Grid>
        </Grid>
        <Button
          onClick={onClickNext}
            variant="contained"
            color="secondary"
            className={classes.buttonWidth2}
          >
            Next
          </Button>
        <Box py={5} />
      </Container>
    </div>
  );
}
