import React, { Component } from 'react';
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import Badge from '@material-ui/core/Badge';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ExploreIcon from '@material-ui/icons/Explore';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import { withRouter } from "react-router-dom";

import LanguageSelect from './LanguageSelect';
import ReligionSelect from './ReligionSelect';
import CasteSelect from './CasteSelect';
const styles = (theme) => ({
    input: {
        display: 'none'
    },
   
});
class PhysicalAppearance extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };
  
    render() {
        const { classes } = this.props;
     
        return (
         

              
            <div>
                    <Grid container style={style.profileTop} className="profilenav">
                        
                    <Link to="/my-profile"><Grid item style={style.backButton} xs={2}>
                       <IconButton aria-label="delete">
                        <ArrowBackIcon/> 
</IconButton>
 </Grid></Link>
                        <Grid item xs={10}>
                            <Box textAlign="center" ml="-33px" fontSize={18} className="fontSailecBold" style={style.profileHeading} >Social Appearance</Box> 
                        </Grid>
                  
                    </Grid>
                    <LanguageSelect />
                    <ReligionSelect />
                    <CasteSelect />
                    <Container fixed><Link  to='./my-profile'>
                <Button
               
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={style.textcapitalize}
                >
                  Done
                </Button>
              </Link>
              </Container>
                  
                
   
     </div>
   
        )
    }
}
export default withStyles(styles, { withTheme: true })(PhysicalAppearance)
const style = {
    profileHeading:{
        paddingTop:10,
        textTransform:'capitalize'
    },
    image:{
        borderRadius:6,
        objectFit:"cover",
        height:130,
        width:130,
      },
       textcapitalize: {
        textTransform: "capitalize",
        marginTop: 120,
        fontSize: 16,
        padding: "15px 0px",
      },
     
   
}